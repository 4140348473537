import ReactApexChart, { Props } from 'react-apexcharts';

const _chartStyle: React.CSSProperties = {
  width: '25%',
  textAlign: 'center',
  padding: '0.5rem 1rem',
};

export interface ReviewResultChartData {
  qnId: string;
  qnLabel: string;
  seq: number;
  total: number;
  answers: ReviewResultContent[];

  /*optional*/
  title?: string;
  style?: React.CSSProperties;
}

export interface ReviewResultContent {
  aoId?: string;
  answer: string;
  count: number;
}

interface ReviewResultChartProps extends Props {
  datas?: ReviewResultContent[];
  total: number;
  seq: number;
  title: string;
}

function stringToHexColor(label: string) {
  // Generate a hash value from the string label
  const hashValue = hashCode(label);

  // Extract three components from the hash value for RGB
  const r = (hashValue & 0xff0000) >> 16;
  const g = (hashValue & 0x00ff00) >> 8;
  const b = hashValue & 0x0000ff;

  // Format the RGB values into a hexadecimal color string
  const hexColor =
    '#' +
    ('0' + r.toString(16)).slice(-2) +
    ('0' + g.toString(16)).slice(-2) +
    ('0' + b.toString(16)).slice(-2);

  return hexColor;
}

// Hash function (Java's String.hashCode() equivalent)
function hashCode(str: string) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash &= hash; // Convert to 32bit integer
  }
  return hash;
}

const ReviewResultChart: React.FC<ReviewResultChartProps> = (
  props: ReviewResultChartProps,
) => {
  let datas = props.datas?.sort((a, b) => b.count - a.count);
  const maxLabels = 10;
  if (props.datas && props.datas.length > maxLabels) {
    const survived = props.datas.slice(0, maxLabels);
    let sum = 0;
    survived.forEach((cnt) => (sum += cnt.count));
    const etc: ReviewResultContent = {
      answer: '기타 (etc)',
      count: props.total - sum,
    };
    survived.push(etc);
    datas = survived;
  }

  const labels: string[] = datas?.map((content) => content.answer) ?? [];
  const colors: string[] =
    datas?.map((content) => stringToHexColor(content.answer + content.aoId)) ??
    [];
  const series: number[] =
    datas?.map(
      (content) => Math.floor((content.count / props.total) * 1000) / 10,
    ) ?? [];
  return (
    <ReactApexChart
      options={{
        chart: {
          height: 390,
          type: 'pie',
        },
        title: {
          text: props.seq + '번: ' + props.title,
          floating: true,
          align: 'left',
          margin: 100,
          offsetX: 0,
          offsetY: 0,
        },
        colors: colors,
        labels: labels,
        legend: {
          show: true,
          floating: true,
          fontSize: '16px',
          position: 'right',
          labels: {
            useSeriesColors: true,
          },
          formatter: function (seriesName: string, opts: any) {
            const labelName =
              seriesName.length > 10
                ? seriesName.substring(0, 10) + '…'
                : seriesName;
            return (
              labelName +
              ':  ' +
              opts.w.globals.series[opts.seriesIndex] +
              '% (' +
              datas?.[opts.seriesIndex].count +
              '명)'
            );
          },
          itemMargin: {
            vertical: 3,
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                show: false,
              },
            },
          },
        ],
      }}
      series={series}
      type="pie"
      height={390}
      width={1000}
    />
  );
};

export default ReviewResultChart;
