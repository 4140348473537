import React, {useEffect} from 'react';
import {Button, Form, Input, Modal, Select, Tag} from "antd";
import {EmotionType} from "../../types/enumtype/emotionEnumType";

interface ModalProps {
    open: boolean;
    close: () => void;
    div: 'EMOTION' | 'FIELD'
    handleFinish: (values: any, mode: string) => void;
    record?: any;
}

// desc: record props 가 잇으면 수정 모드
const CreateOrTagModal = ({open, close, div, handleFinish, record}: ModalProps) => {
    const [form] = Form.useForm();
    const label = div === 'EMOTION' ? '감정태그' : '분야태그';
    useEffect(() => {
        if (record) {
            const {name, div, type} = record;
            form.setFieldsValue({
                name, div, type
            });
        }
    }, [record]);

    return (
        <Modal
            className="common-modal"
            //title={'새 태그 등록'}
            title={record ? `${label} 수정` : `${label} 등록`}
            open={open}
            footer={null}
            onCancel={close}
        >
            <div className='common-modal-content'>
                <Form
                    className="create-form"
                    name="create-form"
                    form={form}
                    autoComplete="off"
                    colon={false}
                    labelCol={{span: 4}}
                    wrapperCol={{span: 30, offset: 1}}
                    requiredMark={false}
                    onFinish={(values) => handleFinish(values, record ? "modify" : "create")}
                >
                    <Form.Item
                        name="div"
                        initialValue={div}
                        hidden={true}
                    />

                    <Form.Item
                        name="name"
                        label={`${label} 제목`}
                        rules={[{required: true, message: `${label} 이름을 입력해 주세요`}]}
                    >
                        <Input placeholder={'제목 입력'}/>
                    </Form.Item>
                    {div === 'EMOTION' &&
                        <Form.Item
                            name="type"
                            label={'감정 분류'}
                            rules={[{required: true, message: '감정분류를 입력해 주세요'}]}
                            initialValue={EmotionType.POSITIVE}
                        >
                            <Select style={{width: 110, textAlign: 'center'}}>
                                <Select.Option value={EmotionType.POSITIVE}><Tag
                                    color={'blue'}>긍정감정</Tag></Select.Option>
                                <Select.Option value={EmotionType.NEGATIVE}><Tag
                                    color={'red'}>부정감정</Tag></Select.Option>
                            </Select>
                        </Form.Item>
                    }


                    <div className="centered-outer register-btn-outer">
                        <Button
                            type="primary"
                            ghost
                            htmlType="submit"
                        >
                            {record ? '수정 완료' : '등록'}
                        </Button>
                    </div>
                </Form>
            </div>
        </Modal>
    );
};

export default CreateOrTagModal;
