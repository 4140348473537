import React, {useEffect, useState} from 'react';
import {Button, Dropdown, message, SelectProps, Space, Table, Tag} from 'antd';
import {PsyTestService} from 'services/PsyTestService';
import {ColumnsType} from 'antd/es/table';
import {PsyTestData} from 'types/psyTestTypes';
import {TestType, testTypeLabel, testTypeOptions} from "../../types/enumtype/psytestEnumtype";
import UseConfirmDelete from "../../utils/useHook/UseConfirmDelete";
import moment from 'moment';
import {RiSettings3Fill} from "react-icons/ri";
import {CloseCircleOutlined, EditOutlined, PlusOutlined, UploadOutlined} from "@ant-design/icons";
import {AiOutlineDelete} from "react-icons/ai";
import PsytestModal from "./PsytestModal";
import {IoMdCheckbox} from "react-icons/io";
import {useNavigate} from "react-router-dom";
import {CounselingService} from "../../services/CounselingService";
import {CounselingData} from "../../types/counselingTypes";
import AnswerModal from "../../components/AnswerModal";
import {MdFindInPage, MdOutlineRateReview} from "react-icons/md";
import Colors from "../../styles/colors";
import AnswerAndResultModal from "../../components/AnswerAndResultModal";

const PsyTest = () => {
        const navigate = useNavigate();
        const [data, setData] = useState<PsyTestData[]>();
        const [selectedRecord, setSelectedRecord] = useState<PsyTestData>();
        const [modalVsb, setModalVsb] = useState(false);
        const [selectOptions, setSelectOptions] = useState<SelectProps['options']>(testTypeOptions);
        const [selectedRows, setSelectedRows] = useState<PsyTestData[]>([]);
        const [reviewModalVsb, setReviewModalVsb] = useState<boolean>(false);
        const [answerModalVsb, setAnswerModalVsb] = useState<boolean>(false);

        useEffect(() => {
            getData();
        }, []);

        const getData = async () => {
            const res = await PsyTestService.get();
            if (res.data.success) {
                setData(res.data.result);
                const basicTest = res.data.result.find((test: PsyTestData) => test.testType === TestType.BASIC_TEST);
                if (basicTest) {
                    setSelectOptions(testTypeOptions.filter((item) => item.value !== 'BASIC_TEST'));
                }
            } else {
                throw new Error(res.data.message);
            }
        }

        const handleFinish = async (values: any) => {
            await create(values);
        }

        const create = async (reqBody: any) => {
            try {
                const res = await PsyTestService.create(reqBody);
                if (res.data.success) {
                    message.success('등록되었습니다.');
                    await getData();
                    setModalVsb(false);
                } else {
                    throw new Error(res.data.message);
                }
            } catch (e: any) {
                console.error(e.message);
                message.error('등록에 실패했습니다.');
            }
        }

        const handleFinishModify = async (values: any) => {
            if (!selectedRecord) return;
            const res = await PsyTestService.update(values, selectedRecord?.id);
            if (res.data.success) {
                message.success('수정되었습니다.');
                getData();
                setModalVsb(false);
                setSelectedRecord(undefined);
            } else {
                message.error('수정에 실패했습니다.');
            }
        }
        const handleDelete = async (id: string) => {
            const res = await PsyTestService.delete(id);
            if (res.data.success) {
                message.success('삭제되었습니다.');
                await getData();
                setSelectedRecord(undefined);
            } else {
                throw new Error(res.data.message);
            }
        }
        const {handleClickDelete, confirmModal} = UseConfirmDelete({handleDelete, content: "삭제 시 작성된 질문도 함께 삭제됩니다."});

        const handleClickPublish = async (id: string, isPublished: boolean) => {
            const res = await PsyTestService.updateIsPublished(id, isPublished);
            if (res.data.success) {
                message.success('공개 상태가 변경되었습니다.');
                await getData();
            } else {
                throw new Error(res.data.message);
            }
        }

        const handleClickPublishSelected = async (isPublished: boolean) => {
            const ids = selectedRows.map((item) => item.id);
            const reqBody = {
                ids,
                isPublished
            }
            const res = await PsyTestService.updateIsPublishedSelected(reqBody);
            if (res.data.success) {
                message.success('공개 상태가 변경되었습니다.');
                await getData();
            } else {
                throw new Error(res.data.message);
            }
        }

        const handleClickEditQn = async (id: string, testType: TestType, mode: string) => {
            navigate(`/psytest/${testType}/${mode}/${id}`);
        }

        const handleCloseModal = () => {
            setModalVsb(false);
            setSelectedRecord(undefined);
        }

        const handleClickReview = async (record: PsyTestData) => {
            setSelectedRecord(record);
            setReviewModalVsb(true);
        }
        const handleClickAnswer = async (record: PsyTestData) => {
            setSelectedRecord(record);
            setAnswerModalVsb(true);
        }

        const additionalItem1 = {
            key: '3',
            label: (
                <>
                    {/*<FiUpload/>*/}
                    <UploadOutlined/>
                    <span className={'menu-item-text'}> 공개하기</span>
                </>
            ),
            onClick: () => selectedRecord && handleClickPublish(selectedRecord?.id, true),
        }

        const additionalItem2 = {
            key: '4',
            label: (
                <>
                    <CloseCircleOutlined/>
                    <span className={'menu-item-text'}>공개취소</span>
                </>
            ),
            onClick: () => selectedRecord && handleClickPublish(selectedRecord?.id, false),
        }
        const items = [
            {
                key: '1',
                label: (
                    <>
                        <EditOutlined/>
                        <span className={'menu-item-text'}>수정하기</span>
                    </>
                ),
                onClick: () => selectedRecord && setModalVsb(true),
            },
            {
                key: '2',
                label: (
                    <>
                        <AiOutlineDelete/>
                        <span className={'menu-item-text'}>삭제하기</span>
                    </>
                ),
                onClick: () => selectedRecord && handleClickDelete(selectedRecord?.id),
            },
        ]

        const columns: ColumnsType<PsyTestData> = [
            {
                title: '',
                dataIndex: 'id',
                key: 'id',
                width: 50,
                align: 'center',
                render: (value: string, record: PsyTestData, index: number) => (
                    <Dropdown
                        trigger={['click']}
                        menu={{items: record.isPublished ? [additionalItem2, ...items] : record.qnCount === 0 ? items : [additionalItem1, additionalItem2, ...items]}}
                        placement="bottomLeft" arrow
                    >
                        <div className="setting-icon-container">
                            <RiSettings3Fill
                                size={24}
                                onClick={() => {
                                    setSelectedRecord(record);
                                }}
                            />
                        </div>
                    </Dropdown>
                ),
            },
            {
                title: '공개 상태',
                dataIndex: 'isPublished',
                key: 'isPublished',
                align: 'center',
                width: 80,
                render: (isPublished: boolean) => (
                    <>{isPublished ? <IoMdCheckbox size={24}/> : <></>}</>
                )
            },
            {
                title: '제목',
                dataIndex: 'title',
                key: 'title',
                align: 'center',
            },
            {
                title: '테스트 타입',
                dataIndex: 'testType',
                key: 'testType',
                align: 'center',
                render: (testType: TestType) => (
                    <Tag
                        className="test-type-tag"
                        color={testType === TestType.TYPE_TEST ? 'purple' :
                            testType === TestType.SCORE_TEST ? 'geekblue' : 'gray'}
                    >
                        {testTypeLabel[testType]}
                    </Tag>
                )
            },
            {
                title: '이용자수',
                dataIndex: 'answeredUserCount',
                key: 'answeredUserCount',
                align: 'center',
            },
            // 이용자 수가 0이 아닌 경우에만 답변, 리뷰/평점 보이도록 설정
            {
                title: '답변',
                dataIndex: 'answers',
                key: 'answers',
                align: 'center',
                render: (value: string, record: PsyTestData) => {
                    return record.answeredUserCount > 0 ? (
                        <Space size="middle">
                            <Button
                                icon={<MdOutlineRateReview size={24} color={Colors.primary_250}/>}
                                type={'text'}
                                onClick={handleClickAnswer.bind(null, record)}
                            />
                        </Space>
                    ) : null;
                }
            },
            {
                title: '리뷰/평점',
                dataIndex: 'review',
                key: 'review',
                align: 'center',
                render: (value: string, record: PsyTestData) => {
                    return record.answeredUserCount > 0 ? (
                        <Space size="middle">
                            <Button
                                icon={<MdFindInPage size={24} color={Colors.primary_250}/>}
                                type={'text'}
                                onClick={handleClickReview.bind(null, record)}
                            />
                        </Space>
                    ) : null;
                }
            },

            {
                title: '등록일시',
                dataIndex: 'createdAt',
                key: 'createdAt',
                align: 'center',
                render: (createdAt: string) => (
                    <>{moment(createdAt).format('YYYY-MM-DD HH:mm')}</>
                )
            },
            {
                title: '질문 수',
                dataIndex: 'qnCount',
                key: 'qnCount',
                align: 'center',
            },
            {
                title: '',
                dataIndex: 'id',
                key: 'id',
                align: 'center',
                render: (id, record, index) => (
                    <Space size="middle">
                        {record.qnCount > 0 ?
                            <Button
                                type="primary" ghost
                                onClick={() => handleClickEditQn(id, record.testType, 'modify')}
                            >질문/결과 보기</Button>
                            :
                            <Button
                                type="primary"
                                onClick={() => handleClickEditQn(id, record.testType, 'create')}
                            >질문/결과 등록</Button>
                        }
                    </Space>
                )
            },
        ];

        return (
            <>
                <div className="content-header">
                    <h2>심리테스트 목록</h2>
                    <Button
                        className="new-btn"
                        type="primary"
                        onClick={() => setModalVsb(true)}
                        icon={<PlusOutlined/>}
                    >
                        새 심리테스트 등록
                    </Button>
                    <Button
                        className="btn"
                        disabled={selectedRows.length === 0
                            // 모든 선택된 행이 공개 상태이거나, 질문 수가 0인 경우 비활성화
                            || selectedRows.every((item) => item.isPublished)
                            || selectedRows.some((item) => item.qnCount === 0)
                        }
                        onClick={handleClickPublishSelected.bind(null, true)
                        }
                    >
                        공개하기
                    </Button>
                    <Button
                        className="btn"
                        disabled={selectedRows.length === 0
                            || selectedRows.every((item) => !item.isPublished)
                        }
                        onClick={handleClickPublishSelected.bind(null, false)}
                    >
                        공개취소
                    </Button>
                </div>
                <Table
                    size={'small'}
                    columns={columns}
                    dataSource={data}
                    rowKey={'id'}
                    rowSelection={{
                        type: 'checkbox',
                        onChange: (selectedRowKeys, selectedRows) => setSelectedRows(selectedRows)
                    }}
                    pagination={{position: ['bottomCenter']}}
                />
                {confirmModal}
                {modalVsb &&
                    <PsytestModal open={modalVsb}
                                  close={handleCloseModal}
                                  handleFinish={selectedRecord ? handleFinishModify : handleFinish}
                                  record={selectedRecord}
                                  selectOptions={selectOptions}
                    />
                }
                {reviewModalVsb && selectedRecord &&
                    <AnswerModal
                        type={'review'}
                        id={selectedRecord?.id}
                        title={selectedRecord?.title}
                        open={reviewModalVsb}
                        close={() => setReviewModalVsb(false)}
                    />
                }
                {answerModalVsb && selectedRecord &&
                    <AnswerAndResultModal
                        type={selectedRecord.testType}
                        id={selectedRecord?.id}
                        title={selectedRecord?.title}
                        open={answerModalVsb}
                        close={() => setAnswerModalVsb(false)}
                    />
                }
            </>
        );
    }
;

export default PsyTest;
