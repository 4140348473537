import React from 'react';
import {Outlet, Route, Routes} from 'react-router-dom';
import DefaultLayout from 'layouts/DefaultLayout';
import PsyTest from 'pages/03-psytest/PsyTest';
import Counseling from "../pages/02-counseling/Counseling";
import TagMng from "../pages/01-conversation/TagMng";
import AuthRoute from "./AuthRouter";
import CounselorMng from "../pages/06-counselor-mng/CounselorMng";
import {AssetType} from "../types/enumtype/assetEnumtype";
import Decoration from "../pages/04-decoration/Decoration";
import PushAlarm from "../pages/05-admin-post/PushAlarm";
import NoticeFaq from "../pages/05-admin-post/NoticeFaq";
import {NotiType} from "../types/enumtype/notiEnumtype";
import ChatPreview from "../pages/07-user-chat-preview/ChatPreview";
import Convo from "../pages/01-conversation/Convo";
import ConvoQns from "../pages/01-conversation/ConvoQns";
import CounselingQns from "../pages/02-counseling/CounselingQns";
import PsyTestQns from "../pages/03-psytest/PsyTestQns";
import NoticeWindow from "../pages/00-appmain/NoticeWindow";
import RandomSpeechBubble from "../pages/00-appmain/RandomSpeechBubble";
import AppReview from "../pages/00-appmain/AppReview";
import RSBubbleQns from "../pages/00-appmain/RSBubbleQns";
import ChatRoom from "../pages/07-user-chat-preview/ChatRoom";
import ChatRoomById from "../pages/07-user-chat-preview/ChatRoomById";

function DefaultRouter() {
    return (
        <Routes>
            <Route element={<DefaultLayout/>}>
                <Route path="/appmain/notice-window" element={<NoticeWindow/>}/>
                <Route path="/appmain/random-speech-bubble" element={<RandomSpeechBubble/>}/>
                <Route path="/appmain/random-speech-bubble/:mode/:cvId" element={<RSBubbleQns/>}/>
                <Route path="/appmain/app-review" element={<AppReview/>}/>
                <Route path="/psytest" element={<PsyTest/>}/>
                <Route path="/psytest/:testType/:mode/:ptId" element={<PsyTestQns/>}/>
                <Route path="/conversation/:stage" element={<Convo/>}/>
                <Route path="/conversation/:stage/:mode/:cvId" element={<ConvoQns/>}/>
                <Route path="/" element={<Counseling/>}/>
                <Route path="/counseling/:mode/:csId" element={<CounselingQns/>}/>
                <Route path="/counseling" element={<Counseling/>}/>
                <Route path="/conversation/tag" element={<TagMng/>}/>
                <Route path="/decoration/avatar" element={<Decoration assetType={AssetType.AVATAR}/>}/>
                <Route path="/decoration/background" element={<Decoration assetType={AssetType.BACKGROUND}/>}/>
                <Route path="/adminpost/notice" element={<NoticeFaq notiType={NotiType.NOTICE}/>}/>
                <Route path="/adminpost/faq" element={<NoticeFaq notiType={NotiType.FAQ}/>}/>
                <Route path="/adminpost/pushalarm" element={<PushAlarm/>}/>
                <Route path="/counselor" element={
                    <AuthRoute redirectPath="/login" isAnonymousPage={false} hasRole="ADMIN">
                        <CounselorMng/>
                    </AuthRoute>
                }/>
                <Route path="/chat-preview/counseling" element={<ChatPreview/>}/>
                <Route path="/chat-preview/conversation" element={<ChatRoom/>}/>
                <Route path="/chat-preview/:type/:id" element={<ChatRoomById/>}/>
                {/*type : conversation, counseling, psytest*/}
            </Route>
        </Routes>
    );
}

export default DefaultRouter;
