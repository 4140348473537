interface ImageURISource {
  uri: string;
}

export const getAvatarProfileImageSource = async (
    name: string,
): Promise<ImageURISource> => {
  if (name.includes('솜이')) {
    return require('../../assets/images/chatroom/profiles/1.png');
  // } else if (name.includes('하루')) {
  //   return require('@assets/images/profiles/2.png');
  // } else if (name.includes('밤비')) {
  //   return require('@assets/images/profiles/3.png');
  } else {
    return require('../../assets/images/chatroom/profiles/1.png');
  }
};
