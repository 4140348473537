import axios, {AxiosResponse} from 'axios';
import {ApiResponseData} from "../types/apiResponseDataTypes";
import {JoinInfo, LoginInfo, LoginSuccessResData} from "../types/authTypes";

const apiUrl = process.env.REACT_APP_API_URL;
export const AuthService = {
    async login(loginInfo: LoginInfo): Promise<AxiosResponse<ApiResponseData<LoginSuccessResData>>> {
        return await axios.post(`${apiUrl}/admin/auth/login`, loginInfo);
    },

    async refresh(refreshToken: string): Promise<AxiosResponse<any>> {
        // const refreshToken = localStorage.getItem('refreshToken');
        const headers = { 'Authorization': refreshToken };
        return await axios.post(
            `${apiUrl}/admin/auth/refresh`,
            {},
            {headers},
        );
    },
    logout: () => {
        localStorage.clear();
        window.location.href = '/login';
    },
};
