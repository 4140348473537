import React, {useEffect, useState} from 'react';
import {Button, Dropdown, Form, Input, message, Select, SelectProps, Space, Table} from 'antd';
import {Section} from "../../components/TriSectionLayout";
import {EditOutlined} from "@ant-design/icons";
import {ColumnsType} from "antd/es/table";
import {AiOutlineDelete} from "react-icons/ai";
import UseConfirmDelete from "../../utils/useHook/UseConfirmDelete";
import {RiSettings3Fill} from "react-icons/ri";
import {ConvoService} from "../../services/ConvoService";
import {StageType} from "../../types/enumtype/stageEnumtype";
import {ConvoData, ConvoReqData} from "../../types/convoTypes";
import {DecorationService} from "../../services/DecorationService";
import moment from "moment";
import ModifyRSBubbleModal from "./ModifyRSBubbleModal";
import {useNavigate} from "react-router-dom";

const RandomSpeechBubble = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [data, setData] = useState<ConvoData[]>();
    const [selectedRecord, setSelectedRecord] = useState<ConvoData>();
    const [modifyModalVsb, setModifyModalVsb] = useState(false);
    const [avatarOptions, setAvatarOptions] = useState<SelectProps['options']>([]);

    useEffect(() => {
        getData();
        getAvatar();
        return () => {
            form.resetFields();
        }
    }, []);

    const getData = async () => {
        const res = await ConvoService.getByStage(StageType.RANDOM_SPEECH_BUBBLE);
        if (res.data.success) {
            setData(res.data.result);
        } else {
            throw new Error(res.data.message);
        }
    };

    const getAvatar = async () => {
        try {
            const res = await DecorationService.getAvatars();
            setAvatarOptions(res.data.result.map((item: any) => {
                    return {
                        label: item.name,
                        value: item.id
                    }
                }
            ));
        } catch (e: any) {
            message.error('데이터를 불러오는데 실패했습니다.');
            console.error(e.message);
        }
    }

    const handleClickEditQn = async (id: string, mode: string) => {
        navigate(`/appmain/random-speech-bubble/${mode}/${id}`);
    }

    const handleFinishCreate = async (values: ConvoReqData) => {
        await create(values);
    };

    const create = async (values: ConvoReqData) => {
        const res = await ConvoService.create(values);
        if (res.data.success) {
            message.success('생성되었습니다.');
            await getData();
            form.resetFields();
        } else {
            throw new Error(res.data.message);
        }
    };

    const handleModifyFinish = async (values: ConvoReqData) => {
        await modify(values);
    }

    const modify = async (values: ConvoReqData) => {
        if (!selectedRecord) return;
        const res = await ConvoService.update(values, selectedRecord!.id);
        if (res.data.success) {
            await getData();
            setModifyModalVsb(false);
            setSelectedRecord(undefined);
            message.success('수정되었습니다.');
        } else {
            throw new Error(res.data.message);
        }
    }

    const handleDelete = async (id: string) => {
        await deleteNotice(id);
    };

    const deleteNotice = async (id: string) => {
        const res = await ConvoService.delete(id);
        if (res.data.success) {
            await getData();
            message.success('삭제되었습니다.');
        } else {
            throw new Error(res.data.message);
        }
    }

    const {handleClickDelete, confirmModal} = UseConfirmDelete({handleDelete});

    const items = [
        {
            key: '1',
            label: (
                <>
                    <EditOutlined/>
                    <span className={'menu-item-text'}>수정하기</span>
                </>
            ),
            onClick: () => selectedRecord && setModifyModalVsb(true),
        },
        {
            key: '2',
            label: (
                <>
                    <AiOutlineDelete/>
                    <span className={'menu-item-text'}>삭제하기</span>
                </>
            ),
            onClick: () => selectedRecord && handleClickDelete(selectedRecord?.id),
        },
    ]

    const columns: ColumnsType<ConvoData> = [
        {
            title: '',
            dataIndex: 'id',
            key: 'id',
            width: 100,
            align: 'center',
            render: (value: string, record: ConvoData, index: number) => (
                <Dropdown
                    trigger={['click']}
                    menu={{items}}
                    placement="bottomLeft" arrow
                >
                    <div className="setting-icon-container">
                        <RiSettings3Fill
                            size={24}
                            onClick={() => {
                                setSelectedRecord(record);
                            }}
                        />
                    </div>
                </Dropdown>
            ),
        },
        {
            title: '제목',
            dataIndex: 'title',
            key: 'title',
            align: 'center',
        },
        {
            title: '아바타',
            dataIndex: ['avatar', 'name'],
            key: 'avatar',
            align: 'center'
        },
        {
            title: '등록일시',
            dataIndex: 'createdAt',
            key: 'createdAt',
            align: 'center',
            render: (createdAt: string) => (
                <>{moment(createdAt).format('YYYY-MM-DD HH:mm')}</>
            )
        },
        {
            title: '',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: 100,
            render: (id, record, index) => (
                <Space size="middle">
                    {record.hasQns ?
                        <Button
                            type="primary" ghost
                            onClick={() => handleClickEditQn(id, 'modify')}
                        >말풍선 보기</Button>
                        :
                        <Button
                            type="primary"
                            onClick={() => handleClickEditQn(id, 'create')}
                        >말풍선 등록</Button>
                    }
                </Space>
            ),
        },
    ];

    return (
        <>
            <div className="layout-container two-to-three">
                <Section
                    title={'새 말풍선 아바타 등록'}
                >

                    <div>
                        <Form
                            className="create-form"
                            name="create-form"
                            form={form}
                            autoComplete="off"
                            colon={false}
                            labelCol={{span: 5}}
                            requiredMark={false}
                            onFinish={handleFinishCreate}
                        >
                            <Form.Item
                                name="stage"
                                initialValue={StageType.RANDOM_SPEECH_BUBBLE}
                                hidden={true}
                            />

                            <Form.Item
                                name="title"
                                label='제목'
                                rules={[{required: true, message: '제목을 입력해 주세요'}]}
                            >
                                <Input placeholder={'제목 입력'}/>
                            </Form.Item>

                            <Form.Item
                                name="avatarId"
                                label="아바타"
                            >
                                <Select
                                    options={avatarOptions}
                                    placeholder="아바타 선택"
                                />
                            </Form.Item>
                            <Form.Item
                                name="description"
                                label="설명"
                            >
                                <Input.TextArea placeholder="설명 입력"/>
                            </Form.Item>

                            <div className="centered-outer">
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    style={{minWidth: 100}}
                                >생성</Button>
                            </div>
                        </Form>
                    </div>
                </Section>

                <Section title={'말풍선 아바타 목록'}>
                    <Table
                        size={'small'}
                        dataSource={data}
                        rowKey={'id'}
                        columns={columns}
                        pagination={{pageSize: 5}}
                    />
                </Section>
            </div>
            {confirmModal}
            {modifyModalVsb &&
                <ModifyRSBubbleModal
                    open={modifyModalVsb}
                    close={() => setModifyModalVsb(false)}
                    stage={StageType.RANDOM_SPEECH_BUBBLE}
                    avatarOptions={avatarOptions}
                    record={selectedRecord!}
                    handleFinish={handleModifyFinish}
                />
            }
        </>
    );
};

export default RandomSpeechBubble;
