import React, { useEffect } from 'react';
import { Button, DatePicker, Form, Input, Modal } from 'antd';
import { NoticeWindowResData } from '../../types/noticeTypes';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

interface ModalProps {
  open: boolean;
  close: () => void;
  handleFinish: (values: any) => void;
  record: NoticeWindowResData | undefined;
}

const NoticeWindowModal = ({
  open,
  close,
  handleFinish,
  record,
}: ModalProps) => {
  const [form] = Form.useForm();
  useEffect(() => {
    if (record) {
      const { dateRange: dateRangeOrigin, title, content } = record;
      const dateRange = [
        dayjs(dateRangeOrigin[0]).tz('Asia/Seoul'),
        dayjs(dateRangeOrigin[1]).tz('Asia/Seoul'),
      ];
      form.setFieldsValue({
        dateRange,
        title,
        content,
      });
    }
  }, [record]);

  return (
    <Modal
      className="common-modal"
      title={record ? '공지창 수정' : '새 공지창 등록'}
      open={open}
      footer={null}
      onCancel={close}
    >
      <div className="common-modal-content">
        <Form
          className="create-form"
          name="create-form"
          form={form}
          autoComplete="off"
          colon={false}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 30, offset: 1 }}
          requiredMark={false}
          onFinish={handleFinish}
        >
          <Form.Item
            name="dateRange"
            label="공지기간"
            rules={[{ required: true, message: '공지기간을 입력해 주세요' }]}
          >
            <DatePicker.RangePicker
              disabledDate={(current) => {
                return (
                  current && current < dayjs().tz('Asia/Seoul').startOf('day')
                );
              }}
              placeholder={['시작날짜', '종료날짜']}
            />
          </Form.Item>
          <Form.Item
            name="title"
            label="제목"
            rules={[{ required: true, message: '제목을 입력해 주세요' }]}
          >
            <Input placeholder={'제목 입력'} />
          </Form.Item>

          <Form.Item name="content" label="내용">
            <Input.TextArea placeholder="내용 입력" />
          </Form.Item>

          <div className="centered-outer register-btn-outer">
            <Button type="primary" ghost htmlType="submit">
              {record ? '수정 완료' : '등록'}
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default NoticeWindowModal;
