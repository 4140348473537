import React, { useEffect, useState } from 'react';
import { Section } from '../../components/TriSectionLayout';
import {
  Button,
  DatePicker,
  Dropdown,
  Form,
  Input,
  message,
  Popover,
  Select,
  Table,
  TimePicker,
} from 'antd';
import { AnyObject } from 'antd/es/_util/type';
import { EditOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { AiOutlineDelete } from 'react-icons/ai';
import UseConfirmDelete from '../../utils/useHook/UseConfirmDelete';
import { RiSettings3Fill } from 'react-icons/ri';
import { IoMdCheckbox, IoMdRefresh } from 'react-icons/io';
import { AdminPostService } from '../../services/AdminPostService';
import { PushAlarmData } from 'types/pushAlarmTypes';
import ModifyPushAlarmModal from './ModifyPushAlarmModal';
import { NotiType } from 'types/enumtype/notiEnumtype';
import UseConfirm from 'utils/useHook/UseConfirm';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { debounce } from 'lodash';

dayjs.extend(utc);
dayjs.extend(timezone);

dayjs.tz.setDefault('Asia/Seoul');

const PushAlarm = () => {
  const [form] = Form.useForm();
  const [data, setData] = useState<PushAlarmData[]>();
  const [selectedRecord, setSelectedRecord] = useState<PushAlarmData>();
  const [modifyModalVsb, setModifyModalVsb] = useState(false);

  useEffect(() => {
    getPushAlarms();
    return () => {
      form.resetFields();
    };
  }, []);

  const getPushAlarms = async () => {
    const res = await AdminPostService.getPushAlarms();
    if (res.data.success) {
      setData(res.data.result);
    } else {
      throw new Error(res.data.message);
    }
  };

  const clearForm = () => {
    form.resetFields();
  };

  const handleClickCancelCreate = () => {
    clearForm();
  };

  // 날짜와 시간을 조합하여 timestamp로 변환합니다.
  const fromDateAndTimeToTimestampString = (
    selectedDate: Dayjs,
    selectedTime: Dayjs,
  ) => {
    // console log params and return value
    console.log('selectedDate', selectedDate);
    console.log('selectedTime', selectedTime);
    console.log(
      'return value',
      `${selectedDate.format('YYYY-MM-DD')}T${selectedTime.format('HH:mm')}`,
    );
    return `${selectedDate.format('YYYY-MM-DD')}T${selectedTime.format(
      'HH:mm',
    )}`;
  };

  const now = dayjs().tz();

  // 현재 시각 이전의 시간을 비활성화
  const disabledBeforeCurrentTime = () => {
    return {
      disabledHours: () => {
        return [...Array(now.hour()).keys()];
      },
      disabledMinutes: (selectedHour: number) => {
        if (selectedHour === now.hour()) {
          return [...Array(now.minute()).keys()];
        }
        return [];
      },
      disabledSeconds: (selectedHour: number, selectedMinute: number) => {
        if (selectedHour === now.hour() && selectedMinute === now.minute()) {
          return [...Array(now.second()).keys()];
        }
        return [];
      },
    };
  };

  // selectedDateTime이 현재 시간 이후라면 true, 그렇지 않으면 false를 반환
  const isAfter = (selectedDate: Dayjs, selectedTime: Dayjs): boolean => {
    const now = dayjs().tz();
    const selectedDateTime = dayjs(
      `${selectedDate.format('YYYY-MM-DD')} ${selectedTime.format('HH:mm')}`,
    ).tz();
    return selectedDateTime.isAfter(now);
  };

  const handleFinishCreateDebounce = debounce((values: AnyObject) => {
    handleFinishCreate(values);
  }, 500);

  const handleFinishCreate = async (values: AnyObject) => {
    const formData = new FormData();
    Object.entries(values).forEach(([key, value]) => {
      if (key !== 'selectedDate' && key !== 'selectedTime') {
        formData.append(key, value);
      }
    });

    const timestamp = fromDateAndTimeToTimestampString(
      values.selectedDate,
      values.selectedTime,
    );
    formData.set('timestamp', timestamp);
    if (isAfter(values.selectedDate, values.selectedTime)) {
      try {
        await createPushAlarm(formData);
      } catch (error: any) {
        const { config: response, code } = error;
        if (code === 'PUSH_ALARM_WRONG_REQUEST') {
          message.error('발송 시각을 현재 시점보다 나중으로 해 주세요!');
        } else {
          throw new Error(response.data.message);
        }
      }
    } else {
      openConfirmModal(formData);
    }
  };

  const createPushAlarm = async (formData: FormData) => {
    const res = await AdminPostService.createPushAlarm(formData);
    if (res.data.success) {
      message.success('생성되었습니다.');
      await getPushAlarms();
      setTimeout(() => {
        clearForm();
      }, 400);
    } else {
      throw new Error(res.data.message);
    }
  };

  const handleModifyFinish = async (values: AnyObject) => {
    const formData = new FormData();
    Object.entries(values).forEach(([key, value]) => {
      if (key !== 'selectedDate' && key !== 'selectedTime') {
        formData.append(key, value);
      }
    });

    const timestamp = fromDateAndTimeToTimestampString(
      values.selectedDate,
      values.selectedTime,
    );
    formData.set('timestamp', timestamp);

    try {
      await modifyPushAlarm(formData);
    } catch (error: any) {
      if (
        error.message ===
        'PUSH_ALARM_WRONG_REQUEST(PUSH-ALARM-003) : 발송 시각이 잘못 설정되어 있습니다. (null)'
      ) {
        message.error(
          '메세지를 수정하는 경우 발송 시각을 현재 시점보다 나중으로 해 주세요!',
        );
      } else {
        throw new Error(error.message);
      }
    }
  };

  const modifyPushAlarm = async (formData: FormData) => {
    if (!selectedRecord) return;
    const res = await AdminPostService.modifyPushAlarm(
      formData,
      selectedRecord?.id,
    );
    if (res.data.success) {
      await getPushAlarms();
      setModifyModalVsb(false);
      setSelectedRecord(undefined);
      message.success('수정되었습니다.');
    } else {
      throw new Error(res.data.message);
    }
  };

  const handleDelete = async (id: string) => {
    await deletePushAlarm(id);
  };

  const deletePushAlarm = async (id: string) => {
    const res = await AdminPostService.deletePushAlarm(id);
    if (res.data.success) {
      await getPushAlarms();
      message.success('삭제되었습니다.');
    } else {
      throw new Error(res.data.message);
    }
  };

  const { handleClickDelete, confirmModal } = UseConfirmDelete({
    handleDelete,
  });

  const items = [
    {
      key: '1',
      label: (
        <>
          <EditOutlined />
          <span className={'menu-item-text'}>수정하기</span>
        </>
      ),
      disabled: false,
      onClick: () => selectedRecord && setModifyModalVsb(true),
    },
    {
      key: '2',
      label: (
        <>
          <AiOutlineDelete />
          <span className={'menu-item-text'}>삭제하기</span>
        </>
      ),
      disabled: false,
      onClick: () => selectedRecord && handleClickDelete(selectedRecord?.id),
    },
  ];

  const handleClickConfirm = async (formData: FormData) => {
    const res = await AdminPostService.createPushAlarm(formData);
    if (res.data.success) {
      message.success(`메세지를 성공적으로 발송하였습니다.`);
      getPushAlarms();
    } else {
      message.error(`메세지를 발송하는데 실패하였습니다.`);
    }
  };

  const { openConfirmModal, confirmModal: confirmSendModal } = UseConfirm({
    handleClickConfirm,
    title:
      '발송 시각을 현재 시점보다 이전으로 하면 즉시 발송됩니다.\n정말 발송하시겠습니까?',
  });

  const columns: ColumnsType<PushAlarmData> = [
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      width: 100,
      align: 'center',
      render: (value: string, record: PushAlarmData) => {
        return (
          <Dropdown
            trigger={['click']}
            menu={{ items }}
            placement="bottomLeft"
            arrow
            disabled={record.sent}
          >
            <div className="setting-icon-container">
              <RiSettings3Fill
                size={24}
                onClick={() => {
                  setSelectedRecord(record);
                }}
                style={
                  record.sent
                    ? { color: 'lightgray', cursor: 'not-allowed' }
                    : { color: 'black' }
                }
              />
            </div>
          </Dropdown>
        );
      },
    },
    {
      title: '제목',
      dataIndex: 'title',
      key: 'title',
      width: 100,
      align: 'center',
    },
    {
      title: '내용',
      dataIndex: 'content',
      key: 'content',
      width: 600,
      align: 'center',
      render: (value: string) => {
        const longText = value.length > 100;
        return longText ? (
          <Popover
            content={
              <div style={{ maxWidth: 300, maxHeight: 300, overflow: 'auto' }}>
                {value}
              </div>
            }
            trigger="hover"
            placement="bottom"
          >
            <span>{value.slice(0, 100)}...</span>
          </Popover>
        ) : (
          <span>{value}</span>
        );
      },
    },
    {
      title: '발송시간',
      dataIndex: 'timestamp',
      key: 'timestamp',
      width: 200,
      align: 'center',
      render(value: string) {
        return (
          <>
            <div>{dayjs(value).tz().format('YYYY년 MM월 DD일')}</div>
            <div>{dayjs(value).tz().format('HH시 mm분')}</div>
          </>
        );
      },
    },
    {
      title: '발송완료',
      dataIndex: 'sent',
      key: 'sent',
      width: 100,
      align: 'center',
      render(value) {
        return value === true ? <IoMdCheckbox size={24} /> : <></>;
      },
    },
  ];

  return (
    <>
      <div className="layout-container two-to-three">
        <Section
          title={`새 푸시알림 등록`}
          extra={
            <Button
              icon={<IoMdRefresh size={20} />}
              onClick={handleClickCancelCreate}
            ></Button>
          }
        >
          <div>
            <Form
              className="create-form"
              name="create-push-alarm-form"
              form={form}
              autoComplete="off"
              colon={false}
              labelCol={{ span: 5 }}
              requiredMark={false}
              onFinish={handleFinishCreateDebounce}
            >
              <Form.Item
                name="title"
                label={'제목'}
                rules={[{ required: true, message: `제목을 입력해 주세요` }]}
              >
                <Input placeholder={`제목 입력`} maxLength={20} />
              </Form.Item>

              <Form.Item name="content" label="내용" initialValue={''}>
                <Input.TextArea
                  placeholder="내용 입력"
                  maxLength={500}
                  style={{ minHeight: 200, overflow: 'auto' }}
                />
              </Form.Item>
              <Form.Item
                name="selectedDate"
                label="발송일자"
                initialValue={dayjs().tz()}
                rules={[
                  { required: true, message: '발송 일자를 선택해 주세요!' },
                ]}
              >
                <DatePicker
                  disabledDate={(current) => {
                    return current && current < dayjs().tz().startOf('day');
                  }}
                />
              </Form.Item>
              <Form.Item
                name="selectedTime"
                label="시간"
                initialValue={dayjs().tz()}
                rules={[
                  { required: true, message: '발송 시간을 선택해 주세요!' },
                ]}
              >
                <TimePicker format="HH:mm" {...disabledBeforeCurrentTime()} />
              </Form.Item>
              <Form.Item
                name="ageRange"
                label="연령"
                initialValue={null}
                rules={[
                  { required: false, message: '대상 연령대를 선택합니다.' },
                ]}
              >
                {/* 드롭다운 선택 - 카카오 연령범위 https://developers.kakao.com/sdk/reference/android-rx/release/kakao-open-android-docs/com.kakao.sdk.user.model/-age-range/index.html */}
                <Select listItemHeight={10} listHeight={500}>
                  <Select.Option value={null}>선택 안 함</Select.Option>
                  <Select.Option value="AGE_0_9">0~9세</Select.Option>
                  <Select.Option value="AGE_10_14">10~14세</Select.Option>
                  <Select.Option value="AGE_15_19">15~19세</Select.Option>
                  <Select.Option value="AGE_20_29">20대</Select.Option>
                  <Select.Option value="AGE_30_39">30대</Select.Option>
                  <Select.Option value="AGE_40_49">40대</Select.Option>
                  <Select.Option value="AGE_50_59">50대</Select.Option>
                  <Select.Option value="AGE_60_69">60대</Select.Option>
                  <Select.Option value="AGE_70_79">70대</Select.Option>
                  <Select.Option value="AGE_80_89">80대</Select.Option>
                  <Select.Option value="AGE_90ABOVE">90세 이상</Select.Option>
                  {/* <Select.Option value="UNKNOWN">알 수 없음</Select.Option> */}
                </Select>
              </Form.Item>
              <Form.Item
                name="gender"
                label="성별"
                initialValue={null}
                rules={[
                  { required: false, message: '대상 성별 그룹을 선택합니다.' },
                ]}
              >
                {/* 드롭다운 선택 - 카카오 성별값 https://developers.kakao.com/sdk/reference/android-rx/release/kakao-open-android-docs/com.kakao.sdk.user.model/-gender/index.html */}
                <Select>
                  <Select.Option value={null}>선택 안 함</Select.Option>
                  <Select.Option value="MALE">남성</Select.Option>
                  <Select.Option value="FEMALE">여성</Select.Option>
                  {/* <Select.Option value="OTHER">기타</Select.Option> */}
                </Select>
              </Form.Item>
              <div className="centered-outer">
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ minWidth: 100 }}
                >
                  생성
                </Button>
              </div>
            </Form>
          </div>
        </Section>

        {/* Form Item : 제목, 이미지, 내용 */}
        <Section title={`푸시알림 목록`}>
          <Table
            size={'small'}
            dataSource={data}
            rowKey={'id'}
            columns={columns}
            pagination={{ pageSize: 10 }}
          />
        </Section>
      </div>
      {confirmModal}
      {confirmSendModal}
      {modifyModalVsb && (
        <ModifyPushAlarmModal
          open={modifyModalVsb}
          close={() => setModifyModalVsb(false)}
          record={selectedRecord!}
          handleFinish={handleModifyFinish}
          type={NotiType.NOTICE}
        />
      )}
    </>
  );
};

export default PushAlarm;
