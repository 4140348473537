import React, {useEffect, useState} from 'react';
import {Button, message, Modal, Table, Tag} from "antd";
import {ConvoService} from "../services/ConvoService";
import {AnswerData, AnswerResultData} from "../types/answerResultTypes";
import {ColumnsType} from "antd/es/table";
import {BiKey} from "react-icons/bi";
import Colors from "../styles/colors";
import {QnType, qnTypeLabel} from "../types/enumtype/qnEnumtype";
import {CounselingService} from "../services/CounselingService";
import {PsyTestService} from "../services/PsyTestService";

interface ModalProps {
    type?: string;
    id: string;
    title: string;
    open: boolean;
    close: () => void;
}

const AnswerModal = ({type, id, title, open, close}: ModalProps) => {
    const [data, setData] = useState<AnswerResultData[]>([]);

    useEffect(() => {
        if (type === 'review') {
            getReviewAnswers();
        } else if (type === 'counseling') {
            getCounselingAnswers();
        }
        // else if (type === 'psytest') {
        //     getPsyTestAnswers();
        // }
    }, []);

    const getReviewAnswers = () => {
        try {
            ConvoService.getReviewAnswers(id).then((res) => {
                if (res.data.success) {
                    setData(res.data.result);
                } else {
                    throw new Error(res.data.message);
                }
            });
        } catch (e: any) {
            message.error('데이터를 불러오는데 실패했습니다.');
            console.error(e.message);
        }
    }

    const getCounselingAnswers = async () => {
        try {
            await CounselingService.getAnswers(id).then((res) => {
                if (res.data.success) {
                    setData(res.data.result);
                } else {
                    throw new Error(res.data.message);
                }
            });
        } catch (e: any) {
            message.error('데이터를 불러오는데 실패했습니다.');
            console.error(e.message);
        }
    }

    const columns: ColumnsType<AnswerResultData> = [
        {
            title: '번호',
            dataIndex: 'seq',
            key: 'seq',
            align: 'center',
            width: 50,
        },
        {
            title: '질 문',
            children: [
                {
                    dataIndex: 'isKeyQn',
                    key: 'isKeyQn',
                    align: 'center',
                    render: (isKeyQn: boolean) => {
                        return isKeyQn ? <BiKey size={24} color={Colors.emphasis_300}/> : null;
                    }
                },
                {
                    title: '질문타입',
                    dataIndex: 'qnType',
                    key: 'qnType',
                    align: 'center',
                    render: (qnType: QnType) => {
                        return <Tag>{qnTypeLabel[qnType]}</Tag>
                    }
                },
                {
                    title: <div style={{textAlign: 'center'}}>내용</div>,
                    dataIndex: 'content',
                    key: 'content',
                },
            ]
        },
        {
            title: '답 변',
            dataIndex: 'answers',
            key: 'answers',
            align: 'center',
            children: [
                {
                    title: '내용',
                    dataIndex: 'answers',
                    key: 'answer',
                    align: 'center',
                    render: (answers: AnswerData[]) => (
                        <>
                            {answers.map((answer, index) => (
                                <div
                                    className="table-cell-seperated left-align"
                                    key={index}>{answer.answer}</div>
                            ))}
                        </>
                    ),
                },
                {
                    title: '유저 수',
                    dataIndex: 'answers',
                    key: 'count',
                    align: 'center',
                    render: (answers: AnswerData[]) => (
                        <>
                            {answers.map((answer, index) => (
                                <div key={index} className="table-cell-seperated">
                                    {answer.count} 명</div>
                            ))}
                        </>
                    ),
                }
            ]
        }
    ];
    const modalTitle = type === 'review' ? '리뷰/평점' : '상담 답변';

    return (
        <Modal
            className="common-modal"
            title={
                <div className="common-modal-title">
                    <div style={{fontWeight: 400}}>{title}
                        <div style={{fontWeight: 600}}>{modalTitle}</div>
                    </div>
                </div>
            }
            open={open}
            footer={null}
            onCancel={close}
            width={1000}
        >
            <div className='common-modal-content'>
                <Table
                    size="small"
                    bordered
                    columns={columns}
                    dataSource={data}
                    pagination={{position: ['bottomCenter'], showSizeChanger: true}}
                    // scroll={{y: '60vh'}}
                />
            </div>
        </Modal>
    );
};

export default AnswerModal;
