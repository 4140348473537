import axios, { AxiosResponse } from 'axios';
import { StageType } from '../types/enumtype/stageEnumtype';
import { ConvoQnData, ConvoReqData } from '../types/convoTypes';
import { ApiResponseArrayData } from '../types/apiResponseDataTypes';
import { ReviewResultChartData } from 'components/ReviewResultChart';

const apiUrl = process.env.REACT_APP_API_URL + '/admin/convo';
export const ConvoService = {
  async getByStage(stage: StageType): Promise<AxiosResponse<any>> {
    return await axios.get(`${apiUrl}?stage=${stage}`);
  },
  async create(reqBody: ConvoReqData): Promise<AxiosResponse<any>> {
    return await axios.post(`${apiUrl}`, reqBody);
  },
  async update(reqBody: any, csId: string): Promise<AxiosResponse<any>> {
    return await axios.put(`${apiUrl}/${csId}`, reqBody);
  },
  async delete(counselId: string): Promise<AxiosResponse<any>> {
    return await axios.delete(`${apiUrl}/${counselId}`);
  },

  /*Qns*/
  async createQns(reqBody: any, cvId: string): Promise<AxiosResponse<any>> {
    return await axios.post(`${apiUrl}/${cvId}/qns`, reqBody);
  },

  async getQns(
    cvId: string,
  ): Promise<AxiosResponse<ApiResponseArrayData<ConvoQnData>>> {
    return await axios.get(`${apiUrl}/${cvId}/qns`);
  },

  /** app review */
  async getAppReviewDatas(): Promise<
    AxiosResponse<ApiResponseArrayData<ReviewResultChartData>>
  > {
    return await axios.get(`${apiUrl}/review/app`);
  },

  /** review answers */
  async getReviewAnswers(
    mainStageId: string,
  ): Promise<AxiosResponse<ApiResponseArrayData<any>>> {
    return await axios.get(`${apiUrl}/reviewAnswers/${mainStageId}`);
  },
  async deleteAnswers(cvId: string): Promise<AxiosResponse<any>> {
    return await axios.delete(`${apiUrl}/answers/${cvId}`);
  },
};
