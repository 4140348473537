import React, { useEffect, useState } from 'react';
import { Button, Card, Form, FormInstance, Input, Select } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import { IoCopyOutline } from 'react-icons/io5';
import { HiPencilSquare } from 'react-icons/hi2';
import Colors from '../../../styles/colors';
import { AiOutlineDelete } from 'react-icons/ai';
import { PageMode } from '../../../types/componentprops/pageTypes';
import AoFormList from '../AoFormList';
import { StageType } from '../../../types/enumtype/stageEnumtype';
import {
  QnType,
  qnTypeLabel,
  qnTypeListMap,
} from '../../../types/enumtype/qnEnumtype';
import { ConvoData, ConvoQnData } from '../../../types/convoTypes';

interface QnFormListProps {
  form: FormInstance<any>;
  mode: PageMode;
  initialValues: ConvoData[] | null;
}

const AppReviewQnFormList = ({
  form,
  mode,
  initialValues,
}: QnFormListProps) => {
  const [editingQnIndex, setEditingQnIndex] = useState<number>(0);

  useEffect(() => {
    form.setFieldsValue({
      convoQns: initialValues || [{}],
    });
    return () => {
      form.resetFields();
    };
  }, [initialValues, form, mode]);

  const handleSelectQnType = (value: QnType, index: number) => {
    if (value !== QnType.MCQ) {
      form.setFieldsValue({
        ['convoQns']: {
          [index]: {
            qnType: value,
            answerOptions: null,
          },
        },
      });
    } else {
      form.setFieldsValue({
        ['convoQns']: {
          [index]: {
            qnType: value,
            answerOptions: [{}],
          },
        },
      });
    }
  };

  const handleOnDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const currentList: ConvoQnData[] = form.getFieldsValue().convoQns;
    const newList: ConvoQnData[] = JSON.parse(JSON.stringify(currentList));
    const draggingItemIndex = result.source.index;
    const dropItemIndex = result.destination.index;
    // 드래그한 요소를 배열에서 삭제
    const removeForm = newList.splice(draggingItemIndex, 1);
    // 드롭한 위치에 드래그한 요소를 추가
    newList.splice(dropItemIndex, 0, removeForm[0]);
    newList.forEach((value, index) => {
      value.seq = index + 1;
    });
    form.setFieldsValue({ ['convoQns']: newList });
  };

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="convoQns">
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            <div className={'layout-container three-to-two'}>
              <div>
                <Form.List name={'convoQns'}>
                  {(fields, { add, remove }) => {
                    let currentQnType = form.getFieldValue([
                      'convoQns',
                      editingQnIndex,
                      'qnType',
                    ]);
                    if (!currentQnType) {
                      currentQnType = QnType.MCQ;
                    }
                    return (
                      <>
                        {fields.map((field, index) => {
                          return (
                            <div key={field.key}>
                              <Draggable
                                draggableId={String(field.key)}
                                index={index}
                              >
                                {(provided) => {
                                  return (
                                    <div
                                      key={field.key}
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <Card
                                        className="common-card"
                                        key={field.key}
                                        onClick={() => {
                                          setEditingQnIndex(index);
                                        }}
                                        extra={
                                          <>
                                            {/*직상증 표시*/}
                                            {editingQnIndex === index && (
                                              <Button ghost>
                                                <HiPencilSquare
                                                  size={20}
                                                  color={Colors.secondary_300}
                                                />
                                              </Button>
                                            )}
                                            {/*복사하기 버튼*/}
                                            <Button
                                              ghost
                                              onClick={() => {
                                                const newQn =
                                                  form.getFieldValue([
                                                    'convoQns',
                                                    index,
                                                  ]);
                                                newQn.id = uuidv4();
                                                newQn.seq = fields.length + 1;
                                                add(newQn);
                                              }}
                                            >
                                              <IoCopyOutline
                                                size={20}
                                                color={'#555555'}
                                              />
                                            </Button>
                                            {/*삭제하기 버튼*/}
                                            <Button
                                              ghost
                                              onClick={() => remove(field.name)}
                                            >
                                              <AiOutlineDelete
                                                size={20}
                                                color={'#555555'}
                                              />
                                            </Button>
                                          </>
                                        }
                                      >
                                        <div className={'common-card-content'}>
                                          <div className={'qn-outer'}>
                                            <div className={'qn-number'}>
                                              Q. {Number(field.name) + 1}
                                            </div>
                                            <Form.Item
                                              name={[index, 'qnType']}
                                              initialValue={currentQnType}
                                              rules={[
                                                {
                                                  required: true,
                                                  message:
                                                    '질문타입을 선택해주세요.',
                                                },
                                              ]}
                                            >
                                              <Select
                                                style={{ width: 120 }}
                                                placeholder={'질문타입'}
                                                onChange={(value: QnType) => {
                                                  handleSelectQnType(
                                                    value,
                                                    index,
                                                  );
                                                }}
                                                options={qnTypeListMap[
                                                  StageType.APP_REVIEW
                                                ].map((item) => {
                                                  return {
                                                    label: qnTypeLabel[item],
                                                    value: item,
                                                  };
                                                })}
                                              ></Select>
                                            </Form.Item>
                                            <Form.Item
                                              name={[index, 'stage']}
                                              initialValue={
                                                StageType.APP_REVIEW
                                              }
                                              style={{ display: 'none' }}
                                            ></Form.Item>
                                            <Form.Item
                                              name={[field.name, 'id']}
                                              style={{ display: 'none' }}
                                              initialValue={uuidv4()}
                                            ></Form.Item>
                                            <Form.Item
                                              name={[field.name, 'seq']}
                                              initialValue={field.key + 1}
                                              style={{ display: 'none' }}
                                            ></Form.Item>
                                            <Form.Item
                                              name={[field.name, 'content']}
                                              rules={[
                                                {
                                                  required: true,
                                                  message:
                                                    '질문을 입력해주세요.',
                                                },
                                              ]}
                                            >
                                              <Input.TextArea
                                                className={'qn-input'}
                                                placeholder={
                                                  '질문을 입력해주세요.'
                                                }
                                                autoSize={{ minRows: 4 }}
                                              />
                                            </Form.Item>
                                          </div>
                                          {currentQnType === QnType.MCQ && (
                                            <div className={'ao-outer'}>
                                              <AoFormList
                                                form={form}
                                                qnIndex={index}
                                              />
                                            </div>
                                          )}
                                        </div>
                                      </Card>
                                    </div>
                                  );
                                }}
                              </Draggable>
                            </div>
                          );
                        })}
                        {provided.placeholder}
                        <div className="qn-add-btn-group">
                          <Form.Item>
                            <Button type="dashed" onClick={() => add()} block>
                              + 질문 추가
                            </Button>
                          </Form.Item>
                        </div>
                      </>
                    );
                  }}
                </Form.List>
              </div>
            </div>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
export default AppReviewQnFormList;
