import React, { useEffect, useState } from 'react';
import ImageUpload from '../../components/ImageUpload';
import {
  Avatar,
  Button,
  Dropdown,
  Form,
  Input,
  message,
  Popover,
  Table,
} from 'antd';
import { DecorationService } from '../../services/DecorationService';
import { RcFile } from 'antd/es/upload/interface';
import { AssetType, assetTypeLabel } from '../../types/enumtype/assetEnumtype';
import { Section } from '../../components/TriSectionLayout';
import { AnyObject } from 'antd/es/_util/type';
import { EditOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { AssetData } from '../../types/assetTypes';
import { AiOutlineDelete } from 'react-icons/ai';
import UseConfirmDelete from '../../utils/useHook/UseConfirmDelete';
import { RiSettings3Fill } from 'react-icons/ri';
import { IoMdRefresh } from 'react-icons/io';
import ModifyAssetModal from './ModifyAssetModal';
import { ImImage } from 'react-icons/im';

const Decoration = ({ assetType }: { assetType: AssetType }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [selectedFiles, setSelectedFiles] = useState<(RcFile | null)[]>([
    null,
    null,
    null,
  ]);
  const [form] = Form.useForm();
  const [data, setData] = useState<AssetData[]>();
  const [selectedRecord, setSelectedRecord] = useState<AssetData>();
  const [modifyModalVsb, setModifyModalVsb] = useState(false);

  useEffect(() => {
    getDecoAssets();
    return () => {
      form.resetFields();
    };
  }, [assetType]);

  const getDecoAssets = async () => {
    const res = await DecorationService.getDecoAssets(assetType);
    if (res.data.success) {
      setData(res.data.result);
    } else {
      throw new Error(res.data.message);
    }
  };

  const handleFileChange = (index: number, file: RcFile | null) => {
    setSelectedFiles((prevFiles) => {
      const newFiles = [...prevFiles];
      newFiles[index] = file;
      return newFiles;
    });
  };

  const clearForm = () => {
    form.resetFields();
    // setSelectedFile(undefined);
  };

  const handleClickCancelCreate = () => {
    clearForm();
  };

  const handleFinishCreate = async (values: AnyObject) => {
    if (selectedFiles.some((file) => file === null)) {
      if (selectedFiles.every((file) => file === null)) {
        message.error('이미지를 등록해 주세요');
        return;
      }
      if (selectedFiles.some((file) => file === null)) {
        message.error('모든 이미지를 등록해 주세요');
        return;
      }
    }
    await form.validateFields();
    const formData = new FormData();
    Object.entries(values).forEach(([key, value]) =>
      formData.append(key, value),
    );
    formData.append('assetType', assetType);
    selectedFiles.forEach((file, index) => {
      if (file) {
        index === 0
          ? formData.append('image', file)
          : formData.append(`image${index + 1}`, file);
      }
      // if (file) formData.append(`image${index + 1}`, file);
    });
    await create(formData);
  };

  const create = async (formData: FormData) => {
    const res = await DecorationService.createDecoAsset(formData);
    if (res.data.success) {
      message.success('생성되었습니다.');
      await getDecoAssets();
      setSelectedFiles([null, null, null]);
      setTimeout(() => {
        clearForm();
      }, 400);
    } else {
      throw new Error(res.data.message);
    }
  };

  const handleModifyFinish = async (
    values: AssetData,
    selectedFiles: (RcFile | null)[],
  ) => {
    const formData = new FormData();
    Object.entries(values).forEach(([key, value]) =>
      formData.append(key, value),
    );
    // selectedFile && formData.append('image', selectedFile[0] as File);
    selectedFiles[0] && formData.append('image', selectedFiles[0] as File);
    selectedFiles[1] && formData.append('image2', selectedFiles[1] as File);
    selectedFiles[2] && formData.append('image3', selectedFiles[2] as File);
    formData.append('assetType', assetType);
    modify(formData);
  };

  const modify = async (formData: FormData) => {
    if (!selectedRecord) return;
    const res = await DecorationService.modifyDecoAsset(
      formData,
      selectedRecord!.id,
    );
    if (res.data.success) {
      await getDecoAssets();
      setModifyModalVsb(false);
      setSelectedRecord(undefined);
      message.success('수정되었습니다.');
    } else {
      throw new Error(res.data.message);
    }
  };

  const handleDelete = async (id: string) => {
    try {
      const res = await DecorationService.deleteDecoAsset(id);
      if (res.data.success) {
        await getDecoAssets();
      } else {
        throw new Error(res.data.message);
      }
    } catch (e: any) {
      if (e.response.status === 409) {
        // const msg = e.response.data.message;
        // message.warning(msg);
        const name = data?.find((item: any) => item.id === id)?.name;
        message.warning(
          `'${name}'을(를) 사용하고 있는 컨텐츠가 있습니다. 컨텐츠를 먼저 삭제해 주세요.`,
        );
      }
    }
  };
  const { handleClickDelete, confirmModal } = UseConfirmDelete({
    handleDelete,
  });

  const items = [
    {
      key: '1',
      label: (
        <>
          <EditOutlined />
          <span className={'menu-item-text'}>수정하기</span>
        </>
      ),
      onClick: () => selectedRecord && setModifyModalVsb(true),
    },
    {
      key: '2',
      label: (
        <>
          <AiOutlineDelete />
          <span className={'menu-item-text'}>삭제하기</span>
        </>
      ),
      onClick: () => selectedRecord && handleClickDelete(selectedRecord?.id),
    },
  ];

  /*const menu = (
        <Menu>
            <Menu.Item key="1" icon={<EditOutlined/>} onClick={handleClickModify}>
                수정하기
            </Menu.Item>
            <Menu.Item key="2" icon={<AiOutlineDelete/>}
                       onClick={() => selectedRecord && handleClickDelete(selectedRecord?.id)}>
                삭제하기
            </Menu.Item>
        </Menu>
    )*/

  const defaultImageIcon = <ImImage />;

  const columns: ColumnsType<AssetData> = [
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      width: 100,
      align: 'center',
      render: (value: string, record: AssetData, index: number) => (
        <Dropdown
          trigger={['click']}
          menu={{ items }}
          // overlay={menu}
          placement="bottomLeft"
          arrow
        >
          <div className="setting-icon-container">
            <RiSettings3Fill
              size={24}
              onClick={() => {
                setSelectedRecord(record);
              }}
            />
            {/*<SettingTwoTone twoToneColor={Colors.primary_300}
                                        style={{fontSize: '1.3rem'}}
                                        onClick={() => {
                                            setSelectedRecord(record);
                                        }}
                        />*/}
          </div>
        </Dropdown>
      ),
    },
    {
      title: '이름',
      dataIndex: 'name',
      key: 'name',
      width: 100,
      align: 'center',
    },
    {
      title: '이미지',
      dataIndex: 'imageId',
      key: 'imageId',
      width: 100,
      align: 'center',
      render: (value: string) => (
        <div className="image-data-container centered-outer">
          {!value ? (
            <Avatar shape="square" size={64} icon={defaultImageIcon} /> // 이미지가 없을 경우 기본 이미지로 대체
          ) : (
            <img src={`${apiUrl}/images/${value}`} alt="asset" width={50} />
          )}
        </div>
      ),
    },
    {
      title: (
        <>
          바탕
          <br />
          이미지
        </>
      ),
      dataIndex: 'image2Id',
      key: 'imageId',
      width: 100,
      align: 'center',
      render: (value: string, record: AssetData, index: number) => (
        <div className="image-data-container centered-outer">
          {!value ? (
            <Avatar shape="square" size={64} icon={defaultImageIcon} />
          ) : (
            <img src={`${apiUrl}/images/${value}`} alt="avatar" width={50} />
          )}
        </div>
      ),
    },
    {
      title: (
        <>
          오브젝트
          <br />
          이미지
        </>
      ),
      dataIndex: 'image3Id',
      key: 'imageId',
      width: 100,
      align: 'center',
      render: (value: string, record: AssetData, index: number) => (
        //desc: 이미지가 없을 경우 기본 이미지로 대체
        <div className="image-data-container centered-outer">
          {!value ? (
            <Avatar shape="square" size={64} icon={defaultImageIcon} />
          ) : (
            <img src={`${apiUrl}/images/${value}`} alt="avatar" width={50} />
          )}
        </div>
      ),
    },
    {
      title: '소개글',
      dataIndex: 'description',
      key: 'description',
      width: 300,
      align: 'center',
      render: (value: string, record: AssetData, index: number) => {
        const longText = value ? value.length > 100 : false;
        return longText ? (
          <Popover
            content={
              <div style={{ maxWidth: 300, maxHeight: 300, overflow: 'auto' }}>
                {value}
              </div>
            }
            trigger="hover"
            placement="bottom"
          >
            <span>{value.slice(0, 100)}...</span>
          </Popover>
        ) : (
          <span>{value}</span>
        );
      },
    },
    {
      title: '마음나무 개수',
      dataIndex: 'mindtreeAmount',
      key: 'mindtreeAmount',
      width: 120,
      align: 'center',
    },
  ];

  return (
    <>
      <div className="layout-container two-to-three">
        <Section
          title={`새 ${assetTypeLabel[assetType]} 생성`}
          extra={
            <Button
              icon={<IoMdRefresh size={20} />}
              onClick={handleClickCancelCreate}
            ></Button>
          }
        >
          <div>
            <Form
              className="create-form"
              name="create-form"
              form={form}
              autoComplete="off"
              colon={false}
              labelCol={{ span: 5 }}
              requiredMark={false}
              onFinish={handleFinishCreate}
            >
              {assetType === AssetType.BACKGROUND ? (
                <div className="justify">
                  <Form.Item name={'image'}>
                    <ImageUpload
                      title={'전체 이미지'}
                      // description={`가로 : 세로 19.5 : 9 비율의 이미지를 업로드해 주세요.`}
                      rule={
                        <>
                          <br />
                          가로 : 세로 19.5 : 9 비율의
                          <br />
                          이미지를 업로드하여 주세요.
                        </>
                      }
                      onFileChange={(file) => handleFileChange(0, file)}
                    />
                  </Form.Item>
                  <Form.Item name={'image2'}>
                    <ImageUpload
                      title={'바탕 이미지'}
                      rule={
                        <>
                          전체 이미지와
                          <br />
                          같은 사이즈의 이미지를
                          <br />
                          업로드하여 주세요.
                        </>
                      }
                      onFileChange={(file) => handleFileChange(1, file)}
                    />
                  </Form.Item>
                  <Form.Item name={'image3'}>
                    <ImageUpload
                      title={'오브젝트 이미지'}
                      rule={
                        <>
                          전체 이미지 안의 오브젝트와
                          <br />
                          같은 사이즈의 이미지를
                          <br />
                          업로드하여 주세요.
                        </>
                      }
                      onFileChange={(file) => handleFileChange(2, file)}
                    />
                  </Form.Item>
                </div>
              ) : (
                <>
                  <Form.Item name={'imageType'}>
                    <ImageUpload
                      onFileChange={(file) => handleFileChange(0, file)}
                    />
                  </Form.Item>
                </>
              )}
              <Form.Item
                name="name"
                label={`${assetTypeLabel[assetType]} 이름`}
                rules={[
                  {
                    required: true,
                    message: `${assetTypeLabel[assetType]} 이름을 입력해 주세요`,
                  },
                ]}
              >
                <Input placeholder={`${assetTypeLabel[assetType]} 이름 입력`} />
              </Form.Item>
              <Form.Item name="description" label="소개글" initialValue={''}>
                <Input.TextArea
                  placeholder="소개글 입력"
                  style={{ minHeight: 200, overflow: 'auto' }}
                />
              </Form.Item>

              <Form.Item
                name="mindtreeAmount"
                label="마음나무 개수"
                rules={[
                  { required: true, message: '마음나무 개수를 설정해 주세요' },
                ]}
                initialValue={0}
              >
                <Input
                  type="number"
                  placeholder="마음나무 개수 입력"
                  min={0}
                  style={{ width: '100%' }}
                />
              </Form.Item>

              <div className="centered-outer">
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ minWidth: 100 }}
                >
                  생성
                </Button>
              </div>
            </Form>
          </div>
        </Section>

        {/* Form Item : 이름, 이미지, 설명, 최소레벨, 가격 */}
        <Section title={`${assetTypeLabel[assetType]} 목록`}>
          <Table
            size={'small'}
            dataSource={data}
            rowKey={'id'}
            columns={
              assetType === AssetType.BACKGROUND
                ? columns
                : [...columns.slice(0, 3), ...columns.slice(5)]
            } // AVATAR 는 이미지2, 이미지3 컬럼 제외
            pagination={{ pageSize: 5 }}
          />
        </Section>
      </div>
      {confirmModal}
      {modifyModalVsb && (
        <ModifyAssetModal
          type={assetType}
          open={modifyModalVsb}
          close={() => setModifyModalVsb(false)}
          record={selectedRecord!}
          handleFinish={handleModifyFinish}
        />
      )}
    </>
  );
};

export default Decoration;
