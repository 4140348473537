import { createSlice } from '@reduxjs/toolkit';

interface BreadcrumbState {
  breadcrumb: [];
}

const initialState = { breadcrumb: [] } as BreadcrumbState;

const breadcrumbSlice = createSlice({
  name: 'breadcrumb',
  initialState,
  reducers: {
    saveBreadcrumb: (state, action) => {
      state.breadcrumb = action.payload;
    },
  },
});

export const { saveBreadcrumb } = breadcrumbSlice.actions;
export default breadcrumbSlice.reducer;
