import React, {useEffect, useState} from 'react';
import {Button, Dropdown, message, Popover, SelectProps, Space, Table, Tag} from 'antd';
import {CounselingData, CounselingFormData} from "../../types/counselingTypes"
import {TagData, TagMapValue} from "../../types/tagTypes";
import {CounselingService} from 'services/CounselingService';
import {CounselingType, counselingTypeLabel, counselingTypeOptions} from "../../types/enumtype/counselingEnumtype";
import {EmotionType} from "../../types/enumtype/emotionEnumType";
import UseConfirmDelete from "../../utils/useHook/UseConfirmDelete";
import {ColumnsType} from "antd/es/table";
import moment from "moment/moment";
import {RiSettings3Fill} from "react-icons/ri";
import {CloseCircleOutlined, EditOutlined, PlusOutlined} from "@ant-design/icons";
import {AiOutlineDelete} from "react-icons/ai";
import CounselingModal from "./CounselingModal";
import {IoMdCheckbox} from "react-icons/io";
import {useNavigate} from "react-router-dom";
import {FiUpload} from "react-icons/fi";
import {MdFindInPage, MdOutlineRateReview} from "react-icons/md";
import Colors from "../../styles/colors";
import AnswerModal from "../../components/AnswerModal";

const Counseling = () => {
        const navigate = useNavigate();
        const [data, setData] = useState<CounselingData[]>();
        const [emotionTagOptions, setEmotionTagOptions] = useState<SelectProps['options']>([]);
        const [negEmotionTagOptions, setNegEmotionTagOptions] = useState<SelectProps['options']>([]);
        const [fieldTagOptions, setFieldTagOptions] = useState<SelectProps['options']>([]);
        const [selectOptions] = useState<SelectProps['options']>(counselingTypeOptions);
        const [selectedRecord, setSelectedRecord] = useState<CounselingData>();
        const [modalVsb, setModalVsb] = useState<boolean>(false);
        const [reviewModalVsb, setReviewModalVsb] = useState<boolean>(false);
        const [answerModalVsb, setAnswerModalVsb] = useState<boolean>(false);
        const [selectedRows, setSelectedRows] = useState<CounselingData[]>([]);

        useEffect(() => {
            getData();
            getTags();
        }, []);

        const getData = async () => {
            try {
                const res = await CounselingService.getCsl();
                if (res.data.success) {
                    setData(res.data.result);
                } else {
                    throw new Error(res.data.message);
                }
            } catch (e: any) {
                message.error('데이터를 불러오는데 실패했습니다.');
                console.error(e.message);
            }
        }
        const getTags = async () => {
            try {
                const res = await CounselingService.getTags();
                if (res.data.success) {
                    const negEmotionTagOptions = res.data.result.EMOTION.filter((item: TagMapValue) => item.type === EmotionType.NEGATIVE).map((item: TagMapValue) => {
                        return {
                            key: item.id,
                            label: item.name,
                            value: item.id
                        }
                    });
                    setNegEmotionTagOptions(negEmotionTagOptions);

                    const emotionTagOptions = res.data.result.EMOTION.map((item: TagMapValue) => {
                        return {
                            key: item.id,
                            label: item.name,
                            value: item.id
                        }
                    });
                    setEmotionTagOptions(emotionTagOptions);

                    const fieldTagOptions = res.data.result.FIELD.map((item: TagMapValue) => {
                            return {
                                key: item.id,
                                label: item.name,
                                value: item.id
                            }
                        }
                    );
                    setFieldTagOptions(fieldTagOptions);
                } else {
                    throw new Error(res.data.message);
                }
            } catch (e: any) {
                console.error(e.message);
                message.error('데이터를 불러오는데 실패했습니다.');
            }
        }

        const handleCloseModal = () => {
            setModalVsb(false);
            setSelectedRecord(undefined);
        }
        /*CRUD api call*/
        const handleFinish = async (values: CounselingFormData) => {
            const {emotionIds, fieldId, ...rest} = values;
            const tagIds = fieldId ? [...emotionIds, fieldId] : emotionIds;

            const newValues = {
                ...rest,
                tagIds,
            };
            create(newValues);
        }

        const create = async (reqBody: any) => {
            try {
                const res = await CounselingService.createCsl(reqBody);
                if (res.data.success) {
                    message.success('등록되었습니다.');
                    await getData();
                    setModalVsb(false);
                } else {
                    throw new Error(res.data.message);
                }
            } catch (e: any) {
                console.error(e.message);
                message.error('상담 등록에 실패했습니다.');
            }
        }

        const handleFinishModify = async (values: CounselingFormData) => {
            const {emotionIds, fieldId, ...rest} = values;
            const tagIds = fieldId ? [...emotionIds, fieldId] : emotionIds;

            const newValues = {
                ...rest,
                tagIds,
            };
            modify(newValues);
        }

        const modify = async (reqBody: any) => {
            try {
                if (!selectedRecord) return;
                const res = await CounselingService.modifyCsl(reqBody, selectedRecord?.id);
                if (res.data.success) {
                    message.success('수정되었습니다.');
                    setModalVsb(false);
                    getData();
                    setSelectedRecord(undefined);
                } else {
                    throw new Error(res.data.message);
                }
            } catch (e: any) {
                console.error(e.message);
                message.error('상담 수정에 실패했습니다.');
            }
        }

        const handleDelete = async (id: string) => {
            const res = await CounselingService.deleteCsl(id);
            if (res.data.success) {
                message.success('삭제되었습니다.');
                await getData();
            } else {
                throw new Error(res.data.message);
            }
        }
        const {handleClickDelete, confirmModal} = UseConfirmDelete({handleDelete, content: "삭제 시 작성된 질문도 함께 삭제됩니다."});

        const handleClickPublish = async (id: string, isPublished: boolean) => {
            const res = await CounselingService.updateIsPublished(id, isPublished);
            if (res.data.success) {
                message.success('공개 상태가 변경되었습니다.');
                await getData();
            } else {
                throw new Error(res.data.message);
            }
        }

        const handleClickPublishSelected = async (isPublished: boolean) => {
            const ids = selectedRows.map((item) => item.id);
            const reqBody = {
                ids,
                isPublished
            }
            const res = await CounselingService.updateIsPublishedSelected(reqBody);
            if (res.data.success) {
                message.success('공개 상태가 변경되었습니다.');
                await getData();
            } else {
                throw new Error(res.data.message);
            }
        }

        const handleClickEditQn = async (id: string, mode: string) => {
            navigate(`/counseling/${mode}/${id}`);
        }

        const handleClickReview = async (record: CounselingData) => {
            setSelectedRecord(record);
            setReviewModalVsb(true);
        }
        const handleClickAnswer = async (record: CounselingData) => {
            setSelectedRecord(record);
            setAnswerModalVsb(true);
        }
        /*Columns, Dropdown Menu*/

        const additionalItem1 = {
            key: '3',
            label: (
                <>
                    <FiUpload/>
                    <span className={'menu-item-text'}>공개하기</span>
                </>
            ),
            onClick: () => selectedRecord && handleClickPublish(selectedRecord?.id, true),
        }

        const additionalItem2 = {
            key: '4',
            label: (
                <>
                    <CloseCircleOutlined/>
                    <span className={'menu-item-text'}>공개취소</span>
                </>
            ),
            onClick: () => selectedRecord && handleClickPublish(selectedRecord?.id, false),
        }

        const items = [
            {
                key: '1',
                label: (
                    <>
                        <EditOutlined/>
                        <span className={'menu-item-text'}>수정하기</span>
                    </>
                ),
                onClick: () => selectedRecord && setModalVsb(true),
            },
            {
                key: '2',
                label: (
                    <>
                        <AiOutlineDelete/>
                        <span className={'menu-item-text'}>삭제하기</span>
                    </>
                ),
                onClick: () => selectedRecord && handleClickDelete(selectedRecord?.id),
            },
        ]

        const columns: ColumnsType<CounselingData> = [
            {
                title: '',
                dataIndex: 'id',
                key: 'id',
                width: 50,
                align: 'center',
                render: (value: string, record: CounselingData, index: number) => (
                    <Dropdown
                        trigger={['click']}
                        // menu={{items: record.isPublished ? [additionalItem2, ...items] : [additionalItem1, ...items]}}
                        menu={{items: record.isPublished ? [additionalItem2, ...items] : record.qnCount === 0 ? items : [additionalItem1, additionalItem2, ...items]}}
                        placement="bottomLeft" arrow
                    >
                        <div className="setting-icon-container">
                            <RiSettings3Fill
                                size={24}
                                onClick={() => {
                                    setSelectedRecord(record);
                                }}
                            />
                        </div>
                    </Dropdown>
                ),
            },
            {
                title: '공개 상태',
                dataIndex: 'isPublished',
                key: 'isPublished',
                align: 'center',
                width: 80,
                render: (isPublished: boolean) => (
                    <>{isPublished ? <IoMdCheckbox size={24}/> : <></>}</>
                )
            },
            {
                title: '제목',
                dataIndex: 'title',
                key: 'title',
                align: 'center',
                render: (value, record) => (
                    <Popover content={record.description} title="설명">
                        <span>{value}</span>
                    </Popover>
                ),
            },
            {
                title: '상담 타입',
                dataIndex: 'counselingType',
                key: 'counselingType',
                align: 'center',
                render: (value: CounselingType) => (
                    <Tag color={value === 'POS' ? 'blue' : value === 'NEG' ? 'red' : 'purple'}>
                        {counselingTypeLabel[value]}
                    </Tag>
                ),
            },
            {
                title: '태그',
                dataIndex: 'tags',
                key: 'tags',
                align: 'center',
                render: (values: TagData[]) => (
                    <div style={{maxWidth: '20vw', whiteSpace: 'normal'}}>
                        {values.map((tag: TagData) => (
                            <Tag
                                className="emotion-field-tag"
                                key={tag.id}
                                color={tag.div === 'EMOTION' ? 'default' : 'orange-inverse'}
                            >
                                {tag.name}
                            </Tag>
                        ))}
                    </div>
                ),
            },
            {
                title: '이용자수',
                dataIndex: 'answeredUserCount',
                key: 'answeredUserCount',
                align: 'center',
            },
            // 이용자 수가 0이 아닌 경우에만 답변, 리뷰/평점 보이도록 설정
            {
                title: '답변',
                dataIndex: 'answers',
                key: 'answers',
                align: 'center',
                render: (value: string, record: CounselingData) => {
                    return record.answeredUserCount > 0 ? (
                        <Space size="middle">
                            <Button
                                icon={<MdOutlineRateReview size={24} color={Colors.primary_250}/>}
                                type={'text'}
                                onClick={handleClickAnswer.bind(null, record)}
                            />
                        </Space>
                    ) : null;
                }
            },
            {
                title: '리뷰/평점',
                dataIndex: 'review',
                key: 'review',
                align: 'center',
                render: (value: string, record: CounselingData) => {
                    return record.answeredUserCount > 0 ? (
                        <Space size="middle">
                            <Button
                                icon={<MdFindInPage size={24} color={Colors.primary_250}/>}
                                type={'text'}
                                onClick={handleClickReview.bind(null, record)}
                            />
                        </Space>
                    ) : null;
                }
            },
            {
                title: '등록일시',
                dataIndex: 'createdAt',
                key: 'createdAt',
                align: 'center',
                render: (createdAt: string) => (
                    <>{moment(createdAt).format('YYYY-MM-DD HH:mm')}</>
                )
            },
            {
                title: '질문 수',
                dataIndex: 'qnCount',
                key: 'qnCount',
                align: 'center',
            },
            {
                title: '',
                dataIndex: 'id',
                key: 'id',
                align: 'center',
                width: 100,
                render: (id, record, index) => (
                    <Space size="middle">
                        {/*{record.hasQns ? (*/}
                        {record.qnCount > 0 ? (
                            <Button
                                icon={<EditOutlined/>}
                                type="primary" ghost
                                onClick={() => handleClickEditQn(id, 'modify')}
                            >
                                질문 보기
                            </Button>
                        ) : (
                            <Button
                                type="primary"
                                icon={<PlusOutlined/>}
                                onClick={() => handleClickEditQn(id, 'create')}
                            >
                                질문 등록
                            </Button>
                        )}
                    </Space>
                ),
            },
        ];

        return (
            <>
                <div className="content-header">
                    <h2>상담 목록</h2>
                    <Button
                        className="new-btn"
                        type="primary"
                        onClick={() => setModalVsb(true)}
                        icon={<PlusOutlined/>}
                    >
                        새 상담 등록
                    </Button>
                    <Button
                        className="btn"
                        disabled={selectedRows.length === 0
                            // 모든 선택된 행이 공개 상태이거나, 질문 수가 0인 경우 비활성화
                            || selectedRows.every((item) => item.isPublished)
                            || selectedRows.some((item) => item.qnCount === 0)
                        }
                        // onClick={()=> handleClickPublishSelected(true)
                        onClick={handleClickPublishSelected.bind(null, true)
                        }
                    >
                        공개하기
                    </Button>
                    <Button
                        className="btn"
                        disabled={selectedRows.length === 0
                            || selectedRows.every((item) => !item.isPublished)
                        }
                        onClick={handleClickPublishSelected.bind(null, false)}
                    >
                        공개취소
                    </Button>
                </div>
                <Table
                    size={'small'}
                    columns={columns}
                    dataSource={data}
                    rowKey={'id'}
                    rowSelection={{
                        type: 'checkbox',
                        onChange: (selectedRowKeys, selectedRows) => setSelectedRows(selectedRows)
                    }}
                    pagination={{position: ['bottomCenter']}}
                >
                </Table>
                {confirmModal}
                {modalVsb &&
                    <CounselingModal open={modalVsb}
                                     close={handleCloseModal}
                                     selectOptions={selectOptions}
                                     fieldTagOptions={fieldTagOptions}
                                     emotionTagOptions={emotionTagOptions}
                                     negEmotionTagOptions={negEmotionTagOptions}
                                     handleFinish={selectedRecord ? handleFinishModify : handleFinish}
                                     record={selectedRecord}
                    />
                }
                {reviewModalVsb && selectedRecord &&
                    <AnswerModal
                        type={'review'}
                        id={selectedRecord?.id}
                        title={selectedRecord?.title}
                        open={reviewModalVsb}
                        close={() => setReviewModalVsb(false)}
                    />
                }
                {answerModalVsb && selectedRecord &&
                    <AnswerModal
                        type={'counseling'}
                        id={selectedRecord?.id}
                        title={selectedRecord?.title}
                        open={answerModalVsb}
                        close={() => setAnswerModalVsb(false)}
                    />
                }
            </>
        );
    }
;

export default Counseling;
