import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Layout } from 'antd';
import HeaderBar from 'components-default/headerbar/HeaderBar';
import Sidebar from 'components-default/sidebar/SideBar';
import Main from 'components-default/main/Main';

const DefaultLayout = () => {
  const [collapsed, setCollapsed] = useState<boolean>(false);

  return (
    <Layout id="default-layout">
      <Sidebar collapsed={collapsed} />
      <Layout>
        <HeaderBar collapsed={collapsed} setCollapsed={setCollapsed} />
        <Main>
          <Outlet />
        </Main>
      </Layout>
    </Layout>
  );
};

export default DefaultLayout;
