import React, {CSSProperties} from 'react';
import { RotatingLines } from 'react-loader-spinner';
import styles from './Loader.module.scss';

const Loader = ({style}:{style?: CSSProperties }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.loader} style={style}>
        <RotatingLines
          strokeColor="grey"
          strokeWidth="5"
          animationDuration="0.75"
          width="30"
          visible={true}
        />
      </div>
    </div>
  );
};

export default Loader;
