import React, {useEffect, useState} from 'react';
import Title_cm3 from "../components-default/titles/Title_cm3";
import {Button, Tabs, Typography} from "antd";
import {emotionTypeLabel, emotionTypeList} from "../types/enumtype/emotionEnumType";
import GridCard, {DropResult} from "./GridCard";
import {TagMapValue} from "../types/tagTypes";
import {CounselingService} from "../services/CounselingService";
import {topicGroupLabel, topicGroupList} from "../types/enumtype/topicGroupEnumtype";

interface TagImportingProps {
    onDropEnd: (item: { contentList: TagMapValue[] }, dropResult: DropResult) => void;
}

const TagImporting = ({onDropEnd}: TagImportingProps) => {
    const [emotionData, setEmotionData] = useState<{
        POSITIVE: TagMapValue[],
        NEGATIVE: TagMapValue[]
    }>({POSITIVE: [], NEGATIVE: []});
    const [fieldData, setFieldData] = useState<TagMapValue[]>();
    const [topicData, setTopicData] = useState<any>();
    const [selectedQnType, setSelectedQnType] = useState<string>('EMOTION');

    useEffect(() => {
        getTags();
        getTopics();
    }, []);

    const getTags = async () => {
        const res = await CounselingService.getTags();
        if (res.data.success) {
            setEmotionData({
                POSITIVE: res.data.result.EMOTION.filter((tag: TagMapValue) => tag.type === 'POSITIVE'),
                NEGATIVE: res.data.result.EMOTION.filter((tag: TagMapValue) => tag.type === 'NEGATIVE')
            });
            setFieldData(res.data.result.FIELD);
        } else {
            throw new Error(res.data.message);
        }
    }

    const getTopics = async () => {
        const res = await CounselingService.getTopics();
        if (res.data.success) {
            setTopicData(res.data.result);
        } else {
            throw new Error(res.data.message);
        }
    }

    return (
        <div className={"importing-container"}>
            {/*<Typography.Title level={4} style={{ marginBottom: '1rem' }}>답변옵션 불러오기</Typography.Title>*/}
            <Tabs type='card' className={"importing-tabs"} defaultActiveKey="EMOTION" onChange={(key) => setSelectedQnType(key)}>
                <Tabs.TabPane tab="감정 태그" key="EMOTION">
                    {/* 감정 태그 불러오기 */}
                    <div className={"importing-content"}>
                        {selectedQnType === 'EMOTION' && (
                            <>
                                {emotionTypeList.map((emotionType) =>
                                        emotionData[emotionType].length > 0 && (
                                            <GridCard
                                                key={emotionType}
                                                title={emotionTypeLabel[emotionType]}
                                                optionType={emotionType}
                                                contentList={emotionData[emotionType]}
                                                style={{ marginBottom: '1rem' }}
                                                onDropEnd={onDropEnd}
                                            />
                                        )
                                )}
                            </>
                        )}
                    </div>
                </Tabs.TabPane>
                <Tabs.TabPane tab="분야 태그" key="FIELD">
                    {/* 분야 태그 불러오기 */}
                    <div className={"importing-content"}>
                        {selectedQnType === 'FIELD' && fieldData && (
                            <GridCard
                                key={'FIELD'}
                                optionType={'FIELD'}
                                contentList={fieldData}
                                style={{ marginBottom: '1rem' }}
                                onDropEnd={onDropEnd}
                            />
                        )}
                    </div>
                </Tabs.TabPane>
                <Tabs.TabPane tab="주제그룹(상담타입)" key="TOPIC">
                    {/* 주제그룹(상담타입) 불러오기 */}
                    <div className={"importing-content"}>
                        {selectedQnType === 'TOPIC' && topicData && (
                            <>
                                {topicGroupList.map((topicGroup) => (
                                    <GridCard
                                        key={topicGroup}
                                        title={topicGroupLabel[topicGroup]}
                                        optionType={'TOPIC'}
                                        contentList={topicData[topicGroup]}
                                        style={{ marginBottom: '1rem' }}
                                        onDropEnd={onDropEnd}
                                    />
                                ))}
                            </>
                        )}
                    </div>
                </Tabs.TabPane>
            </Tabs>
        </div>
    );

};

export default TagImporting;
