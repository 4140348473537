enum CounselingType {
    POS = "POS",
    NEG = "NEG",
    DAILY = "DAILY",
}

const counselingTypeList = [
    CounselingType.POS,
    CounselingType.NEG,
    CounselingType.DAILY,
]

const counselingTypeLabel = {
    [CounselingType.POS]: "긍정강화상담",
    [CounselingType.NEG]: "부정상담",
    [CounselingType.DAILY]: "데일리멘탈트레이닝",
}

const counselingTypeOptions = counselingTypeList.map((counselingType) => {
    return {
        label: counselingTypeLabel[counselingType],
        value: counselingType,
    }
})

export {CounselingType, counselingTypeList, counselingTypeLabel, counselingTypeOptions};
