import React, { useEffect, useState } from 'react';
import { Button, Form, Modal, message } from 'antd';
import { AiOutlineSave } from 'react-icons/ai';
import { ConvoQnStrFormData } from '../../types/convoTypes';
import { ConvoService } from '../../services/ConvoService';
import { useNavigate, useParams } from 'react-router-dom';
import QnFormList from '../../components/formlist/QnFormList';
import { StageType } from '../../types/enumtype/stageEnumtype';
import { PageMode } from '../../types/componentprops/pageTypes';
import { RiArrowGoBackLine } from 'react-icons/ri';
import { v4 as uuidv4 } from 'uuid';
import { MdOutlineDeleteForever, MdPreview } from 'react-icons/md';
import Colors from 'styles/colors';

const ConvoQns = () => {
  const params = useParams();
  const [initialValues, setInitialValues] = useState<any>(null);
  const { stage: stageParam, mode, cvId } = params;
  const stage = stageParam?.toUpperCase() as StageType;
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const previousPath = `/conversation/${stage?.toLowerCase()}`;

  useEffect(() => {
    if (mode === 'modify' && cvId) {
      getData();
    }
  }, [cvId]);
  const getData = async () => {
    if (!cvId) return;
    const res = await ConvoService.getQns(cvId);
    if (res.data.success) {
      setInitialValues(res.data.result);
    } else {
      if (res.data.message) {
        console.error(res.data.message);
        message.error('질문을 불러오는데 실패했습니다.');
        navigate(previousPath);
      }
    }
  };

  const handleSave = async () => {
    await form.submit();
  };
  const handleFinish = async (values: ConvoQnStrFormData) => {
    // id 없는 질문에 id 부여
    const qns = values['convoQns'];
    const newQns = qns.map((qn: any) => {
      if (!qn.id) {
        qn.id = uuidv4();
      }
      return qn;
    });
    await createQns(newQns);
  };

  const createQns = async (reqBody: any) => {
    if (!cvId) return;
    try {
      const res = await ConvoService.createQns(reqBody, cvId);
      if (res.data.success) {
        message.success('저장되었습니다.');
        form.resetFields();
        setTimeout(() => {
          navigate(previousPath);
        }, 400);
      } else {
        throw new Error(res.data.message);
      }
    } catch (e: any) {
      if (e.response.status && e.response.status === 409) {
        const msg = e.response.data.message;
        Modal.confirm({
          title: msg,
          content: '유저 답변을 삭제 후 저장하시겠습니까?',
          icon: null,
          okButtonProps: { type: 'primary', ghost: true },
          cancelButtonProps: {
            type: 'primary',
            style: { background: Colors.primary_250 },
          },
          okText: '취소',
          cancelText: '유저 답변 삭제',
          onOk() {
            Modal.destroyAll();
          },
          onCancel() {
            handleDeleteAnswers(cvId);
          },
        });
      }
    }
  };

  const handleOpenChatroom: () => void = () => {
    window.open(
      `/chat-preview/conversation/${cvId}`,
      'Child',
      'width=900, height=900',
    );
  };

  const handleDeleteAnswers = async (cvId: string) => {
    // 유저의 답변을 삭제하는 메서드
    try {
      const res = await ConvoService.deleteAnswers(cvId);
      if (res.data.success) {
        message.success('유저 답변이 삭제되었습니다.');
        setTimeout(() => {
          form.submit();
        }, 1000);
      } else {
        throw new Error(res.data.message);
      }
    } catch (e: any) {
      message.error('답변을 삭제하는데 실패했습니다.');
      console.error(e.message);
    }
  };

  return (
    <div>
      <div className={'save-btn-group'}>
        <Button
          icon={<AiOutlineSave />}
          className={'save-btn'}
          type={'primary'}
          onClick={handleSave}
        >
          질문 저장
        </Button>
        <Button
          icon={<RiArrowGoBackLine />}
          className={'cancel-btn'}
          type="primary"
          ghost
          onClick={() => navigate(previousPath)}
        >
          질문 등록 취소
        </Button>
        {mode === 'modify' && (
          <>
            <Button
              icon={<MdPreview />}
              className={'preview-btn'}
              type="primary"
              ghost
              onClick={handleOpenChatroom}
            >
              채팅 미리보기
            </Button>
            <Button
              icon={<MdOutlineDeleteForever />}
              className={'delete-btn'}
              type="primary"
              ghost
              onClick={() => {
                cvId &&
                  Modal.confirm({
                    title: '유저 답변 삭제',
                    content: '유저 답변을 삭제 후 저장하시겠습니까?',
                    icon: null,
                    okButtonProps: { type: 'primary', ghost: true },
                    cancelButtonProps: {
                      type: 'primary',
                      style: { background: Colors.primary_250 },
                    },
                    okText: '취소',
                    cancelText: '유저 답변 삭제',
                    onOk() {
                      Modal.destroyAll();
                    },
                    onCancel() {
                      handleDeleteAnswers(cvId);
                    },
                  });
              }}
            >
              답변 삭제하기
            </Button>
          </>
        )}
      </div>
      <div>
        <Form
          name="convo"
          form={form}
          autoComplete="off"
          colon={false}
          onFinish={handleFinish}
          requiredMark={false}
        >
          <QnFormList
            stage={stage as StageType}
            mode={mode as PageMode}
            form={form}
            initialValues={initialValues}
          />
        </Form>
      </div>
    </div>
  );
};

export default ConvoQns;
