import axios, {AxiosResponse} from 'axios';
import {ApiResponseData} from "../types/apiResponseDataTypes";
import {ConvoQnData, SaveAnswerReqBody} from "../types/userChatTypes";
import {StageType} from "../types/enumtype/stageEnumtype";

const apiUrl = process.env.REACT_APP_API_URL + '/user/convo';
const apiUrl2 = process.env.REACT_APP_API_URL + '/user/counseling';
const apiUrl3 = process.env.REACT_APP_API_URL + '/user/psytest';
export const UserConvoService = {
    getCvIds: async (
        stage: StageType,
        avatarId?: string,
    ): Promise<AxiosResponse<ApiResponseData<string[]>>> => {
        if (avatarId) {
            return await axios.get(
                `${apiUrl}?stage=${stage}&avatarId=${avatarId}`,
            );
        } else {
            return await axios.get(`${apiUrl}?stage=${stage}`);
        }
    },

    getQn: async (
        cvId: string,
        seq: number,
    ): Promise<AxiosResponse<ApiResponseData<ConvoQnData>>> => {
        return await axios.get(`${apiUrl}/${cvId}/qns?seq=${seq}`);
    },

    createConvoHistory: async (
        cvId: string,
        username: string,
    ): Promise<AxiosResponse<ApiResponseData<string>>> => {
        return await axios.post(`${apiUrl}/${cvId}/history?username=${username}`);
    },
    saveAnswer: async (cvhId: string, reqBody: SaveAnswerReqBody): Promise<AxiosResponse<ApiResponseData<any>>> => {
        return await axios.post(`${apiUrl}/${cvhId}/answer`, reqBody);
    }
};
export const UserCounselingService = {

    /** 상담 질문 조회 */
    async getQn(
        csId: string,
        seq: number,
    ): Promise<AxiosResponse<ApiResponseData<any>>> {
        return await axios.get(`${apiUrl2}/${csId}/qns?seq=${seq}`);
    },
    createCounselingHistory: async (
        csId: string,
        username: string,
    ): Promise<AxiosResponse<ApiResponseData<string>>> => {
        return await axios.post(`${apiUrl2}/${csId}/history?username=${username}`);
    },
    saveAnswer: async (cshId: string, reqBody: SaveAnswerReqBody): Promise<AxiosResponse<ApiResponseData<any>>> => {
        return await axios.post(`${apiUrl2}/${cshId}/answer`, reqBody);
    }
};


export const UserPsytestService = {
    /** 심리테스트 질문 조회 */
    async getQn(
        ptId: string,
        seq: number,
    ): Promise<AxiosResponse<ApiResponseData<any>>> {
        return await axios.get(`${apiUrl3}/${ptId}/qns?seq=${seq}`);
    },
    createPsytestHistory: async (
        ptId: string,
        username: string,
    ): Promise<AxiosResponse<ApiResponseData<string>>> => {
        return await axios.post(`${apiUrl3}/${ptId}/history?username=${username}`);
    },
    saveAnswer: async (pthId: string, reqBody: SaveAnswerReqBody): Promise<AxiosResponse<ApiResponseData<any>>> => {
        return await axios.post(`${apiUrl3}/${pthId}/answer`, reqBody);
    },
    /** 심리테스트 점수로 인한 결과 확인 */
    async getResultByScore(
        pthId: string,
        score: number,
    ): Promise<AxiosResponse<ApiResponseData<any>>> {
        return await axios.get(
            `${apiUrl3}/score/${pthId}?score=${score}`,
        );
    },

    /** 심리테스트 점수로 인한 결과 확인 */
    async getResultByType(
        pthId: string,
        typeId: string,
    ): Promise<AxiosResponse<ApiResponseData<any>>> {
        return await axios.get(`${apiUrl3}/type/${pthId}?type=${typeId}`);
    },
    async getResultByTypeForCheck(
        typeId: string,
    ): Promise<AxiosResponse<ApiResponseData<any>>> {
        return await axios.get(`${apiUrl3}/type?type=${typeId}`);
    }
};
