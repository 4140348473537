import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import 'locales/i18n';
import './styles/global.scss';
import './App.scss';
import { Provider } from 'react-redux';
import { store } from './store';
import { BrowserRouter } from 'react-router-dom';
import {ConfigProvider} from "antd";
import Colors from "./styles/colors";

const reduxStore = store;
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  // <React.StrictMode>
    <BrowserRouter>
      <Provider store={reduxStore}>
          <ConfigProvider
              theme={{
                  token: {
                      // Seed Token
                      colorPrimary: Colors.primary_250,
                      // borderRadius: 2,

                      // Alias Token
                      // colorBgContainer: '#f6ffed',
                  },
              }}
          >
              <App />
          </ConfigProvider>
      </Provider>
    </BrowserRouter>
  // </React.StrictMode>,
);
