import axios, {AxiosResponse} from 'axios';
import {ApiResponseData} from "../types/apiResponseDataTypes";
import {TagData, TagMapData} from "../types/tagTypes";
import {UpdateIsPublishedReqBody} from "../types/counselingTypes";

const apiUrl = process.env.REACT_APP_API_URL + '/admin/counseling';
export const CounselingService = {
    /*Scenario*/
    async getCsl(): Promise<AxiosResponse<any>> {
        return await axios.get(`${apiUrl}`);
    },
    async createCsl(reqBody: { reqBody: any }): Promise<AxiosResponse<any>> {
        return await axios.post(`${apiUrl}`, reqBody);
    },

    async modifyCsl(reqBody: { reqBody: any }, csId: string): Promise<AxiosResponse<any>> {
        return await axios.put(`${apiUrl}/${csId}`, reqBody);
    },

    async deleteCsl(csId: string): Promise<AxiosResponse<any>> {
        return await axios.delete(`${apiUrl}/${csId}`);
    },

    async updateIsPublished(csId: string, isPublished: boolean): Promise<AxiosResponse<any>> {
        return await axios.put(`${apiUrl}/publish/${csId}/${isPublished}`);
    },

    async updateIsPublishedSelected(reqBody:UpdateIsPublishedReqBody): Promise<AxiosResponse<any>> {
        return await axios.put(`${apiUrl}/publish`, reqBody);
    },

    /*Scenario qns*/
    async getCslQns(csId: string): Promise<AxiosResponse<any>> {
        return await axios.get(`${apiUrl}/${csId}/qns`);
    },

    async createCslQns(csId: string, reqBody: any): Promise<AxiosResponse<any>> {
        return await axios.post(`${apiUrl}/${csId}/qns`, reqBody);
    },

    /*Tags*/
    async getTags(): Promise<AxiosResponse<ApiResponseData<TagMapData>>> {
        return await axios.get(`${apiUrl}/tag`);
    },
    async createTag(reqBody: { reqBody: TagData }): Promise<AxiosResponse<any>> {
        return await axios.post(`${apiUrl}/tag`, reqBody);
    },
    async modifyTag(tagId: string, reqBody: { reqBody: TagData }): Promise<AxiosResponse<any>> {
        return await axios.put(`${apiUrl}/tag/${tagId}`, reqBody);
    },
    async deleteTag(tagId: string): Promise<AxiosResponse<any>> {
        return await axios.delete(`${apiUrl}/tag/${tagId}`);
    },
    /*Topics*/
    async getTopics(): Promise<AxiosResponse<ApiResponseData<TagMapData>>> {
        return await axios.get(`${apiUrl}/topic`);
    },
    /*Answers*/
    async getAnswers(csId: string): Promise<AxiosResponse<any>> {
        return await axios.get(`${apiUrl}/answers/${csId}`);
    },
    async deleteAnswers(csId: string): Promise<AxiosResponse<any>> {
        return await axios.delete(`${apiUrl}/answers/${csId}`);
    }
};
