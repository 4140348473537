// import {StorageUtils} from '@utils/StorageUtils';
// import {EncryptedStorageKey} from '~/typescript/enumtype/encryptedStorageKeyEnumType';

interface RandomIdObj {
  id: string;
  used: boolean;
}

// id Obj 저장할 키
const RANDOM_IDS = 'smalltalkIds'

// Storage 에서 id Obj 가져오는 함수
const getStoredRandomItems = async (): Promise<RandomIdObj[]> => {
  // const storedItems = await StorageUtils.getItem(RANDOM_IDS);
  const storedItems = localStorage.getItem(RANDOM_IDS);
  return storedItems ? JSON.parse(storedItems) : [];
};

// id List 를 id Obj 로 변환해 Storage 에 저장하는 함수
const storeRandomItems = async (ids: string[]) => {
  const items: RandomIdObj[] = ids.map((id: string) => ({id, used: false}));
  // await StorageUtils.setItem(RANDOM_IDS, items);
  await localStorage.setItem(RANDOM_IDS, JSON.stringify(items));
};

// 사용되지 않은 id 를 랜덤하게 가져오는 함수
const getRandomUnusedItemId = async () => {
  const items = await getStoredRandomItems();
  let unusedItems = items.filter((item: RandomIdObj) => !item.used);

  // 모든 아이템이 사용 중인 경우, 모두 사용되지 않은 상태로 변경 후 처리
  if (unusedItems.length === 0) {
    items.forEach((item: RandomIdObj) => (item.used = false));
    unusedItems = items;
  }

  const randomIndex = Math.floor(Math.random() * unusedItems.length);
  const randomItem = unusedItems[randomIndex];

  // 사용된 상태로 변경 후 저장
  const updatedItems = items.map((item: RandomIdObj) => {
    if (item.id === randomItem.id) {
      return {...item, used: true};
    }
    return item;
  });
  await localStorage.setItem(RANDOM_IDS, JSON.stringify(updatedItems));
  return randomItem.id;
};

export {
  storeRandomItems,
  getRandomUnusedItemId,
};
