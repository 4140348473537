import { configureStore } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';
import counterReducer from 'store/reducer/count';
import authReducer from 'store/reducer/auth';
import breadcrumb from 'store/reducer/breadcrumb';
import relqn from "./reducer/relqn";

// react-logger
const logger = createLogger();

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    auth: authReducer,
    breadcrumb: breadcrumb,
    relqn: relqn
  },
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
