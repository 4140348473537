import axios, {AxiosResponse} from 'axios';
import {ApiResponseData} from "../types/apiResponseDataTypes";
import {JoinInfo, LoginSuccessResData} from "../types/authTypes";
import {ChangeActiveReqBody} from "../types/counselorTypes";

const apiUrl = process.env.REACT_APP_API_URL+"/admin/counselor";

// role: ADMIN 인 경우에만 사용하는 api
export const CounselorMngmService = {
    // 상담사 계정 목록 조회
    async getCounselor(): Promise<AxiosResponse<ApiResponseData<LoginSuccessResData>>> {
        return await axios.get(`${apiUrl}`)
    },

    // 상담사 계정 등록
    async registerCounselor(joinInfo: JoinInfo): Promise<AxiosResponse<ApiResponseData<LoginSuccessResData>>> {
        return await axios.post(`${apiUrl}/join/manual`, joinInfo);
    },

    // 상담사 계정 비밀번호 초기화
    async resetCounselor(): Promise<AxiosResponse<ApiResponseData<LoginSuccessResData>>> {
        return await axios.post(`${apiUrl}/reset`);
    },

    // 상담사 계정 활성상태 변경
    async changeActive(reqBody: ChangeActiveReqBody): Promise<AxiosResponse<ApiResponseData<LoginSuccessResData>>> {
        return await axios.put(`${apiUrl}/active`, reqBody);
    }
};