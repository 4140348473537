import React, {useEffect, useState} from 'react';
import {Section} from "../../components/TriSectionLayout";
import {Button, Form, Input, message, Popover, Table, Tag} from "antd";
import {CounselorMngmService} from "../../services/CounselorMngmService";
import {ColumnsType} from "antd/es/table";
import moment from "moment/moment";
import {CounselorData} from "../../types/counselorTypes";
import UseConfirm from "../../utils/useHook/UseConfirm";

const CounselorMng = () => {
    const [form] = Form.useForm();
    const [data, setData] = useState<any>();

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const res = await CounselorMngmService.getCounselor();
        if (res.data.success) {
            setData(res.data.result);
        } else {
            throw new Error(res.data.message);
        }
    }
    const handleFinish = async (values: any) => {
        if (String(values.email).trim() === '') {
            values.email = null;
        }
        values.roles = 'COUNSELOR'
        const res = await CounselorMngmService.registerCounselor(values);
        if (res.data.success) {
            message.success(`상담사 "${values.username}"를 등록하였습니다.`);
            form.resetFields()
            getData();
        } else {
            message.success('상담사 등록에 실패하였습니다.');
        }
    }

    const handleClickConfirm = async (record: CounselorData) => {
        const reqBody = {
            username: record.username,
            active: !record.active
        }
        const res = await CounselorMngmService.changeActive(reqBody);
        if (res.data.success) {
            message.success(`상담사 "${record.username}"의 상태를 변경하였습니다.`);
            getData();
        } else {
            message.error(`상담사 "${record.username}"의 상태 변경에 실패하였습니다.`);
        }
    }

    const {openConfirmModal, confirmModal} = UseConfirm({handleClickConfirm, title: '상담사 계정 상태를 변경하시겠습니까?'});

    const columns: ColumnsType<CounselorData> = [
        {
            title: '계정 상태',
            dataIndex: 'active',
            key: 'active',
            align: 'center',
            width: 80,
            render: (active: boolean, record: CounselorData) => (
                <>
                    {active ? (
                        <Popover content="클릭해서 비활성화" trigger="hover">
                            <Tag color={'cyan'}  style={{ cursor: 'pointer'}} onClick={() => openConfirmModal(record)}>
                                활성화
                            </Tag>
                        </Popover>
                    ) : (
                        <Popover content="클릭해서 활성화" trigger="hover">
                            <Tag style={{ cursor: 'pointer'}} onClick={() => openConfirmModal(record)}>
                                비활성화
                            </Tag>
                        </Popover>
                    )}
                </>
            ),
        },
        {
            title: '아이디',
            dataIndex: 'username',
            key: 'username',
            align: 'center',
        },
        {
            title: '이메일',
            dataIndex: 'email',
            key: 'email',
            align: 'center'
        },
        {
            title: '등록일시',
            dataIndex: 'createdAt',
            key: 'createdAt',
            align: 'center',
            render: (createdAt: string) => (
                <>{moment(createdAt).format('YYYY-MM-DD HH:mm')}</>
            )
        },
        {
            title: '계정상태 변경일시',
            dataIndex: 'lastModifiedAt',
            key: 'lastModifiedAt',
            align: 'center',
            render: (lastModifiedAt: string, record: CounselorData) => (
                <>
                    { lastModifiedAt === record.createdAt ? (
                        <></> // createAt 과 lastModifiedAt 이 같으면 표시되지 않음
                    ) : (
                        <>{moment(lastModifiedAt).format('YYYY-MM-DD HH:mm')}</>
                    )}
                </>
            )
        },
    ];

    return (
        <>
        <div className="layout-container">
            <Section
                title="상담사 계정 등록"
                style={{minHeight: '50vh'}}
            >
                <>
                    <Form
                        form={form}
                        name="create-from"
                        autoComplete="off"
                        colon={false}
                        labelCol={{span: 6}}
                        wrapperCol={{span: 16}}
                        // requiredMark={false}
                        onFinish={handleFinish}
                    >

                        <div className="counselor-form-group">
                            <Form.Item
                                name="username"
                                label="아이디"
                                rules={[{required: true, message: '필수 항목입니다.'}]}
                            >
                                <Input
                                    className="i"
                                    placeholder="Id"
                                    autoComplete={'off'}
                                />
                            </Form.Item>

                            <Form.Item
                                name="password"
                                label="비밀번호"
                                rules={[{required: true, message: '필수 항목입니다.'},
                                    {
                                        pattern: /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,16}$/i,
                                        message: '비밀번호는 영문, 숫자, 특수문자를 포함하여 8~16자리로 입력해주세요.'
                                    }]}
                            >
                                <Input.Password
                                    className="i"
                                    placeholder="Password"
                                    autoComplete={'off'}
                                />
                            </Form.Item>

                            <Form.Item
                                name="email"
                                label="이메일"
                                rules={[{type: 'email', message: '이메일 형식을 입력해 주세요.'}]}
                            >
                                <Input
                                    className="i"
                                    placeholder="Email"
                                />
                            </Form.Item>
                        </div>

                        <div className="centered-outer">
                            <Button
                                type="primary"
                                ghost
                                htmlType="submit"
                            >
                                등록
                            </Button>
                        </div>
                    </Form>
                </>
            </Section>

            <Section title={'상담사 목록'}>
                <Table
                    size={'small'}
                    dataSource={data}
                    rowKey={'id'}
                    pagination={false}
                    columns={columns}
                />
                {/*>
                    <Table.Column title="아이디" dataIndex="username"/>
                    <Table.Column title="이메일" dataIndex="email"/>
                    <Table.Column title="가입일" dataIndex="createdBy"/>

                </Table>*/}
            </Section>
        </div>
            {confirmModal}
        </>
    );
};

export default CounselorMng;
