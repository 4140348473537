import Breadcrumb from '../breadcrumb/Breadcrumb';
import './Main.scss';

const Main = ({children}: any) => {
    return (
        <main>
            <div className="main__container">
                <Breadcrumb/>
                {children}
            </div>
        </main>
    );
};

export default Main;
