import axios, {AxiosResponse} from "axios";
import {ApiResponseArrayData, ApiResponseData} from "../types/apiResponseDataTypes";
import {PsyTestData, PsyTestQnReq, PsyTestResultType} from "../types/psyTestTypes";
import {UpdateIsPublishedReqBody} from "../types/counselingTypes";

const apiUrl = process.env.REACT_APP_API_URL + '/admin/psytest';
export const PsyTestService = {
    async get(): Promise<AxiosResponse<ApiResponseArrayData<any>>> {
        return await axios.get(`${apiUrl}`);
    },
    async create(reqBody: any): Promise<AxiosResponse<ApiResponseData<any>>> {
        return await axios.post(`${apiUrl}`, reqBody);
    },
    async update(reqBody: PsyTestData, ptId: string): Promise<AxiosResponse<ApiResponseData<any>>> {
        return await axios.put(`${apiUrl}/${ptId}`, reqBody);
    },
    async delete(psyTestId: string): Promise<AxiosResponse<ApiResponseData<any>>> {
        return await axios.delete(`${apiUrl}/${psyTestId}`);
    },

    async updateIsPublished(psyTestId: string, isPublished: boolean): Promise<AxiosResponse<ApiResponseData<any>>> {
        return await axios.put(`${apiUrl}/publish/${psyTestId}/${isPublished}`);
    },


    async updateIsPublishedSelected(reqBody:UpdateIsPublishedReqBody): Promise<AxiosResponse<any>> {
        return await axios.put(`${apiUrl}/publish`, reqBody);
    },

    /*rt*/
    async getRts(psyTestId: string): Promise<AxiosResponse<ApiResponseArrayData<any>>> {
        return await axios.get(`${apiUrl}/${psyTestId}/rts`);
    },

    async createRts(psyTestId: string, reqBody: PsyTestResultType[]): Promise<AxiosResponse<ApiResponseData<any>>> {
        return await axios.post(`${apiUrl}/${psyTestId}/rts`, reqBody);
    },

    /*qn*/
    async getQns(psyTestId: string): Promise<AxiosResponse<ApiResponseArrayData<any>>> {
        return await axios.get(`${apiUrl}/${psyTestId}/qns`);
    },

    async createQns(psyTestId: string, reqBody: PsyTestQnReq[]): Promise<AxiosResponse<ApiResponseData<any>>> {
        return await axios.post(`${apiUrl}/${psyTestId}/qns`, reqBody);
    },

    /*Answers*/
    async getAnswers(psyTestId: string): Promise<AxiosResponse<any>> {
        return await axios.get(`${apiUrl}/answers/${psyTestId}`);
    },
    async deleteAnswers(psyTestId: string): Promise<AxiosResponse<any>> {
        return await axios.delete(`${apiUrl}/answers/${psyTestId}`);
    },

    /*Results*/
    async getResults(psyTestId: string): Promise<AxiosResponse<any>> {
        return await axios.get(`${apiUrl}/results/${psyTestId}`);
    }
};
