import axios, { InternalAxiosRequestConfig } from 'axios';
import { AuthService } from '../services/AuthService';
import { store } from '../store';
import { loginSuccess } from '../store/reducer/auth';

const setupAxiosInterceptor = (): void => {
  axios.interceptors.request.use((config: InternalAxiosRequestConfig) => {
    const accessToken = store.getState().auth.tokens?.accessToken;
    if (accessToken) {
      if (config.url && config.headers) {
        if (config.url.indexOf('/api/admin/auth') === -1) {
          config.headers.authorization = 'Bearer ' + accessToken;
        }
      }
    } else {
      config.headers.accessToken = null;
      config.headers.refreshToken = null;
    }
    return config;
  });
  axios.interceptors.response.use(
    function (response) {
      // 2xx 범위 내에 있는 모든 상태 코드이면 이 기능이 트리거 됨
      return response;
    },
    async (error) => {
      // 2xx 범위를 벗어나는 모든 상태 코드이면 이 함수가 트리거됨
      const { config: originalRequest, response } = error;
      const { status, data } = response;
      const isJwtError = data?.message?.includes('JWT') || status === 401;
      console.log('error in axios interceptor', error);
      // && (response?.status === 401 || code === 'ERR_NETWORK')
      if (
        originalRequest.url?.indexOf('/api/admin/auth') === -1 &&
        isJwtError &&
        !originalRequest._retry
      ) {
        console.error('originalRequest JWT error : ', data?.message);
        // 재요청 루프 방지 플래그(토큰 갱신 요청에서도 401 에러가 발생하면 예외 처리 위해 추가)
        originalRequest._retry = true;
        const refreshToken = localStorage.getItem('refreshToken');
        if (!refreshToken) {
          return Promise.reject(error);
        }
        try {
          const res = await AuthService.refresh(refreshToken);
          // 새로운 토큰 저장
          if (res.data.success) {
            const { id, jwt, roles, username } = res.data.result;
            const { refreshToken, accessToken: newAccessToken } = jwt;
            await localStorage.setItem('refreshToken', refreshToken);
            const payload = {
              tokens: jwt,
              id,
              roles: [roles],
              username,
            };
            store.dispatch(loginSuccess(payload));

            // 401로 요청 실패했던 요청 새로운 accessToken 으로 재요청
            originalRequest.headers.authorization = `Bearer ${newAccessToken}`;
            return axios(originalRequest);
          } else {
            console.error('refreshToken error : ', res.data.message);
            AuthService.logout();
          }
        } catch (error: any) {
          console.error('refreshToken error : ', error);
          AuthService.logout();
        }
      }
      return Promise.reject(error);
    },
  );
};
export default setupAxiosInterceptor;
