enum TopicGroup {
    POS = "POS",
    NEG = "NEG",
    DAILY = "DAILY",
    PSYTEST = "PSYTEST",
}

/*interface TopicGroupWithIndex {
    [key: string]: TopicGroup;
}*/
const topicGroupList = [
    TopicGroup.POS,
    TopicGroup.NEG,
    TopicGroup.DAILY,
    TopicGroup.PSYTEST,
]

const topicGroupLabel = {
    [TopicGroup.POS]: "긍정강화상담",
    [TopicGroup.NEG]: "부정상담",
    [TopicGroup.DAILY]: "데일리멘탈트레이닝",
    [TopicGroup.PSYTEST]: "심리테스트",
}

const topicGroupOptions = topicGroupList.map((topicGroup) => {
    return {
        label: topicGroupLabel[topicGroup],
        value: topicGroup,
    }
})

export {TopicGroup, topicGroupList, topicGroupLabel, topicGroupOptions};
