import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface RelQnState {
  relqns: any[];
}

const initialState = { relqns: [] } as RelQnState;

const relQnSlice = createSlice({
  name: 'relqn',
  initialState,
  reducers: {
    saveRelQn(state, action) {
      state.relqns = [...state.relqns, action.payload];
    },
  },
});

export const { saveRelQn } = relQnSlice.actions;
export default relQnSlice.reducer;
