import React, {useEffect, useState} from 'react';
import {Button, message, Modal, Table, Tabs, Tag} from "antd";
import {ConvoService} from "../services/ConvoService";
import {AnswerData, AnswerResultData, ResultTypeData} from "../types/answerResultTypes";
import {ColumnsType} from "antd/es/table";
import {BiKey} from "react-icons/bi";
import Colors from "../styles/colors";
import {QnType, qnTypeLabel} from "../types/enumtype/qnEnumtype";
import {CounselingService} from "../services/CounselingService";
import {PsyTestService} from "../services/PsyTestService";
import TabPane from "antd/es/tabs/TabPane";
import {TestType} from "../types/enumtype/psytestEnumtype";

interface ModalProps {
    type: TestType;
    id: string;
    title: string;
    open: boolean;
    close: () => void;
}

const AnswerAndResultModal = ({type, id, title, open, close}: ModalProps) => {
    const [data, setData] = useState<AnswerResultData[]>([]);
    const [data2, setData2] = useState<ResultTypeData[]>([]);

    useEffect(() => {
        getPsyTestAnswers();
    }, []);

    const getPsyTestAnswers = async () => {
        try {
            const res = await PsyTestService.getAnswers(id);
            if (res.data.success) {
                setData(res.data.result);
            } else {
                throw new Error(res.data.message);
            }
        } catch (e: any) {
            message.error('데이터를 불러오는데 실패했습니다.');
            console.error(e.message);
        }
    }

    const handleClickResultTab = () => {
        getResults();
    }

    const getResults = async () => {
        try {
            const res = await PsyTestService.getResults(id);
            if (res.data.success) {
                setData2(res.data.result);
            } else {
                throw new Error(res.data.message);
            }
        } catch (e: any) {
            message.error('데이터를 불러오는데 실패했습니다.');
            console.error(e.message);
        }
    }

    const columns: ColumnsType<AnswerResultData> = [
        {
            title: '번호',
            dataIndex: 'seq',
            key: 'seq',
            align: 'center',
            width: 50,
        },
        {
            title: '질 문',
            dataIndex: 'content',
            key: 'content',
            align: 'center',
        },
        {
            title: '답 변',
            dataIndex: 'answers',
            key: 'answers',
            align: 'center',
            children: [
                {
                    title: '내용',
                    dataIndex: 'answers',
                    key: 'answer',
                    align: 'center',
                    render: (answers: AnswerData[]) => (
                        <>
                            {answers.map((answer, index) => (
                                <div
                                    // style={{
                                    //     textAlign: 'start',
                                    //     borderBottom: index === answers.length - 1 ? 'none' : '1px solid #f0f0f0'
                                    // }}
                                    className="table-cell-seperated left-align"
                                    key={index}>{answer.answer}</div>
                            ))}
                        </>
                    ),
                },
                {
                    title: type === TestType.TYPE_TEST ? '결과유형' : '점수',
                    dataIndex: 'answers',
                    key: 'score',
                    align: 'center',
                    render: (answers: AnswerData[]) => {
                        return answers.map((answer, index) => (
                            <div key={index} className="table-cell-seperated">
                                {answer.score !== null ? answer.score + ' 점' : answer.resultType}
                            </div>
                        ));
                    }
                },
                {
                    title: '유저 수',
                    dataIndex: 'answers',
                    key: 'count',
                    align: 'center',
                    render: (answers: AnswerData[]) => (
                        <>
                            {answers.map((answer, index) => (
                                <div key={index} className="table-cell-seperated">{answer.count}</div>
                            ))}
                        </>
                    ),
                },

            ]
        }
    ];

    const columns2: ColumnsType<ResultTypeData> = [
        {
            title: '번호',
            dataIndex: 'seq',
            key: 'seq',
            align: 'center',
            width: 50,
        },
        {
            title: '결과유형',
            dataIndex: 'title',
            key: 'title',
            align: 'center',
        },
        {
            title: '유저 수',
            dataIndex: 'userCount',
            key: 'userCount',
            align: 'center',
            width: 100,
        }
    ];

    const column3: ColumnsType<ResultTypeData> = [
        {
            title: '번호',
            dataIndex: 'seq',
            key: 'seq',
            align: 'center',
            width: 50,
        },
        {
            title: '결과유형',
            dataIndex: 'title',
            key: 'title',
            align: 'center',
        },
        {
            title: '점수범위',
            dataIndex: 'startScore',
            key: 'startScore',
            align: 'center',
            render: (startScore, record) => (
                <div>{startScore} ~ {record.endScore} 점</div>
            )
        },
        {
            title: '유저 수',
            dataIndex: 'userCount',
            key: 'userCount',
            align: 'center',
            render: (userCount) => (
                <div>{userCount}</div>
            )
        },
        // {
        //     title: '유저 점수',
        //     dataIndex: 'userScores',
        //     key: 'userScores',
        //     align: 'center',
        //     render: (userScores: number[]) => (
        //         <>
        //             {userScores.map((userScore, index) => (
        //                 <div
        //                     className="table-cell-seperated"
        //                     key={index}>{userScore} 점</div>
        //             ))}
        //         </>
        //     ),
        // }
    ];

    const modalTitle = '심리테스트 답변/결과'
    return (
        <Modal
            className="common-modal"
            title={
                <div className="common-modal-title">
                    <div style={{fontWeight: 400}}>{title}
                        <div style={{fontWeight: 600}}>{modalTitle}</div>
                    </div>
                </div>
            }
            open={open}
            footer={null}
            onCancel={close}
            width={1000}
        >
            <div>
                {/*<div className='common-modal-content'>*/}
                <Tabs defaultActiveKey="1"
                      type="card"
                      centered
                      onTabClick={(activeKey) => {
                          if (activeKey === '2') {
                              handleClickResultTab();
                          }
                      }}>
                    <TabPane key="1"
                             tab={<h3 className="tab-title">답변</h3>}
                             className="tab-content"
                    >
                        {/* 답변 테이블  */}
                        <Table
                            size="small"
                            bordered
                            columns={columns}
                            dataSource={data}
                            pagination={{position: ['bottomCenter'], showSizeChanger: true}}
                        />
                    </TabPane>
                    <TabPane key="2"
                             tab={<h3 className="tab-title">결과</h3>}
                             className="tab-content"

                    >
                        {/* 결과 테이블 */}
                        <Table
                            size="small"
                            bordered
                            // columns={columns2}
                            columns={type === TestType.TYPE_TEST ? columns2 : column3}
                            dataSource={data2}
                            pagination={{position: ['bottomCenter'], showSizeChanger: true}}
                        />
                    </TabPane>
                </Tabs>
            </div>
        </Modal>
    );
};

export default AnswerAndResultModal;
