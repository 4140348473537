import React, {useState} from 'react';
import {Button, Form, Input} from 'antd';
import styles from './Login.module.scss';
import {useNavigate} from 'react-router-dom';
import {AuthService} from '../../services/AuthService';
import {useDispatch} from 'react-redux';
import '../../styles/login.scss';
import {loginSuccess} from "../../store/reducer/auth";

const Login = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState('');

    const login = async (username: string, password: string) => {
        try {
            setLoading(true);
            // const res = await AuthService.login({username, password});'
            // Todo: TEST 후 삭제
            const res = await AuthService.login({username, password});
            setErrorMessage('');
            if (res.data.success) {
                const {id, jwt, roles, username} = res.data.result;
                const {refreshToken, accessToken} = jwt;
                try {
                    await localStorage.setItem('refreshToken', refreshToken);
                    const payload = {
                        tokens: jwt,
                        id,
                        roles: [roles],
                        username
                    }
                    dispatch(loginSuccess(payload));
                    navigate('/conversation/smalltalk');
                } catch (e: any) {
                    console.error(e.message);
                }
            } else {
                res.data.message && setErrorMessage(res.data.message);
            }
        } catch (e: any) {
            console.error(e.message);
            setErrorMessage('로그인에 실패했습니다.');
        } finally {
            setLoading(false);
        }
    }

    const handleFinish = async (values: any) => {
        await login(values.username, values.password);
    };

    return (
        <div className={styles.page}>
            <div className="login">
                <div className="login-outer">
                    <div className="title-outer">
                        <div className="title-logo"></div>
                    </div>
                    <div className={'w'}>
                        {/*<div className={styles.loginForm}>*/}
                        <Form form={form} onFinish={handleFinish}>
                            <Form.Item
                                name="username"
                                //   rules={[{ validator: validateUsername }]}
                            >
                                <Input
                                    className="i"
                                    //prefix={<MailOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
                                    placeholder="ID"
                                />
                            </Form.Item>

                            <Form.Item
                                name="password"
                                rules={[{required: true, message: '필수 항목입니다.'}]}
                            >
                                <Input.Password
                                    className="i"
                                    //prefix={<LockOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
                                    placeholder="Password"
                                />
                            </Form.Item>
                            <Button
                                className="b"
                                // type="primary"
                                htmlType="submit"
                                loading={loading}
                            >
                                LOGIN
                            </Button>
                        </Form>
                        <div style={{color: '#DB3B21', textAlign: 'center'}}>
                            {errorMessage}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
