import React, {useEffect, useRef, useState} from 'react';
import {StageType} from 'types/enumtype/stageEnumtype';
import {AnswerOptionData} from "../../types/convoTypes";
import {PsyTestAnswerData, QnTalk} from "../../types/userChatTypes";
import {QnType} from "../../types/enumtype/qnEnumtype";
import {UserConvoService, UserCounselingService, UserPsytestService} from "../../services/ChatroomService";
import {MessageList, MessageType} from "react-chat-elements";
import {IoSendSharp} from "react-icons/io5";
import Colors from "../../styles/colors";
import {Button, Checkbox, Divider, Input, message, Modal, Table} from "antd";
import {useParams} from "react-router-dom";
import {RiRefreshLine} from "react-icons/ri";
import {PtResult, ScorePsyTestAnswerOption, TypePsyTestAnswerOption} from "../../types/psyTestTypes";
const ChatRoomById = () => {
    const params = useParams();
    const {type, id} = params as { type: string, id: string };

    // Ids for each stage
    const [currentCvId, setCurrentCvId] = useState<string>();
    const [csId, setCsId] = useState<string>();
    const [ptId, setPtId] = useState<string>();
    // (strId, rvId 대신 cvhId 사용)
    const [currentStage, setCurrentStage] = useState<StageType>(
        StageType.MAIN
    );

    // History id
    const [cvhId, setCvhId] = useState<string>();
    const [cshId, setCshId] = useState<string>();
    const [pthId, setPthId] = useState<string>();

    // Chat Data
    const [messages, setMessages] = useState<MessageType[]>([]);
    const [currentQnId, setCurrentQnId] = useState<string>();
    const [currentQnType, setCurrentQnType] = useState<QnType>(QnType.MCQ);
    const [currentAo, setCurrentAo] = useState<AnswerOptionData[]>([]);
    const [nextSeq, setNextSeq] = useState<number>(1);
    const [selectedSeq, setSelectedSeq] = useState<number>(1); // ?
    const [intervalUntilNext, setIntervalUntilNext] = useState<number>(0);

    // Visibility
    const [isDrawingCanvasVsb, setIsDrawingCanvasVsb] = useState(false);
    const [inputVsb, setInputVsb] = useState(false);
    const [inputValue, setInputValue] = useState('');

    /* PsyTest code */
    const [ptScore, setPtScore] = useState<number>(0);
    const [ptTypes, setPtTypes] = useState<PsyTestAnswerData[]>([]);
    const [ptResultVsb, setPtResultVsb] = useState(false);

    // Web Preview 에서만 사용
    const [saveMode, setSaveMode] = useState(false);
    const [ignoreInterval, setIgnoreInterval] = useState<boolean>(false);
    const avatarProfile = 'avatar'; // 임시 Profile (APP 에서는 진입 시 Profile 을 받아와야 함)
    const userProfile = 'user';
    const [username, setUsername] = useState<string>();

    // Ref (하단으로 자동 스크롤 설정)
    const messageListContainerRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (messageListContainerRef.current) {
            messageListContainerRef.current.scrollTop = messageListContainerRef.current.scrollHeight;
        }
    }, [messages]);

    useEffect(() => {
        if (id) {
            switch (type) {
                case 'conversation':
                    setCurrentCvId(id);
                    setCurrentStage(StageType.REVIEW);
                    break;
                case 'counseling':
                    setCsId(id);
                    setCurrentStage(StageType.MAIN);
                    break;
                case 'psytest':
                    setPtId(id);
                    setCurrentStage(StageType.MAIN);
                    break;
                default:
                    break;
            }
        }
    }, []);

    // messageIds console for debugging
    useEffect(() => {
        ptResultVsb && onPsyTestFinish();
    },[ptResultVsb]);

    type AsyncFunction = (id: string, username: string) => Promise<any>;
    type SetIdFunction = (id: any) => void;

    const startService = async (serviceFunction: AsyncFunction, setIdFunction: SetIdFunction) => {
        if (!username || username?.trim() === '') {
            message.warning('유저 닉네임을 입력해주세요');
            return;
        }
        try {
            const res = await serviceFunction(id, username);
            if (res.data.success) {
                setIdFunction(res.data.result);
                return res.data.result;
            } else {
                if (res.data.message) {
                    console.error(res.data.message);
                    message.error(res.data.message);
                }
            }
        } catch (e) {
            console.error(e);
        }
    }

    const startConvo = async () => {
        return startService(UserConvoService.createConvoHistory, setCvhId);
    }

    const startCounseling = async () => {
        return startService(UserCounselingService.createCounselingHistory, setCshId);
    }

    const startPsytest = async () => {
        return startService(UserPsytestService.createPsytestHistory, setPthId);
    }

    const handleCreateHistory = async () => {
        let historyId;
        switch (currentStage) {
            case StageType.REVIEW:
                historyId = await startConvo();
                break;
            case StageType.MAIN:
                if (csId) {
                    historyId = await startCounseling();
                } else if (ptId) {
                    historyId = await startPsytest();
                }
                break;
            default:
                break;
        }

        if (historyId) {
            setSaveMode(true);
            setMessages([]);
            getQnAndRender(id!, 1);
        }
    };


    const handleConvoEnd = async () => {
        if (ptId) {
            setPtResultVsb(true);
            // await onPsyTestFinish();
        } else {
            message.success('대화 종료');
        }
        setSaveMode(false);
        setUsername('');
        cshId && setCshId(undefined);
        cvhId && setCvhId(undefined);
    };
    const getQnAndRender = async (
        parentId: string,
        seq: number,
        mainStageId?: string,
        mainStageType?: string,
    ) => {
        if (seq === 0) {
            console.log('isLastQn=true -> 대화 종료');
            await handleConvoEnd();
            return;
        }

        const result = await getQn(parentId, seq);

        if (!result) {
            console.log('result=null -> 대화 종료');
            await handleConvoEnd();
            return;
        } else {
            // console.log('result ->>>>>>>>>> ', result);
            if (result.isLastQn) {
                setNextSeq(0);
            } else {
                setNextSeq(seq + 1);
            }
        }

        let delay = 0;
        /* 질문 렌더는 2가지 타입이 있음
            1. 심리테스트: content: string
            2. 대화 및 상담: content: QnTalk[]*/
        if (typeof result.content === 'string') {
            renderMessage(
                result.id,
                result.content,
                delay,
                avatarProfile,
            );
        } else {
            result.content.forEach((item: QnTalk, index: number) => {
                const intervalUntilNext = item.interval * 1000;
                console.log('intervalUntilNext', intervalUntilNext);
                setIntervalUntilNext(intervalUntilNext);
                renderMessage(
                    result.id + '_' + index,
                    item.text,
                    delay,
                    avatarProfile,
                );
                // delay += intervalUntilNext; // 다음 메시지의 렌더링을 위해 delay 업데이트
                delay += !ignoreInterval ? intervalUntilNext : 0;
            });
        }

        // 답변 렌더
        if (result.answerOptions) {
            setCurrentAo(result.answerOptions);
            const lastQnChat = result.content[result.content.length - 1];
            const optionInterval = lastQnChat.interval * 1000;
            delay += optionInterval;
            result.answerOptions.forEach((option: any) => {
                renderMessage(option.id, option.content, delay, 'answerOption');
            });
        }


        // SAQ 일 경우, input 창 보이게 함
        setTimeout(() => {
            setCurrentQnId(result.id);
            switch (result.qnType) {
                case QnType.SAQ:
                    setInputVsb(true);
                    break;
                case QnType.TEXT:
                    getQnAndRender(parentId, seq + 1);
                    break;
                case QnType.DRAWING:
                    console.log('DRAWING called', result.id);
                    setIsDrawingCanvasVsb(true);

                    break;
                case QnType.VIDEO:
                    console.log('VIDEO called');
                    break;

                default:
                    break;
            }
        }, delay);
    };

    const getQn = async (parentId: string, seq: number) => {
        const res =
            StageType.MAIN !== currentStage
                ? await UserConvoService.getQn(parentId, seq)
                : (ptId && (await UserPsytestService.getQn(parentId, seq))) ||
                (csId && (await UserCounselingService.getQn(parentId, seq))) ||
                null;
        if (!res) {
            return;
        }
        try {
            if (res.data.success) {
                return res.data.result;
            } else {
                res.data.message && console.error('res.data.message', res.data.message);
                throw new Error(res.data.message);
            }
        } catch (e) {
            console.error(e);
            throw e;
        }
    };
    const renderMessage = (
        id: string,
        text: string,
        delay: number,
        senderProfile: string,
    ) => {
        // console.log('renderMessage -> id', id);
        const message: any = {
            title: senderProfile === 'avatar' && '아바타',
            notch: senderProfile === 'avatar' && true,
            createdAt: Date.now(),
            id,
            text: text,
            type: 'text',
            position: senderProfile === 'user' && 'right'
        };
        setTimeout(() => {
            setMessages(prevMessages => [...prevMessages, message]);
        }, delay); // desc: param 으로 받은 delay 만큼 다음 메시지의 렌더링 지연
    };

    /*이벤트 핸들러*/

    const saveAnswer = async (service: any, id: string | undefined, reqBody: {qnId: string, content: string, aoId?: string},) => {
        if (!id) {
            message.warning('대화를 저장하지 못하였습니다. 대화를 저장하지 않고 계속 실행합니다');
            return;
        }
        try {
            await service.saveAnswer(id, reqBody);
        } catch (e) {
            console.error(e);
            message.error('답변 저장에 실패했습니다.');
        }
    };

    const saveConvoAnswer = async (qnId: string, content: string, aoId?: string) => {
        const reqBody = { qnId, content, aoId };
        await saveAnswer(UserConvoService, cvhId, reqBody);
    };

    const saveCounselAnswer = async (qnId: string, content: string, aoId?: string) => {
        const reqBody = { qnId, content, aoId };
        await saveAnswer(UserCounselingService, cshId, reqBody);
    };

    const savePsytestAnswer = async (qnId: string, content: string, aoId?: string) => {
        const reqBody = { qnId, content, aoId };
        await saveAnswer(UserPsytestService, pthId, reqBody);
    };

    // PSYTEST SCORE, TYPE CALCULATION
    /** 심리테스트 결과 집계 */
    const countPsyTestTypeUp = async (ao: TypePsyTestAnswerOption) => {
        if (!ao || !ao.rtId || !ao.rtWeight) {
            return;
        }
        const typeId = ao.rtId; // 결과 uuid

        if (ptTypes.filter(d => d.id === typeId).length > 0) {
            ptTypes.forEach(d => {
                if (d.id === typeId && d.weight) {
                    d.weight += ao.rtWeight ?? 0;
                }
            });
        } else {
            ptTypes.push({id: typeId, weight: ao.rtWeight ?? 0});
        }
        console.log('countPsyTestTypeUp > ptTypes', ptTypes);
        setPtTypes(ptTypes);
    };

    /** 심리테스트 결과 확인 */
    const onPsyTestFinish = async () => {
        if (!ptId) {
            return;
        }

        // ptType 확인 후 없으면 점수로 산정
        if (ptTypes && ptTypes.length > 0) {
            await onPsyTestType();
        } else {
            await onPsyTestScore();
        }
    };

    const renderPsyTestResult = (ptResult: PtResult) => {
        if (!ptResult) {
            return;
        }
        Modal.info({
            title: '심리테스트 결과',
            content: (
                <div>
                    <p>{ptResult.title}</p>
                    <p>{ptResult.content}</p>
                </div>
            ),
        });
        setPtScore(0);
        setPtTypes([]);
        setPtResultVsb(false);
        setPthId(undefined);
    }

    /** 심리테스트 결과 확인(점수별) */
    const onPsyTestScore = async () => {
        message.info(ptScore+'점');

        if (!pthId) {
            return;
        }
        const res = await UserPsytestService.getResultByScore(pthId, ptScore);
        if (res.data.success) {
            renderPsyTestResult(res.data.result);
        }
    };

    /** 심리테스트 결과 확인(타입별) */
    const onPsyTestType = async () => {
        const sorted = ptTypes
            .filter(d => d.weight !== (null || undefined))
            .sort((a, b) => (b.weight ?? 0) - (a.weight ?? 0));
        if (sorted.length > 0) {
            const typeId = sorted[0].id; // 결과 uuid
            console.log('onPsyTestType most answer', sorted[0]);
            if (typeId) {
                if (!pthId) {
                    const res = await UserPsytestService.getResultByTypeForCheck(typeId);
                    if (res.data.success) {
                        renderPsyTestResult(res.data.result);
                    }
                } else {
                    const res = await UserPsytestService.getResultByType(pthId, typeId);
                    if (res.data.success) {
                        renderPsyTestResult(res.data.result);
                    }
                }
            }
        }

    };

    const handleClickAnswer = async (id: string) => { // 답변 클릭 시 (답변 저장 방식 1)
        const replyInfo: any = currentAo.find((ao: any) => ao.id === id);
        if (!replyInfo) {
            console.log('currentAo not found');
            return;
        }

        const {qnId, aoId, content} = {qnId: currentQnId!, aoId: id, content: replyInfo.content};

        /* 기존 답변옵션 제거하고 유저가 선택한 답변 유저 쪽 버블로 렌더 */
        const currentAoIds = currentAo.map((ao: any) => ao.id);
        const newDataSource = messages.filter((item: any) => !currentAoIds.includes(item.id));
        setMessages(newDataSource);
        renderMessage(aoId, content, 400, userProfile);

        // 다음 대화를 가져와서 렌더
        const parentId =
            currentStage === StageType.MAIN ?
                csId || ptId
                : currentCvId;

        setTimeout(() => {
            // PSYTEST
            if (currentStage === StageType.MAIN && ptId) {
                // setPtScore(ptScore + (replyInfo.score ?? 0));
                setPtScore(prevScore => prevScore + (replyInfo.score ?? 0));
                countPsyTestTypeUp(replyInfo);
            }
            getQnAndRender(
                parentId!,
                replyInfo?.nextQnSeq || nextSeq,
                id,
                replyInfo.tagType,
            );
        }, 2000);
        if (saveMode) {
            switch (currentStage) {
                case StageType.MAIN:
                    if (csId) {
                        saveCounselAnswer(qnId, content, aoId);
                    }
                    if (ptId) {
                        savePsytestAnswer(qnId, content, aoId);
                    }
                    break;
                case StageType.REVIEW:
                    saveConvoAnswer(qnId, content, aoId);
                    break;
                default:
                    break;
            }
        }
    };

    const handleClickSend = () => { // 주관식 답변 전송 시 (답변 저장 방식 2)
        setInputVsb(false);
        if (inputValue) {
            const message = {
                position: "right",
                type: "text",
                text: inputValue,
            };
            setMessages((prevData: any) => [...prevData, message]);
            setInputValue('');

            // 다음 대화를 가져와서 렌더
            const parentId = currentStage === StageType.MAIN ? csId : currentCvId;
            getQnAndRender(parentId!, nextSeq);

            // 답변 저장
            if (saveMode) {
                const {qnId, content} = {qnId: currentQnId!, content: inputValue};
                saveConvoAnswer(qnId, content);
            }
        }
    };

    const handlePressEnter = () => {
        handleClickSend();
    };

    const reload = () => {
        window.location.reload();
    }

    const handleRestart = () => {
        setMessages([]);
        getQnAndRender(id!, 1);
    }
    const handleMoveToSeq = () => {
        setMessages([]);
        getQnAndRender(id!, selectedSeq);
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLElement>, callback: () => void) => {
        if (e.key === 'Enter') {
            callback();
        }
    }

    return (
        <div>
            <div className={'chat-preview-header'}>
                <Button icon={<RiRefreshLine/>} onClick={reload}>새로고침</Button>
                <div>
                    <Button type="primary" onClick={handleRestart}>대화시작</Button>
                    {
                        type !== 'conversation' && (
                            <>
                                <Divider type="vertical" style={{height: '30px'}}/>
                                <Button
                                    type="primary" ghost
                                    onClick={handleCreateHistory}
                                    disabled={!username}>
                                    저장모드로 대화시작
                                </Button>
                                <Input placeholder="유저 닉네임"
                                       style={{width: '100px', height: '36px', marginLeft: '5px'}}
                                       value={username}
                                       onChange={(e) => setUsername(e.target.value)}
                                />
                            </>
                        )
                    }
                </div>
            </div>
            <div className={'message-info-container'}>
                <div>
                    <div>현재 질문 번호 : {nextSeq - 1}</div>
                    <div>다음 질문 번호 : {nextSeq}</div>
                    <div>다음 질문메시지 : {intervalUntilNext / 1000}초 후</div>
                </div>
                <div>
                    <div>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <Input
                                type="number"
                                showCount={false}
                                style={{width: '65px', height: '30px', marginRight: '5px'}}
                                value={selectedSeq}
                                onChange={(e) => setSelectedSeq(Number(e.target.value))}
                                onKeyDown={(e) => {handleKeyDown(e, handleMoveToSeq)}}
                                aria-label="시퀀스 입력"
                            />
                            <span>번으로</span>
                            <span
                                role="button"
                                tabIndex={0}
                                onClick={handleMoveToSeq}
                                onKeyDown={(e) => {handleKeyDown(e, handleMoveToSeq)}}
                                style={{
                                    textDecoration: 'underline',
                                    cursor: 'pointer',
                                    marginLeft: '5px'
                                }}
                                aria-label="이동 버튼"
                            >이동</span>
                        </div>
                    </div>
                    {
                        type !== 'psytest' && (
                            <div style={{float: 'right'}}>
                                <Checkbox
                                    style={{marginRight: 10}}
                                    checked={ignoreInterval}
                                    onChange={(e) => setIgnoreInterval(e.target.checked)}/>
                                <span>시간간격 무시</span>
                            </div>
                        )
                    }
                </div>
            </div>
            <div className={'message-list-container'} ref={messageListContainerRef}>
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/*@ts-ignore*/}
                <MessageList
                    className='message-list'
                    isShowChild={true}
                    dataSource={messages}
                    downButton={true}
                    downButtonBadge={messages.length}
                    onClick={(item: MessageType) => {
                        console.log('handleAnswerClick item', item);
                        if (item.id && !item.notch) {
                            handleClickAnswer(item.id as string);
                        }
                    }}
                />
            </div>
            {inputVsb && (
                <div className={'message-input-container'}>
                    <Input
                        className={'message-input'}
                        placeholder="메시지를 입력해주세요"
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        onPressEnter={handlePressEnter}
                        suffix={<IoSendSharp
                            size={24}
                            color={Colors.primary_200}
                            className={'send-icon'}
                            onClick={handleClickSend}
                        />}
                    />
                </div>
            )}
        </div>
    );
};

export default ChatRoomById;
