import { useEffect } from 'react';
import {
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  Select,
  TimePicker,
} from 'antd';
import { ModifyModalProps } from '../../types/componentprops/modifyModalProps';
import { PushAlarmData } from '../../types/pushAlarmTypes';
import dayjs, { Dayjs } from 'dayjs';
import { debounce } from 'lodash';

const ModifyPushAlarmModal = ({
  open,
  close,
  record,
  handleFinish,
}: ModifyModalProps<PushAlarmData>) => {
  const [form] = Form.useForm();

  useEffect(() => {
    const { title, content, ageRange, gender } = record;
    const selectedDate = form.getFieldValue('selectedDate') as Dayjs;
    const selectedTime = form.getFieldValue('selectedTime') as Dayjs;

    form.setFieldsValue({
      title,
      content,
      ageRange,
      gender,
      timestamp: `${selectedDate.format('YYYY-MM-DD')}T${selectedTime.format(
        'HH:mm',
      )}`,
    });
  }, [record]);

  const handleFinishDebounce = debounce((values: PushAlarmData) => {
    handleFinish(values, null);
  }, 500);

  return (
    <Modal
      className="common-modal"
      title={`푸시알림 수정`}
      open={open}
      footer={null}
      onCancel={close}
    >
      <div className="common-modal-content">
        <Form
          className="modify-form"
          name="modify-push-alarm-form"
          form={form}
          autoComplete="off"
          colon={false}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 30, offset: 1 }}
          requiredMark={false}
          onFinish={handleFinishDebounce}
        >
          <Form.Item
            name="type"
            label="타입"
            style={{ display: 'none' }}
          ></Form.Item>
          <Form.Item
            name="title"
            label="제목"
            rules={[{ required: true, message: '제목을 입력해 주세요' }]}
          >
            <Input placeholder={'제목 입력'} maxLength={20} />
          </Form.Item>

          <Form.Item name="content" label="내용">
            <Input.TextArea
              placeholder="내용 입력"
              maxLength={500}
              style={{
                minHeight: 200,
                overflow: 'auto',
              }}
            />
          </Form.Item>
          <Form.Item
            name="selectedDate"
            key="modifySelectedDate"
            label="발송일자"
            initialValue={dayjs(record.timestamp).tz('Asia/Seoul')}
            rules={[{ required: true, message: '발송 일자를 선택해 주세요!' }]}
          >
            <DatePicker />
          </Form.Item>
          <Form.Item
            name="selectedTime"
            key="modifySelectedTime"
            label="시간"
            initialValue={dayjs(record.timestamp).tz('Asia/Seoul')}
            rules={[{ required: true, message: '발송 시간을 선택해 주세요!' }]}
          >
            <TimePicker format="HH:mm" />
          </Form.Item>
          <Form.Item
            name="ageRange"
            label="연령"
            initialValue={null}
            rules={[{ required: false, message: '대상 연령대를 선택합니다.' }]}
          >
            {/* 드롭다운 선택 - 카카오 연령범위 https://developers.kakao.com/sdk/reference/android-rx/release/kakao-open-android-docs/com.kakao.sdk.user.model/-age-range/index.html */}
            <Select listItemHeight={10} listHeight={500}>
              <Select.Option value={null}>선택 안 함</Select.Option>
              <Select.Option value="AGE_0_9">0~9세</Select.Option>
              <Select.Option value="AGE_10_14">10~14세</Select.Option>
              <Select.Option value="AGE_15_19">15~19세</Select.Option>
              <Select.Option value="AGE_20_29">20대</Select.Option>
              <Select.Option value="AGE_30_39">30대</Select.Option>
              <Select.Option value="AGE_40_49">40대</Select.Option>
              <Select.Option value="AGE_50_59">50대</Select.Option>
              <Select.Option value="AGE_60_69">60대</Select.Option>
              <Select.Option value="AGE_70_79">70대</Select.Option>
              <Select.Option value="AGE_80_89">80대</Select.Option>
              <Select.Option value="AGE_90ABOVE">90세 이상</Select.Option>
              {/* <Select.Option value="UNKNOWN">알 수 없음</Select.Option> */}
            </Select>
          </Form.Item>
          <Form.Item
            name="gender"
            label="성별"
            initialValue={null}
            rules={[
              { required: false, message: '대상 성별 그룹을 선택합니다.' },
            ]}
          >
            {/* 드롭다운 선택 - 카카오 성별값 https://developers.kakao.com/sdk/reference/android-rx/release/kakao-open-android-docs/com.kakao.sdk.user.model/-gender/index.html */}
            <Select>
              <Select.Option value={null}>선택 안 함</Select.Option>
              <Select.Option value="MALE">남성</Select.Option>
              <Select.Option value="FEMALE">여성</Select.Option>
              {/* <Select.Option value="OTHER">기타</Select.Option> */}
            </Select>
          </Form.Item>

          <div className="centered-outer">
            <Button
              type="primary"
              ghost
              htmlType="submit"
              disabled={record.sent}
            >
              등록
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};
export default ModifyPushAlarmModal;
