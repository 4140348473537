enum StageType {
    SMALLTALK = "SMALLTALK",
    STRUCTURING = "STRUCTURING",
    MAIN = "MAIN",
    REVIEW = "REVIEW",
    APP_REVIEW = "APP_REVIEW",
    RANDOM_SPEECH_BUBBLE = "RANDOM_SPEECH_BUBBLE"
}
const stageList = Object.values(StageType);
const stageTypeLabel = {
    [StageType.SMALLTALK]: '스몰톡',
    [StageType.STRUCTURING]: '구조화',
    [StageType.MAIN]: '메인',
    [StageType.REVIEW]: '리뷰',
    [StageType.APP_REVIEW]: '앱 리뷰',
    [StageType.RANDOM_SPEECH_BUBBLE]: '랜덤 말풍선'
}

export {StageType, stageTypeLabel, stageList};
