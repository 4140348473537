import React, {useEffect, useState} from 'react';
import {Button, Form, Input, Modal} from "antd";
import {RcFile} from "antd/es/upload/interface";
import ImageUpload from "../../components/ImageUpload";
import {ModifyModalProps} from "../../types/componentprops/modifyModalProps";
import {NoticeFaqData} from "../../types/noticeTypes";
import {NotiType, notiTypeLabel as typeLabel} from "../../types/enumtype/notiEnumtype";

const ModifyNoticeFaqModal = ({open, close, record, type, handleFinish}: ModifyModalProps<NoticeFaqData>) => {
    const notiType = type as NotiType;
    const apiUrl = process.env.REACT_APP_API_URL;
    const [form] = Form.useForm();
    const [selectedFile, setSelectedFile] = useState<RcFile | null>();

    useEffect(() => {
        const {title, content} = record;
        form.setFieldsValue({
            title, content
        });
    }, [record]);

    const handleFileChange = (file: RcFile | null) => {
        setSelectedFile(file);
    };

    return (
        <Modal
            className="common-modal"
            title={`${typeLabel[notiType]} 수정`}
            open={open}
            footer={null}
            onCancel={close}
        >
            <div className='common-modal-content'>
                <div className="image-uploader-container">
                    <ImageUpload
                        onFileChange={handleFileChange}
                        defaultImageUrl={record.imageId ? `${apiUrl}/images/${record.imageId}` : undefined}
                    />
                </div>
                <Form
                    className="create-form"
                    name="create-form"
                    form={form}
                    autoComplete="off"
                    colon={false}
                    labelCol={{span: 4}}
                    wrapperCol={{span: 30, offset: 1}}
                    requiredMark={false}
                    onFinish={(values) => handleFinish(values, selectedFile ? selectedFile : null)}
                >
                    <Form.Item
                        name="type"
                        label="타입"
                        style={{display: 'none'}}
                    >
                    </Form.Item>
                    <Form.Item
                        name="title"
                        label="제목"
                        rules={[{required: true, message: '제목을 입력해 주세요'}]}
                    >
                        <Input placeholder={'제목 입력'}/>
                    </Form.Item>

                    <Form.Item
                        name="content"
                        label="내용"
                    >
                        <Input.TextArea placeholder="내용 입력"
                                        style={{minHeight: 200, overflow: 'auto'}}
                        />
                    </Form.Item>

                    <div className="centered-outer">
                        <Button
                            type="primary"
                            ghost
                            htmlType="submit"
                        >
                            등록
                        </Button>
                    </div>
                </Form>
            </div>
        </Modal>
    );
};
export default ModifyNoticeFaqModal;
