import React, {useEffect, useState} from 'react';
import {Button, Form, message} from "antd";
import {AiOutlineSave} from "react-icons/ai";
import {ConvoService} from "../../services/ConvoService";
import {useNavigate, useParams} from "react-router-dom";
import {RiArrowGoBackLine} from "react-icons/ri";
import RSBubbleQnFormList from "../../components/formlist/appmain/RSBubbleQnFormList";
import {StageType} from "../../types/enumtype/stageEnumtype";
import {PageMode} from "../../types/componentprops/pageTypes";
import {v4 as uuidv4} from 'uuid';

const RSBubbleQns = () => {
    const navigate = useNavigate();
    const params = useParams();
    const {mode, cvId} = params;
    const previousPath = '/appmain/random-speech-bubble'
    const [initialValues, setInitialValues] = useState<any>(null);
    const [form] = Form.useForm();
    const [randomInterval, setRandomInterval] = useState<number>(4);

    useEffect(() => {
        if (mode === 'modify' && cvId) {
            getData();
        }
    }, [cvId])
    const getData = async () => {
        if (!cvId) return;
        const res = await ConvoService.getQns(cvId);
        if (res.data.success) {
            setInitialValues(res.data.result);
            setRandomInterval(res.data.result[0]?.content[0]?.randomInterval);
        } else {
            if (res.data.message) {
                console.error(res.data.message);
                message.error('질문을 불러오는데 실패했습니다.');
                navigate(previousPath)
            }
        }
    }

    const handleSave = async () => {
        await form.submit();
    }
    const handleFinish = async (values: any) => {
        const qns = values['convoQns'];

        if (qns.length === 0 || qns[0].content.length === 0) {
            message.warning('하나 이상의 말풍선을 입력해주세요.');
            return;
        }

        // content 첫번째에 randomInterval 값 설정
        const firstContent = qns[0].content[0]
        firstContent.randomInterval = randomInterval;

        // id 없는 질문에 id 부여
        const newQns = qns.map((qn: any) => {
            if (!qn.id) {
                qn.id = uuidv4();
            }
            return qn;
        });
        await createQns(newQns);
    }

    const createQns = async (reqBody: any) => {
        if (!cvId) return;
        try {
            const res = await ConvoService.createQns(reqBody, cvId);
            if (res.data.success) {
                message.success('저장되었습니다.');
                form.resetFields();
                setTimeout(() => {
                    navigate(previousPath);
                }, 400);
            } else {
                throw new Error(res.data.message);
            }
        } catch (e: any) {
            if (e.response.status === 409) {
                const msg = e.response.data.message;
                message.warning(msg);
            }
            message.error('저장에 실패했습니다.');
        }
    };

    return (
        <div>
            <div className={'save-btn-group'}>
                <Button
                    icon={<AiOutlineSave/>}
                    className={'save-btn'}
                    type={'primary'}
                    onClick={handleSave}>
                    질문 저장
                </Button>
                <Button
                    icon={<RiArrowGoBackLine/>}
                    className={'cancel-btn'}
                    type="primary" ghost
                    onClick={() => navigate(previousPath)}>
                    질문 등록 취소
                </Button>
            </div>
            <div>
                <Form
                    name="convo"
                    form={form}
                    autoComplete="off"
                    colon={false}
                    onFinish={handleFinish}
                    requiredMark={false}
                >
                    <RSBubbleQnFormList
                        stage={StageType.RANDOM_SPEECH_BUBBLE as StageType}
                        mode={mode as PageMode}
                        form={form}
                        initialValues={initialValues}
                        randomInterval={randomInterval}
                        setRandomInterval={setRandomInterval}
                    />
                </Form>
            </div>
        </div>
    );
};

export default RSBubbleQns;
