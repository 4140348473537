import React, {useEffect, useState} from 'react';
import {Button, Dropdown, message, SelectProps, Space, Table} from "antd";
import {ConvoService} from "../../services/ConvoService";
import {ColumnsType} from "antd/es/table";
import {DecorationService} from "../../services/DecorationService";
import {StageType, stageTypeLabel} from "../../types/enumtype/stageEnumtype";
import {ConvoData} from 'types/convoTypes';
import UseConfirmDelete from "../../utils/useHook/UseConfirmDelete";
import moment from "moment/moment";
import {RiSettings3Fill} from "react-icons/ri";
import {EditOutlined, PlusOutlined} from "@ant-design/icons";
import {AiOutlineDelete} from "react-icons/ai";
import ConvoModal from "./ConvoModal";
import {useNavigate, useParams} from 'react-router-dom';

const Convo = () => {
    const navigate = useNavigate();
    const params = useParams();
    const {stage: stageParam} = params;
    const stage = (stageParam as string).toUpperCase() as StageType || StageType.SMALLTALK;
    const [data, setData] = useState<any>(null);
    const [avatarOptions, setAvatarOptions] = useState<SelectProps['options']>([]);
    const [selectedRecord, setSelectedRecord] = useState<ConvoData>();
    const [modalVsb, setModalVsb] = useState<boolean>(false);
    const stageAvatarNotRequired = [StageType.REVIEW, StageType.APP_REVIEW];

    useEffect(() => {
        getData();
        // if (stage !== StageType.REVIEW) getAvatar();
        if (!stageAvatarNotRequired.includes(stage)) getAvatar();
    }, [stage]);
    const getData = async () => {
        try {
            const res = await ConvoService.getByStage(stage);
            setData(res.data.result);
        } catch (e: any) {
            message.error('데이터를 불러오는데 실패했습니다.');
            console.error(e.message);
        }
    }

    const getAvatar = async () => {
        try {
            const res = await DecorationService.getAvatars();
            setAvatarOptions(res.data.result.map((item: any) => {
                    return {
                        label: item.name,
                        value: item.id
                    }
                }
            ));
        } catch (e: any) {
            message.error('데이터를 불러오는데 실패했습니다.');
            console.error(e.message);
        }
    }

    const handleClickEditQn = async (id: string, mode: string) => {
        navigate(`/conversation/${stage.toLowerCase()}/${mode}/${id}`);
    }

    const handleDelete = async (id: string) => {
        const res = await ConvoService.delete(id);
        if (res.data.success) {
            message.success('삭제되었습니다.');
            setTimeout(() => {
                getData();
            }, 400);
        } else {
            message.error('삭제에 실패했습니다.');
        }
    }

    const items = [
        {
            key: '1',
            label: (
                <>
                    <EditOutlined/>
                    <span className={'menu-item-text'}>수정하기</span>
                </>
            ),
            onClick: () => selectedRecord && setModalVsb(true),
        },
        {
            key: '2',
            label: (
                <>
                    <AiOutlineDelete/>
                    <span className={'menu-item-text'}>삭제하기</span>
                </>
            ),
            onClick: () => selectedRecord && handleClickDelete(selectedRecord?.id),
        },
    ]
    const {handleClickDelete, confirmModal} = UseConfirmDelete({handleDelete, content: "삭제 시 작성된 질문도 함께 삭제됩니다."});

    const columns: ColumnsType<ConvoData> = [
        {
            title: '',
            dataIndex: 'id',
            key: 'id',
            width: 50,
            align: 'center',
            render: (value: string, record: ConvoData, index: number) => (
                <Dropdown
                    trigger={['click']}
                    menu={{items}}
                    placement="bottomLeft" arrow
                >
                    <div className="setting-icon-container">
                        <RiSettings3Fill
                            size={24}
                            onClick={() => {
                                setSelectedRecord(record);
                            }}
                        />
                    </div>
                </Dropdown>
            ),
        },
        {
            title: '제목',
            dataIndex: 'title',
            key: 'title',
            align: 'center',
        },
        {
            title: '아바타',
            dataIndex: ['avatar', 'name'],
            key: 'avatar',
            align: 'center'
        },
        {
            title: '등록일시',
            dataIndex: 'createdAt',
            key: 'createdAt',
            align: 'center',
            render: (createdAt: string) => (
                <>{moment(createdAt).format('YYYY-MM-DD HH:mm')}</>
            )
        },
        {
            title: '',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: 100,
            render: (id, record, index) => (
                <Space size="middle">
                    {record.hasQns ?
                        <Button
                            type="primary" ghost
                            onClick={() => handleClickEditQn(id, 'modify')}
                        >질문 보기</Button>
                        :
                        <Button
                            type="primary"
                            onClick={() => handleClickEditQn(id, 'create')}
                        >질문 등록</Button>
                    }
                </Space>
            ),
        },
    ];

    const handleCloseModal = () => {
        setModalVsb(false);
        setSelectedRecord(undefined);
    }

    const handleFinish = async (values: any) => {
        const res = await ConvoService.create(values);
        await getData();
        if (res.data.success) {
            message.success('등록되었습니다.');
            getData();
            setModalVsb(false);
        } else {
            message.error('등록에 실패했습니다.');
            throw new Error(res.data.message);
        }
    }

    const handleFinishModify = async (values: any) => {
        if (!selectedRecord) return;
        const res = await ConvoService.update(values, selectedRecord?.id);
        if (res.data.success) {
            message.success('수정되었습니다.');
            setModalVsb(false);
            getData();
            setSelectedRecord(undefined);
        } else {
            message.error('수정에 실패했습니다.');
        }
    }

    return (
        <>
            <div className="content-header">
                <h2>{stageTypeLabel[stage]} 목록</h2>
                <Button
                    className="new-btn"
                    type="primary"
                    onClick={() => setModalVsb(true)}
                    icon={<PlusOutlined/>}
                >
                    새 {stageTypeLabel[stage]} 등록
                </Button>
            </div>
            <Table
                size={'small'}
                columns={
                    stage === StageType.REVIEW ?
                        columns.filter((item: any) => item.key !== 'avatar')
                        : columns
                }
                dataSource={data}
                rowKey={'id'}
                pagination={{position: ['bottomCenter']}}
            />
            {/*</Section>*/}
            {confirmModal}
            {modalVsb &&
                <ConvoModal open={modalVsb}
                            close={handleCloseModal}
                            avatarOptions={avatarOptions}
                            stage={stage}
                            handleFinish={selectedRecord ? handleFinishModify : handleFinish}
                            record={selectedRecord}
                />
            }
        </>
    );
};

export default Convo;
