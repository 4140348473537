import React, {useEffect, useState} from 'react';
import {Button, Form, Input, Modal, Select, SelectProps} from "antd";
import {CounselingType} from "../../types/enumtype/counselingEnumtype";
import {TagMapValue} from "../../types/tagTypes";
import {CounselingData} from "../../types/counselingTypes";

interface ModalProps {
    open: boolean;
    close: () => void;
    handleFinish: (values: any) => void;
    record: CounselingData | undefined;
    selectOptions: SelectProps['options'];
    fieldTagOptions: SelectProps['options'];
    emotionTagOptions: SelectProps['options'];
    negEmotionTagOptions: SelectProps['options'];
}

const CounselingModal = ({
                                   open, close, handleFinish, record,
                                   selectOptions, fieldTagOptions, emotionTagOptions, negEmotionTagOptions
                               }: ModalProps) => {
    const [form] = Form.useForm();
    const [selectedCounselType, setSelectedCounselType] = useState<string>();

    useEffect(() => {
        if (record) {
            const {title, tags, counselingType, description} = record;
            let emotionIds: string[] = [];
            let fieldId: string | undefined = undefined;
            if (tags.length > 0) {
                emotionIds = tags.filter((tag: TagMapValue) => tag.div === 'EMOTION').map((tag: any) => tag.id)
                fieldId = tags.filter((tag: TagMapValue) => tag.div === 'FIELD')[0]?.id
            }
            form.setFieldsValue({
                title, counselingType, emotionIds, fieldId, description
            });
            setSelectedCounselType(counselingType);
        }
    }, [record]);

    const handleCounselTypeChange = (value: CounselingType) => {
        form.resetFields(['emotionIds', 'fieldId']);
        setSelectedCounselType(value);
    };

    return (
        <Modal
            className="common-modal"
            title={record ? '상담 수정' : '새 상담 등록'}
            open={open}
            footer={null}
            onCancel={close}
        >
            <div className='common-modal-content'>
                <Form
                    className="create-form"
                    name="create-form"
                    form={form}
                    autoComplete="off"
                    colon={false}
                    labelCol={{span: 4}}
                    wrapperCol={{span: 30, offset: 1}}
                    requiredMark={false}
                    onFinish={handleFinish}
                >
                    <Form.Item
                        name="title"
                        label='제목'
                        rules={[{required: true, message: '상담 제목을 입력해 주세요'}]}
                    >
                        <Input placeholder={'제목 입력'}/>
                    </Form.Item>

                    {/* 감정태그, 분야태그 Select Box 는 상담타입 선택에 따라 노출/비노출
                                        감정태그 Select Box : 상담타입이 NEG, DAILY 타입인 경우에만,
                                        분야태그 Select Box : 상담타입이 NEG 타입인 경우에만
                                    */}
                    <Form.Item
                        name="counselingType"
                        label="상담타입"
                        rules={[{required: true, message: '상담 타입을 선택해 주세요'}]}
                    >
                        <Select
                            placeholder="상담 타입 선택"
                            options={selectOptions}
                            onChange={(value) => handleCounselTypeChange(value)}
                        />
                    </Form.Item>

                    {/*감정태그 Select Box : 상담타입이 NEG, DAILY 타입인 경우에만*/}
                    {
                        (selectedCounselType === 'NEG' || selectedCounselType === 'DAILY') &&
                        <Form.Item
                            name="emotionIds"
                            label="감정태그"
                            rules={[{required: true, message: '감정 태그를 선택해 주세요'}]}
                        >
                            <Select
                                className="emotion-select"
                                placeholder="감정태그 선택"
                                mode="multiple"
                                options={selectedCounselType === 'NEG' ? negEmotionTagOptions : emotionTagOptions}
                                listHeight={356}
                                autoClearSearchValue={true}
                                virtual={false}
                            />
                        </Form.Item>
                    }
                    {/*분야태그 Select Box : 상담타입이 NEG 타입인 경우에만*/}
                    {
                        (selectedCounselType === 'NEG') &&
                        <Form.Item
                            name="fieldId"
                            label="분야태그"
                            rules={[{required: true, message: '분야 태그를 선택해 주세요'}]}
                        >
                            <Select
                                placeholder="분야태그 선택"
                                options={fieldTagOptions}
                                virtual={false}
                                allowClear
                            />
                        </Form.Item>
                    }

                    <Form.Item
                        name="description"
                        label="설명"
                    >
                        <Input.TextArea placeholder="설명 입력"/>
                    </Form.Item>


                    <div className="centered-outer register-btn-outer">
                        <Button
                            type="primary"
                            ghost
                            htmlType="submit"
                        >
                            { record ? '수정 완료' : '등록'}
                        </Button>
                    </div>
                </Form>
            </div>
        </Modal>
    );
};

export default CounselingModal;
