import React, {useEffect, useRef, useState} from 'react';
import {stageList, StageType} from 'types/enumtype/stageEnumtype';
import {AnswerOptionData} from "../../types/convoTypes";
import {getRandomUnusedItemId, storeRandomItems} from "../../utils/chatroom/RandomIdUtils";
import {QnTalk} from "../../types/userChatTypes";
import {QnType} from "../../types/enumtype/qnEnumtype";
import {getAvatarProfileImageSource} from "../../utils/chatroom/ImageUtils";
import {UserConvoService, UserCounselingService, UserPsytestService} from "../../services/ChatroomService";
import {DecorationService} from "../../services/DecorationService";
import {MessageList, MessageType} from "react-chat-elements";
import {IoSendSharp} from "react-icons/io5";
import Colors from "../../styles/colors";
import {Input} from "antd";
import {v4 as uuid} from "uuid";

const ChatRoom = () => {
// const ChatRoom = ({mode, _cvId, _csId, _ptId}: any) => {
  const [strId, setStrId] = useState<string>();
  const [rvId, setRvId] = useState<string>();
  const [currentCvId, setCurrentCvId] = useState<string>();
  const [csId, setCsId] = useState<string>();
  const [ptId, setPtId] = useState<string>();
  const [currentStage, setCurrentStage] = useState<StageType>(
    StageType.SMALLTALK,
  );
  // const [lastDelay, setLastDelay] = useState<number>(0);
  const [isDrawingCanvasVsb, setIsDrawingCanvasVsb] = useState(false);
  const [currentQnType, setCurrentQnType] = useState<QnType>(QnType.MCQ);
  const [currentQnId, setCurrentQnId] = useState<string>();
  const [currentAo, setCurrentAo] = useState<AnswerOptionData[]>([]);
  const [nextSeq, setNextSeq] = useState<number>(1);
  const [messages, setMessages] = useState<MessageType[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [inputVsb, setInputVsb] = useState(false);
  const avatarProfile = 'avatar';
  const userProfile = 'user';
  const mode = 'chat';

  useEffect(() => {
    if (mode === 'chat') {
      if (currentStage === StageType.SMALLTALK) {
        initStage();
      } else {
        startNextStage();
      }
    }
  }, [currentStage]);
  // StageType.MAIN !== currentStage
  //     ? await UserConvoService.getQn(parentId, seq)
  //     : (ptId && (await UserPsytestService.getQn(parentId, seq))) ||
  //     (csId && (await UserCounselingService.getQn(parentId, seq))) ||
  //     null;
  const moveToNextStage = () => {
    const currentIndex = stageList.indexOf(currentStage);

    const nextIndex = currentIndex + 1;
    // 다음 stage 가 없으면 종료
    if (nextIndex >= stageList.length) {
      return;
    }
    const _nextStage = stageList[nextIndex];
    setCurrentStage(_nextStage);
    console.log('move from ', currentStage, ' to ', _nextStage);
  };

  const initStage = async () => {
    // avatarId 를 가져와서 getCvIds 호출에 사용
    const myAvatar = await getMyAvatar();
    const {id: avatarId, name} = myAvatar;
    const avatarImage = await getAvatarProfileImageSource(myAvatar.name);
    if (avatarId) {
      // 구조화, 리뷰 아이디 리스트를 가져와서 첫번째 id 를 state 로 저장 해 놓음
      const strIds = await getCvIds(StageType.STRUCTURING, avatarId);
      if (strIds) {
        setStrId(strIds[0]);
      }
      const rvIds = await getCvIds(StageType.REVIEW);
      if (rvIds) {
        setRvId(rvIds[0]);
      }

      // 스콜톡 아이디 리스트를 가져와서 randomId 를 currentCvId state 에 저장하고 getQnAndRender 호출
      const smtIds = await getCvIds(StageType.SMALLTALK, avatarId);
      if (smtIds) {
        await storeRandomItems(smtIds);
        const randomId = await getRandomUnusedItemId();
        setCurrentCvId(randomId);
        await getQnAndRender(randomId, 1);
      } else {
        // 없으면 다음 스테이지로 이동
        moveToNextStage();
      }
    }
  };

  const startNextStage = async () => {
    switch (currentStage) {
      case StageType.STRUCTURING:
        // state 에 저장된 strId 를 이용해서 getQnAndRender 호출
        if (strId) {
          setCurrentCvId(strId);
          await getQnAndRender(strId, 1);
        }
        break;
      case StageType.MAIN:
        if (csId) {
          await getQnAndRender(csId, 1);
        } else if (ptId) {
          await getQnAndRender(ptId, 1);
        }
        break;
      case StageType.REVIEW:
        if (rvId) {
          setCurrentCvId(rvId);
          await getQnAndRender(rvId, 1);
        }
        break;
    }
  };

  const getMyAvatar = async () => {
    // const res = await UserAssetService.getAvatar();
    const res = await DecorationService.getAvatars();
    if (res.data.success) {
      return res.data.result[0];
    } else {
      res.data.message && console.error('res.data.message', res.data.message);
      throw new Error(res.data.message);
    }
  };

  const getCvIds = async (stage: StageType, avatarId?: string) => {
    const res = await UserConvoService.getCvIds(stage, avatarId);
    if (res.data.success) {
      return res.data.result;
    } else {
      res.data.message && console.error('res.data.message', res.data.message);
      throw new Error(res.data.message);
    }
  };

  const handleConvoEnd = async (
    stage: StageType,
    mainStageId?: string,
    mainStageType?: string,
  ) => {
    if (stage === StageType.STRUCTURING) {
      mainStageType === 'PSYTEST' ? setPtId(mainStageId) : setCsId(mainStageId);
      setCurrentCvId(undefined);
    }
    setNextSeq(1);
    moveToNextStage();
  };
  const getQnAndRender = async (
      parentId: string,
      seq: number,
      mainStageId?: string,
      mainStageType?: string,
  ) => {
    if (seq === 0) {
      console.log('isLastQn=true -> 대화 종료');
      await handleConvoEnd(currentStage, mainStageId, mainStageType);
    }

    const result = await getQn(parentId, seq);

    if (!result) {
      console.log('result=null -> 대화 종료');
      await handleConvoEnd(currentStage, mainStageId, mainStageType);
      return;
    } else {
      console.log('result ->>>>>>>>>> ', result);
      if (result.isLastQn) {
        setNextSeq(0);
      } else {
        setNextSeq(seq + 1);
      }
    }

    let delay = 0;
    /* 질문 렌더는 2가지 타입이 있음
        1. 심리테스트: content: string
        2. 대화 및 상담: content: QnTalk[]*/
    if (typeof result.content === 'string') {
      renderMessage(
          result.id,
          result.content,
          delay,
          avatarProfile,
      );
    } else {
      result.content.forEach((item: QnTalk, index: number) => {
        const intervalUntilNext = item.interval * 1000;
        console.log('intervalUntilNext', intervalUntilNext);
        renderMessage(
            result.id + '_' + index,
            item.text,
            delay,
            avatarProfile,
        );
        delay += intervalUntilNext; // 다음 메시지의 렌더링을 위해 delay 업데이트
      });
    }

    // 답변 렌더
    if (result.answerOptions) {
      setCurrentAo(result.answerOptions);
      const lastQnChat = result.content[result.content.length - 1];
      const optionInterval = lastQnChat.interval * 1000;
      delay += optionInterval;
      result.answerOptions.forEach((option: any) => {
        renderMessage(option.id, option.content, delay, 'answerOption');
      });
    }


    // SAQ 일 경우, input 창 보이게 함
    setTimeout(() => {
      setCurrentQnId(result.id);
      switch (result.qnType) {
        case QnType.SAQ:
          setInputVsb(true);
          break;
        case QnType.TEXT:
          getQnAndRender(parentId, seq + 1);
          break;
        case QnType.DRAWING:
          console.log('DRAWING called', result.id);
          setIsDrawingCanvasVsb(true);

          break;
        case QnType.VIDEO:
          console.log('VIDEO called');
          break;

        default:
          break;
      }
    }, delay);
  };

  const getQn = async (parentId: string, seq: number) => {
    const res =
      StageType.MAIN !== currentStage
        ? await UserConvoService.getQn(parentId, seq)
        : (ptId && (await UserPsytestService.getQn(parentId, seq))) ||
          (csId && (await UserCounselingService.getQn(parentId, seq))) ||
          null;
    if (!res) {
      return;
    }
    try {
      if (res.data.success) {
        return res.data.result;
      } else {
        res.data.message && console.error('res.data.message', res.data.message);
        throw new Error(res.data.message);
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  };
  const renderMessage = (
      id: string,
      text: string,
      delay: number,
      senderProfile: string,
  ) => {
    console.log('renderMessage -> id', id);
    const message: any = {
      title: senderProfile === 'avatar' && '데일리멘탈트레이닝 반려봇',
      notch: senderProfile === 'avatar' && true,
      createdAt: Date.now(),
      id,
      text: text,
      type: 'text',
      position: senderProfile === 'user' && 'right'
    };
    setTimeout(() => {
      setMessages(prevMessages => [...prevMessages, message]);
    }, delay); // desc: param 으로 받은 delay 만큼 다음 메시지의 렌더링 지연
  };

  /*이벤트 핸들러*/
  const saveAnswer = async ({
                                     qnId,
                                     content,
                                     aoId,
                                   }: {
    qnId: string;
    content: string;
    aoId?: string;
  }) => {
    const reqBody = {
        qnId,
        content,
        aoId,
    }
    if (currentStage === StageType.MAIN) {
        if (csId) {
            await UserCounselingService.saveAnswer(csId, reqBody);
        } else if (ptId) {
            await UserPsytestService.saveAnswer(ptId, reqBody);
        }
    } else if (currentStage === StageType.REVIEW) {
        await UserConvoService.saveAnswer(rvId!, reqBody);
    }
  };

  const handleClickSend = () => {
    setInputVsb(false);
    if (inputValue) {
      const message = {
        position: "right",
        type: "text",
        text: inputValue,
      };
      setMessages((prevData: any) => [...prevData, message]);
      setInputValue('');

      // 다음 대화를 가져와서 렌더
      const parentId = currentStage === StageType.MAIN ? csId : currentCvId;
      saveAnswer({
        qnId: currentQnId!,
        aoId: undefined,
        content: inputValue,
      });
      getQnAndRender(parentId!, nextSeq);
    }
  };

  const handlePressEnter = () => {
    handleClickSend();
  };

  const handleAnswerClick = async (id: string) => {
    const replyInfo = currentAo.find((ao: any) => ao.id === id);
    if (!replyInfo) {
      console.log('currentAo not found');
      return;
    }
    await saveAnswer({
      qnId: currentQnId!,
      aoId: id,
      content: replyInfo.content,
    });
    /*Remove currentAo in the dataSource*/
    const currentAoIds = currentAo.map((ao: any) => ao.id);
    const newDataSource = messages.filter((item: any) => !currentAoIds.includes(item.id));
    setMessages(newDataSource);
    renderMessage(id, replyInfo.content, 400, userProfile);
    // 다음 대화를 가져와서 렌더
    const parentId = currentStage === StageType.MAIN ? csId : currentCvId;
    setTimeout(() => {
      getQnAndRender(
          parentId!,
          replyInfo?.nextQnSeq || nextSeq,
          id,
          replyInfo.tagType,
      );
    }, 2000);
  };

  const messageListContainerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (messageListContainerRef.current) {
      messageListContainerRef.current.scrollTop = messageListContainerRef.current.scrollHeight;
    }
  }, [messages]);
  return (
      <div className={'chat-preview-inner left'}>

        <div className={'message-list-container'} ref={messageListContainerRef}>
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/*@ts-ignore*/}
          <MessageList
              // referance={messageListRef}
              className='message-list'
              isShowChild={true}
              dataSource={messages}
              downButton={true}
              downButtonBadge={messages.length}
              onClick={(item: MessageType) => {
                console.log('handleAnswerClick item', item);
                if (item.id && !item.notch) {
                  handleAnswerClick(item.id as string);
                }
              }}
          />
        </div>
        {inputVsb && (
            <div className={'message-input-container'}>
              <Input
                  className={'message-input'}
                  placeholder="메시지를 입력해주세요"
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  onPressEnter={handlePressEnter}
                  suffix={<IoSendSharp
                      size={24}
                      color={Colors.primary_200}
                      className={'send-icon'}
                      onClick={handleClickSend}
                  />}
              />
            </div>
        )}
      </div>
  );
};

export default ChatRoom;
