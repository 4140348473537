import React, {useEffect} from 'react';
import {Button, Layout, Modal} from 'antd';
import {MenuFoldOutlined, MenuUnfoldOutlined} from '@ant-design/icons';
import styles from './HeaderBar.module.scss';
import classNames from 'classnames/bind';
import {useSelector} from "react-redux";
import {RootState} from "../../store";
import {AdminPostService} from "../../services/AdminPostService";
import Colors from "../../styles/colors";
import {AuthService} from "../../services/AuthService";

const cn = classNames.bind(styles);

const {Header} = Layout;

const HeaderBar = ({collapsed, setCollapsed}: any) => {
    const role = useSelector((state: RootState) => state.auth.roles?.[0]);
    const [headerText, setHeaderText] = React.useState<string|null>(null);
    const onClickCollapsed = () => {
        setCollapsed(!collapsed);
    };

    useEffect(() => {
        getServerNotice();
    }, []);

    const getServerNotice = async () => {
        const res = await AdminPostService.getServerNotice();
        if (res.data.success) {
            if(res.data.result.length > 0) setHeaderText(res.data.result[0].title);
        }
    }

    const handleClickLogout = () => {
        Modal.confirm({
            title: '로그아웃 하시겠습니까?',
            icon: null,
            okButtonProps: { type: 'primary', ghost: true },
            cancelButtonProps: { type: 'primary', style: { background: Colors.primary_250 } },
            okText: '취소',
            cancelText: '로그아웃',
            onOk() {
                Modal.destroyAll();
            },
            onCancel() {
                AuthService.logout();
            }
        });
    }

    const headerTitle = 'Soul me' + (role ? ' - ' + role : '');
    return (
        <>
        <Header id="header">
            <div className={cn('header')}>
            <span
                className={cn('trigger')}
                onClick={onClickCollapsed}
            >
              {collapsed ? <MenuUnfoldOutlined/> : <MenuFoldOutlined/>}
            </span>
                <span
                    className="title"
                    style={{
                        fontSize: '20px',
                        fontWeight: '500',
                        cursor: 'pointer',
                    }}
                >{headerTitle}
                </span>
                {headerText &&
                    <span className="header-text">
                    {headerText}
                </span>}
            </div>
            <div className="header-right">
                <Button
                    onClick={handleClickLogout}
                >Logout</Button>
            </div>
        </Header>
        </>
    );
};

export default HeaderBar;
