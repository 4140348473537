const Colors = {
    primary_50: '#d6e4f6',
    primary_100: '#4096ff',
    primary_200: '#367cb8',
    primary_250: '#375e87',
    primary_300: '#163244',
    secondary_200: '#13c2c2',
    secondary_300: '#1677ff',
    emphasis_200: '#ff8000',
    emphasis_300: '#ff6c37',
    shade_200: '#f7f7f7',
    shade_300: '#c6c6c6',
    shade_400: '#7c7c7c',
    shade_500: '#313131',
    shade_600: '#000000',

};

export default Colors;
