import React, {useEffect, useState} from 'react';
import {Button, Card, Form, FormInstance, Input, Popover} from 'antd';
import {BiMinusCircle, BiPlus} from 'react-icons/bi';
import TextArea from "antd/es/input/TextArea";
import {v4 as uuidv4} from 'uuid';
import {StageType} from "../../../types/enumtype/stageEnumtype";
import {IoChatbubbleOutline} from "react-icons/io5";
import {AiOutlineClear} from "react-icons/ai";
import {PageMode} from "../../../types/componentprops/pageTypes";

interface RSBubbleQnFormListProps {
    stage: StageType;
    mode: PageMode;
    form: FormInstance<any>;
    initialValues: any;
    randomInterval: number;
    setRandomInterval: (value: number) => void;
}

const RSBubbleQnFormList = ({
                                stage,
                                mode,
                                form,
                                initialValues,
                                randomInterval,
                                setRandomInterval
                            }: RSBubbleQnFormListProps) => {
        const [editingQnIndex, setEditingQnIndex] = useState<number>(0);
        // const [randomInterval, setRandomInterval] = useState<number>(4);
        //value={initialValues?.[index]?.content?.[0]?.randomInterval || 4}

        useEffect(() => {
            form.setFieldsValue({
                convoQns: initialValues || [{seq: 1, content: Array(10).fill({})}],
                // convoQns: initialValues || [{seq: 1, content: [{}]}],
            });
            // if (initialValues) {
            //     setRandomInterval(initialValues[0].content[0].randomInterval);
            // }
            // console.log('initialValues', initialValues);
            // console.log('form values', form.getFieldsValue());
        }, [initialValues, form, mode]);

        const handleAddBubble = (_value: string) => {
            const value = parseInt(_value);
            if (isNaN(value) || value <= 0) {
                return;
            }
            const currentConvoQns = form.getFieldValue('convoQns');
            const currentConvoQn = currentConvoQns[editingQnIndex];
            const currentConvoQnContent = currentConvoQn.content || [{}];
            const newConvoQnContent = Array(value).fill({}).map(() => ({}));
            // console.log('newConvoQnContent', newConvoQnContent);
            currentConvoQn.content = currentConvoQnContent.concat(newConvoQnContent);
            form.setFieldsValue({
                convoQns: currentConvoQns,
            });
        }

        const handleClearEmptyBubble = () => {
            const currentConvoQns = form.getFieldValue('convoQns');
            const currentConvoQn = currentConvoQns[editingQnIndex];
            const currentConvoQnContent = currentConvoQn.content || [{}];
            const newConvoQnContent = currentConvoQnContent.filter((content: any) => {
                return content?.text;
            });
            currentConvoQn.content = newConvoQnContent;
            form.setFieldsValue({
                convoQns: currentConvoQns,
            });
        }

        const handleRemoveBubble = (remove: (index: number) => void, index: number) => {
            remove(index);
        }

        const handleChangeRandomInterval = (e: any, index: number) => {
            setRandomInterval(e.target.value);
            // const currentConvoQns = form.getFieldValue('convoQns');
            // const currentConvoQn = currentConvoQns[index];
            // const currentConvoQnContent = currentConvoQn.content || [{}];
            // //currentConvoQnContent[0].randomInterval = e.target.value;
            // currentConvoQnContent.forEach((content: any) => {
            //     content.randomInterval = e.target.value;
            // });
            // currentConvoQn.content = currentConvoQnContent;
            // form.setFieldsValue({
            //     convoQns: currentConvoQns,
            // });
        }

        return (
            <>
                <div className={'layout-container three-to-two'}>
                    <div>
                        <Form.List name="convoQns">
                            {(fields, {add, remove}) => (
                                <>
                                    {fields.map((field, index) => {
                                        return (
                                            <Card
                                                className={'common-card rs-bubble-card'}
                                                key={field.key}
                                                bordered={false}
                                                onClick={() => {
                                                    setEditingQnIndex(index);
                                                }}
                                            >
                                                <div className={'common-card-content'}>
                                                    <div className={'qn-outer'}>
                                                        {/******* Form Item *******
                                                         stage, id, seq, content
                                                         **************************/}
                                                        <Form.Item
                                                            name={[index, 'stage']}
                                                            initialValue={stage}
                                                            style={{display: 'none'}}
                                                        ></Form.Item>
                                                        <Form.Item
                                                            name={[index, 'id']}
                                                            style={{display: 'none'}}
                                                            initialValue={uuidv4()}
                                                        ></Form.Item>
                                                        <Form.Item
                                                            name={[index, 'seq']}
                                                            initialValue={index + 1}
                                                            style={{display: 'none'}}
                                                        ></Form.Item>
                                                        {/****************말풍선 리스트*****************/}
                                                        <Form.List
                                                            name={[index, 'content']}
                                                        >
                                                            {(fields, {
                                                                add,
                                                                remove
                                                            }) => (
                                                                <>
                                                                    <div className={'rs-bubble-header'}>
                                                                        {/*랜덤 시간간격 입력창*/}
                                                                        <div style={{
                                                                            display: 'flex',
                                                                            alignItems: 'center'
                                                                        }}>
                                                                            <span>팝업 간격</span>
                                                                            <Input
                                                                                className={'qn-chat-input'}
                                                                                type={'number'}
                                                                                min={1}
                                                                                step={1}
                                                                                defaultValue={4}
                                                                                //value={initialValues?.[index]?.content?.[0]?.randomInterval || 4}
                                                                                value={randomInterval}
                                                                                onChange={(e) => {
                                                                                    handleChangeRandomInterval(e, index);
                                                                                }}
                                                                                addonAfter={
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: '12px'
                                                                                        }}
                                                                                    >초</span>
                                                                                }
                                                                            />
                                                                        </div>
                                                                        {/*<Form.Item*/}
                                                                        {/*    className={'qn-chat-input-form'}*/}
                                                                        {/*    name={[index, 'randomIntervalInput']}*/}
                                                                        {/*    initialValue={4}*/}
                                                                        {/*    label={'팝업 주기'}*/}
                                                                        {/*>*/}
                                                                        {/*    <Input*/}
                                                                        {/*        className={'qn-chat-input'}*/}
                                                                        {/*        type={'number'}*/}
                                                                        {/*        min={1}*/}
                                                                        {/*        step={1}*/}
                                                                        {/*        addonAfter={*/}
                                                                        {/*            <span*/}
                                                                        {/*                style={{*/}
                                                                        {/*                    fontSize: '12px'*/}
                                                                        {/*                }}*/}
                                                                        {/*            >초</span>*/}
                                                                        {/*        }*/}
                                                                        {/*    />*/}
                                                                        {/*</Form.Item>*/}
                                                                        {/*총 말풍선 수*/}
                                                                        <span>
                                                                            총 {fields.length}개의 말풍선
                                                                        </span>
                                                                    </div>
                                                                    <div className={'rs-bubble-header'}>
                                                                        <div/>
                                                                        <div className={'qn-chat-btns'}>
                                                                            {/*빈 말풍선 클리어*/}
                                                                            <Popover content={'빈 말풍선 제거'}>
                                                                                <Button
                                                                                    onClick={handleClearEmptyBubble}
                                                                                >
                                                                                    <AiOutlineClear
                                                                                        size={20}/>
                                                                                </Button>
                                                                            </Popover>
                                                                            {/*말풍선 추가 버튼*/}
                                                                            <Input.Search
                                                                                type={'number'}
                                                                                min={1}
                                                                                defaultValue={10}
                                                                                placeholder="말풍선 입력창 수"
                                                                                enterButton="추가"
                                                                                onSearch={
                                                                                    value => handleAddBubble(value)
                                                                                }
                                                                            />
                                                                            <Button
                                                                                type={'text'}
                                                                                className={'qn-chat-btn plus'}
                                                                                onClick={() => {
                                                                                    add();
                                                                                }}
                                                                            >
                                                                                <BiPlus
                                                                                    size={20}/>

                                                                                <IoChatbubbleOutline
                                                                                    size={24}/>
                                                                            </Button>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        className={'qn-chat-group'}>
                                                                        {fields.map((field, index) => (
                                                                            <div
                                                                                key={field.key}
                                                                                className={'qn-chat'}>
                                                                                <div
                                                                                    className={'qn-chat-text-input-group'}>
                                                                                    <div>
                                                                                        <Button
                                                                                            type={'text'}
                                                                                            className={'qn-chat-btn minus'}
                                                                                            onClick={() => {
                                                                                                remove(index);
                                                                                                // handleRemoveBubble(remove, index);
                                                                                            }}
                                                                                        ><BiMinusCircle
                                                                                            size={20}/>
                                                                                        </Button>
                                                                                    </div>

                                                                                    {/*<Form.Item*/}
                                                                                    {/*    key={field.key}*/}
                                                                                    {/*    name={[index, 'randomInterval']}*/}
                                                                                    {/*    style={{display: 'none'}}*/}
                                                                                    {/*></Form.Item>*/}
                                                                                    <Form.Item
                                                                                        key={field.key}
                                                                                        name={[index, 'text']}
                                                                                        rules={[
                                                                                            {
                                                                                                required: true,
                                                                                                message:
                                                                                                    '말풍선 내용을 입력해주세요.',
                                                                                            },
                                                                                        ]}
                                                                                    >
                                                                                        <TextArea
                                                                                            style={{
                                                                                                borderTopLeftRadius: 0,
                                                                                                borderBottomLeftRadius: 0
                                                                                            }}
                                                                                            autoSize={{
                                                                                                minRows: 3,
                                                                                            }}
                                                                                            // placeholder="말풍선 내용을 입력해주세요."
                                                                                            placeholder={`말풍선 ${index + 1}`}
                                                                                        />
                                                                                    </Form.Item>
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                    <div
                                                                        className={'rs-bubble-footer'}>
                                                                        <Button
                                                                            type={'text'}
                                                                            className={'qn-chat-btn plus'}
                                                                            onClick={() => {
                                                                                add();
                                                                            }}
                                                                        >
                                                                            <BiPlus
                                                                                size={20}/>

                                                                            <IoChatbubbleOutline
                                                                                size={24}/>
                                                                        </Button>
                                                                    </div>
                                                                </>
                                                            )}
                                                        </Form.List>
                                                    </div>
                                                </div>
                                            </Card>
                                        );
                                    })}
                                </>
                            )}
                        </Form.List>
                    </div>
                </div>
            </>
        )
            ;
    }
;

export default RSBubbleQnFormList;
