import React, {useEffect, useState} from 'react';
import ImageUpload from '../../components/ImageUpload';
import {Avatar, Button, Dropdown, Form, Input, message, Popover, Table} from 'antd';
import {RcFile} from "antd/es/upload/interface";
import {Section} from "../../components/TriSectionLayout";
import {AnyObject} from "antd/es/_util/type";
import {EditOutlined, UserOutlined} from "@ant-design/icons";
import {ColumnsType} from "antd/es/table";
import {AiOutlineDelete} from "react-icons/ai";
import UseConfirmDelete from "../../utils/useHook/UseConfirmDelete";
import {RiSettings3Fill} from "react-icons/ri";
import {IoMdRefresh} from "react-icons/io";
import {AdminPostService} from "../../services/AdminPostService";
import {NoticeFaqData} from "../../types/noticeTypes";
import {NotiType, notiTypeLabel} from "../../types/enumtype/notiEnumtype";
import ModifyNoticeFaqModal from "./ModifyNoticeFaqModal";

const NoticeFaq = ({notiType}: { notiType: NotiType }) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const [selectedFile, setSelectedFile] = useState<any>();
    const [form] = Form.useForm();
    const [data, setData] = useState<NoticeFaqData[]>();
    const [selectedRecord, setSelectedRecord] = useState<NoticeFaqData>();
    const [modifyModalVsb, setModifyModalVsb] = useState(false);
    const longTextMinValue = 400;


    useEffect(() => {
        if (notiType === NotiType.NOTICE) {
            getNotices();
        } else {
            getFaqs();
        }
        return () => {
            form.resetFields();
        }
    }, [notiType]);

    const getNotices = async () => {
        const res = await AdminPostService.getNotices();
        if (res.data.success) {
            setData(res.data.result);
        } else {
            throw new Error(res.data.message);
        }
    };

    const getFaqs = async () => {
        const res = await AdminPostService.getFaqs();
        if (res.data.success) {
            setData(res.data.result);
        } else {
            throw new Error(res.data.message);
        }
    }

    const handleFileChange = (file: RcFile | null) => {
        setSelectedFile(file);
    };

    const handleFinishCreate = async (values: AnyObject) => {
        // if (selectedFile === undefined) {
        //     message.error('이미지를 생성해 주세요');
        //     return;
        // }
        const formData = new FormData();
        Object.entries(values).forEach(([key, value]) => formData.append(key, value));

        if (selectedFile) {
            formData.append('image', selectedFile as File);
        }
        if (notiType === NotiType.NOTICE) {
            await createNotices(formData);
        } else {
            await createFaq(formData);
        }
    };

    const createNotices = async (formData: FormData) => {
        const res = await AdminPostService.createNotice(formData);
        if (res.data.success) {
            message.success('생성되었습니다.');
            await getNotices();
            form.resetFields();
        } else {
            throw new Error(res.data.message);
        }
    };

    const createFaq = async (formData: FormData) => {
        const res = await AdminPostService.createFaq(formData);
        if (res.data.success) {
            message.success('생성되었습니다.');
            await getFaqs();
            form.resetFields();
        } else {
            throw new Error(res.data.message);
        }
    }

    const handleModifyFinish = async (values: NoticeFaqData, selectedFile: RcFile | null) => {
        const formData = new FormData();
        Object.entries(values).forEach(([key, value]) => formData.append(key, value));
        selectedFile && formData.append('image', selectedFile as File);
        if (notiType === NotiType.NOTICE)
            await modifyNotices(formData);
        else
            await modifyFaq(formData);
    }

    const modifyNotices = async (formData: FormData) => {
        if (!selectedRecord) return;
        const res = await AdminPostService.modifyNotice(formData, selectedRecord!.id);
        if (res.data.success) {
            await getNotices();
            setModifyModalVsb(false);
            setSelectedRecord(undefined);
            message.success('수정되었습니다.');
        } else {
            throw new Error(res.data.message);
        }
    }

    const modifyFaq = async (formData: FormData) => {
        if (!selectedRecord) return;
        const res = await AdminPostService.modifyFaq(formData, selectedRecord!.id);
        if (res.data.success) {
            await getFaqs();
            setModifyModalVsb(false);
            setSelectedRecord(undefined);
            message.success('수정되었습니다.');
        } else {
            throw new Error(res.data.message);
        }
    }

    const handleDelete = async (id: string) => {
        if (notiType === NotiType.NOTICE) {
            await deleteNotice(id);
        } else {
            await deleteFaq(id);
        }
    };

    const deleteNotice = async (id: string) => {
        const res = await AdminPostService.deleteNotice(id);
        if (res.data.success) {
            await getNotices();
            message.success('삭제되었습니다.');
        } else {
            throw new Error(res.data.message);
        }
    }
    const deleteFaq = async (id: string) => {
        const res = await AdminPostService.deleteFaq(id);
        if (res.data.success) {
            await getFaqs();
            message.success('삭제되었습니다.');
        } else {
            throw new Error(res.data.message);
        }
    }

    const {handleClickDelete, confirmModal} = UseConfirmDelete({handleDelete});

    const items = [
        {
            key: '1',
            label: (
                <>
                    <EditOutlined/>
                    <span className={'menu-item-text'}>수정하기</span>
                </>
            ),
            onClick: () => selectedRecord && setModifyModalVsb(true),
        },
        {
            key: '2',
            label: (
                <>
                    <AiOutlineDelete/>
                    <span className={'menu-item-text'}>삭제하기</span>
                </>
            ),
            onClick: () => selectedRecord && handleClickDelete(selectedRecord?.id),
        },
    ]

    const columns: ColumnsType<NoticeFaqData> = [
        {
            title: '',
            dataIndex: 'id',
            key: 'id',
            width: 50,
            align: 'center',
            render: (value: string, record: NoticeFaqData, index: number) => (
                <Dropdown
                    trigger={['click']}
                    menu={{items}}
                    placement="bottomLeft" arrow
                >
                    <div className="setting-icon-container">
                        <RiSettings3Fill
                            size={24}
                            onClick={() => {
                                setSelectedRecord(record);
                            }}
                        />
                    </div>
                </Dropdown>
            ),
        },
        {
            title: '제목',
            dataIndex: 'title',
            key: 'title',
            width: 100,
            align: 'center',
        },
        {
            title: '이미지',
            dataIndex: 'imageId',
            key: 'imageId',
            width: 100,
            align: 'center',
            render: (value: string) => (
                // desc: 이미지가 없을 경우 기본 이미지로 대체
                <div className="image-data-container centered-outer">
                    {!value
                        ? <Avatar shape="square" size={64} icon={<UserOutlined/>}/>
                        : <img src={`${apiUrl}/images/${value}`} alt="avatar" width={50}/>
                    }
                </div>
            ),
        },
        {
            title: '내용',
            dataIndex: 'content',
            key: 'content',
            width: 300,
            // align: 'center',
            render: (value: string) => {
                return (
                    value.length > longTextMinValue
                        ? <Popover
                        content={
                            <div style={{maxWidth: '40vw', maxHeight: longTextMinValue, overflow: 'auto'}}>
                                {value}
                            </div>
                        }
                        trigger="hover"
                        placement="bottom"
                    >
                        <span>{value.slice(0, longTextMinValue)}...</span>
                    </Popover> : <span>{value}</span>)
            },
        },
    ];

    return (
        <>
            <div className="layout-container two-to-three">
                <Section
                    title={`새 ${notiTypeLabel[notiType]} 등록`}
                    extra={
                        <Button
                            icon={<IoMdRefresh size={20}/>}
                            onClick={() => form.resetFields()}>
                        </Button>
                    }
                >

                    <div>
                        <Form
                            className="create-form"
                            name="create-form"
                            form={form}
                            autoComplete="off"
                            colon={false}
                            labelCol={{span: 5}}
                            requiredMark={false}
                            onFinish={handleFinishCreate}
                        >
                            <Form.Item
                                name="type"
                                label="타입"
                                style={{display: 'none'}}
                            >
                            </Form.Item>
                            <Form.Item
                                name={'image-upload'}>
                                <ImageUpload
                                    onFileChange={handleFileChange}
                                />
                            </Form.Item>

                            <Form.Item
                                name="title"
                                label={'제목'}
                                rules={[{required: true, message: `제목을 입력해 주세요`}]}
                            >
                                <Input placeholder={`제목 입력`}/>
                            </Form.Item>

                            <Form.Item
                                name="content"
                                label="내용"
                                initialValue={''}
                            >
                                <Input.TextArea placeholder="내용 입력"
                                                style={{minHeight: 200, overflow: 'auto'}}
                                />
                            </Form.Item>

                            <div className="centered-outer">
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    style={{minWidth: 100}}
                                >생성</Button>
                            </div>
                        </Form>
                    </div>
                </Section>

                {/* Form Item : 제목, 이미지, 내용 */}
                <Section title={`${notiTypeLabel[notiType]} 목록`}>
                    <Table
                        size={'small'}
                        dataSource={data}
                        rowKey={'id'}
                        columns={columns}
                        pagination={{pageSize: 5}}
                    />
                </Section>
            </div>
            {confirmModal}
            {modifyModalVsb &&
                <ModifyNoticeFaqModal
                    type={notiType}
                    open={modifyModalVsb}
                    close={() => setModifyModalVsb(false)}
                    record={selectedRecord!}
                    handleFinish={handleModifyFinish}
                />
            }
        </>
    );
};

export default NoticeFaq;
