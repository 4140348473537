import React, {useEffect, useState} from 'react';
import {Button, Card, Form, FormInstance, Input, message, Popover, Select} from 'antd';
import {AiOutlineDelete} from 'react-icons/ai';
import AoFormList from './AoFormList';
import {HiPencilSquare} from 'react-icons/hi2';
import {QnType, qnTypeLabel, qnTypeListMap,} from '../../types/enumtype/qnEnumtype';
import Colors from '../../styles/colors';
import {StageType} from '../../types/enumtype/stageEnumtype';
import CheckableTag from 'antd/es/tag/CheckableTag';
import {BiKey, BiMinusCircle, BiPlus} from 'react-icons/bi';
import TagImporting from '../TagImporting';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {DndProvider} from 'react-dnd';
import AoBoxFormList from './convo-str/AoBoxFormList';
import {TagMapValue} from '../../types/tagTypes';
import {DropResult} from '../GridCard';
import {DragDropContext, Draggable, Droppable, DropResult as BeautifulDropResult,} from 'react-beautiful-dnd';
import {TopicGroup, topicGroupLabel} from "../../types/enumtype/topicGroupEnumtype";
import {EmotionType, emotionTypeLabel} from "../../types/enumtype/emotionEnumType";
import {IoChatbubbleOutline, IoCopyOutline} from "react-icons/io5";
import TextArea from "antd/es/input/TextArea";
import {LuChevronLast} from "react-icons/lu";
import {PageMode} from "../../types/componentprops/pageTypes";
import {v4 as uuidv4} from 'uuid';

interface QnFormListProps {
    stage: StageType;
    form: FormInstance<any>;
    mode: PageMode;
    initialValues: any;
}

const QnFormList = ({stage, mode, form, initialValues}: QnFormListProps) => {
    const [editingQnIndex, setEditingQnIndex] = useState<number>(0);
    const [isKeyQnList, setIsKeyQnList] = useState<boolean[]>([]);
    const keyQnPossibleQnTypeList = [QnType.MCQ, QnType.EMOTION, QnType.FIELD, QnType.TOPIC];

    useEffect(() => {
        form.setFieldsValue({
            convoQns: initialValues || [{seq: 1, content: [{}]}],
        });
        // console.log('initialValues', initialValues);
        // console.log('form values', form.getFieldsValue());
    }, [initialValues, form, mode]);

    const selectOptions: any[] = [];
    qnTypeListMap[stage].map((item) => {
        selectOptions.push({label: qnTypeLabel[item], value: item});
    });


    /*event handler*/
    const handleKeyQnChange = (index: number, checked: boolean) => {
        const newIsKeyQnList = [...isKeyQnList];
        newIsKeyQnList[index] = checked;
        setIsKeyQnList(newIsKeyQnList);
    };

    const allTypeLabel = {
        ...topicGroupLabel,
        ...emotionTypeLabel,
        '' : '분야',
        null: '분야'
    }
    const handleDropEnd = (
        item: { contentList: TagMapValue[] },
        dropResult: DropResult,
    ) => {
        const {contentList} = item;

        const {aoIndex} = dropResult;
        if (contentList.length === 0) message.warning('1개 이상의 컨텐츠 생성 후 답변옵션 설정이 가능합니다.')
        const type = contentList[0]?.type;
        const content = allTypeLabel[type as TopicGroup | EmotionType | ''];
        const tagId = contentList[0].id;
        form.setFieldsValue({
                ['convoQns']: {
                    [editingQnIndex]: {
                        answerOptions: {
                            [aoIndex]: {
                                seq: aoIndex + 1,
                                content: content + ' 목록 전체',
                                tagId
                            }
                        }
                    }
                }
            },
        );
    };

    const handleOnQuestionCardDragEnd = (result: BeautifulDropResult) => {
        if (!result.destination) {
            return;
        }

        const currentList: any[] = form.getFieldsValue().convoQns;
        const newList: any[] = JSON.parse(JSON.stringify(currentList));
        const draggingItemIndex = result.source.index;
        const dropItemIndex = result.destination.index;
        // 드래그한 요소를 배열에서 삭제
        const removeForm = newList.splice(draggingItemIndex, 1);
        // 드롭한 위치에 드래그한 요소를 추가
        newList.splice(dropItemIndex, 0, removeForm[0]);
        newList.forEach((value, index) => {
            value.seq = index + 1;
        });
        form.setFieldsValue({['convoQns']: newList});
    };

    const validateYouTubeUrl = (_: any, value: string) => {
        const youtubeUrlRegex =
            /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(\?\S*)?$|https:\/\/www\.youtube\.com\/watch\?v=(\w|-){11}/;

        if (value && !youtubeUrlRegex.test(value)) {
            return Promise.reject('유효하지 않은 YouTube 동영상 URL입니다.');
        }

        return Promise.resolve();
    };

    const handleRemoveQn = (index: number, remove: (index: number) => void) => {
        remove(index);
        const qns = form.getFieldValue(['convoQns']); //나머지 질문의 seq 재설정
        qns.forEach((item: any, index: number) => {
            item.seq = index + 1;
        });
        form.setFieldsValue({
            ['convoQns']: qns
        });
    }
    const handleCopyQn = (index: number, add: (insertIndex: number) => void) => {
        const originalQn = form.getFieldValue(['convoQns', index]);
        const newQn = {...originalQn};
        newQn.id = uuidv4();
        newQn.seq = form.getFieldValue(['convoQns']).length + 1;
        add(newQn);
    }

    const handleSelectQnType = (value: QnType, index: number) => {
        // 선택 옵션이 바뀌면 답변옵션 보이기 또는 숨기기
        form.setFieldsValue({
            ['convoQns']: {
                [index]: {
                    answerOptions: value === QnType.MCQ ? [{}] : null
                }
            }
        });
    }
    const isAoBoxVsb = (stage: StageType, qnType: QnType) => {
        const strTypeList = [QnType.EMOTION, QnType.FIELD, QnType.TOPIC];
        return stage === StageType.STRUCTURING && strTypeList.includes(qnType);
    }

    return (
        <>
            <DragDropContext
                onDragEnd={handleOnQuestionCardDragEnd}
            >
                <Droppable droppableId="convoQns">
                    {(provided) => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                            <div className={'layout-container three-to-two'}>
                                <DndProvider backend={HTML5Backend}>
                                    <div>
                                        <Form.List name="convoQns">
                                            {(fields, {add, remove}) => (
                                                <>
                                                    {fields.map((field, index) => {
                                                        /*키질문 버튼 보이기 설정*/
                                                        let formValueQnType = form.getFieldValue(['convoQns', index, 'qnType']);

                                                        /*질문타입 초기값 설정*/
                                                        let initialValueQnType: QnType;
                                                        if (!formValueQnType) {
                                                            switch (stage) {
                                                                case StageType.STRUCTURING:
                                                                    initialValueQnType = QnType.EMOTION;
                                                                    break;
                                                                case StageType.REVIEW:
                                                                    initialValueQnType = QnType.SAQ;
                                                                    break;
                                                                case StageType.RANDOM_SPEECH_BUBBLE:
                                                                    initialValueQnType = QnType.TEXT;
                                                                    break;
                                                                default:
                                                                    initialValueQnType = QnType.MCQ;
                                                            }
                                                        } else {
                                                            initialValueQnType = formValueQnType;
                                                        }
                                                        formValueQnType = formValueQnType || initialValueQnType;

                                                        const currentIsKeyQn = form.getFieldValue(['convoQns', index, 'isKeyQn']);
                                                        const currentIsLastQn = form.getFieldValue(['convoQns', index, 'isLastQn']);
                                                        const isKeyQnVsb = formValueQnType ? keyQnPossibleQnTypeList.includes(formValueQnType) : true
                                                        return (
                                                            <Draggable
                                                                draggableId={String(field.key)}
                                                                index={index}
                                                                key={field.key}
                                                            >
                                                                {(provided) => {
                                                                    return (
                                                                        <div
                                                                            key={field.key}
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                        >
                                                                            <Card
                                                                                className={`common-card ${
                                                                                    editingQnIndex === index
                                                                                        ? 'editing'
                                                                                        : ''
                                                                                }`}
                                                                                /*키질문 토글 버튼*/
                                                                                key={field.key}
                                                                                bordered={false}
                                                                                onClick={() => {
                                                                                    setEditingQnIndex(index);
                                                                                }}
                                                                                title={
                                                                                    <div className={'qn-card-title'}>
                                                                                        {isKeyQnVsb &&
                                                                                            <Form.Item
                                                                                                name={[index, 'isKeyQn']}
                                                                                                style={{marginBottom: 0}}
                                                                                                initialValue={false}
                                                                                            >
                                                                                                <CheckableTag
                                                                                                    checked={currentIsKeyQn}
                                                                                                    style={{
                                                                                                        backgroundColor:
                                                                                                            currentIsKeyQn
                                                                                                                ? Colors.emphasis_300
                                                                                                                : Colors.shade_300,
                                                                                                        color: '#fff',
                                                                                                        lineHeight: '20px',
                                                                                                    }}
                                                                                                    onChange={(checked) => {
                                                                                                        handleKeyQnChange(
                                                                                                            index,
                                                                                                            checked,
                                                                                                        );
                                                                                                    }}
                                                                                                >
                                                                                                    <Popover
                                                                                                        content={currentIsKeyQn ? '키질문' : '키질문으로 설정'}>
                                                                                                        <BiKey
                                                                                                            size={20}/>
                                                                                                    </Popover>
                                                                                                </CheckableTag>
                                                                                            </Form.Item>
                                                                                        }
                                                                                        <Form.Item
                                                                                            name={[index, 'isLastQn']}
                                                                                            style={{marginBottom: 0}}
                                                                                            initialValue={false}
                                                                                        >
                                                                                            <CheckableTag
                                                                                                checked={currentIsLastQn}
                                                                                                style={{
                                                                                                    backgroundColor:
                                                                                                        currentIsLastQn
                                                                                                            ? Colors.secondary_200
                                                                                                            : Colors.shade_300,
                                                                                                    color: '#fff',
                                                                                                }}
                                                                                                onChange={(checked) => {
                                                                                                    handleKeyQnChange(
                                                                                                        index,
                                                                                                        checked,
                                                                                                    );
                                                                                                }}
                                                                                            >
                                                                                                <Popover
                                                                                                    content={currentIsKeyQn ? '마지막질문' : '마지막질문으로 설정'}>
                                                                                                    <LuChevronLast
                                                                                                        size={20}/>
                                                                                                </Popover>
                                                                                            </CheckableTag>
                                                                                        </Form.Item>
                                                                                    </div>
                                                                                }
                                                                                extra={
                                                                                    <>
                                                                                        {/*직상증 표시*/}
                                                                                        {editingQnIndex === index && (
                                                                                            <Button ghost>
                                                                                                <HiPencilSquare
                                                                                                    size={20}
                                                                                                    color={Colors.secondary_300}
                                                                                                />
                                                                                            </Button>
                                                                                        )}
                                                                                        {/*복사하기 버튼*/}
                                                                                        <Button ghost
                                                                                                onClick={() => handleCopyQn(index, add)}
                                                                                        >
                                                                                            <IoCopyOutline
                                                                                                size={20}
                                                                                                color={"#555555"}/>
                                                                                        </Button>
                                                                                        {/*삭제하기 버튼*/}
                                                                                        <Button
                                                                                            ghost
                                                                                            onClick={() => handleRemoveQn(index, remove)}
                                                                                        >
                                                                                            <AiOutlineDelete
                                                                                                size={20}
                                                                                                color={'#555555'}
                                                                                            />
                                                                                        </Button>
                                                                                    </>
                                                                                }
                                                                            >
                                                                                <div className={'common-card-content'}>
                                                                                    <div className={'qn-outer'}>
                                                                                        <div className={'qn-header'}>
                                                                                            <div
                                                                                                className={'qn-number'}>
                                                                                                Q. {index + 1}
                                                                                            </div>
                                                                                            <div>
                                                                                                <Form.Item
                                                                                                    name={[index, 'qnType']}
                                                                                                    initialValue={initialValueQnType}
                                                                                                    rules={[
                                                                                                        {
                                                                                                            required: true,
                                                                                                            message:
                                                                                                                '질문타입을 선택해주세요.',
                                                                                                        },
                                                                                                    ]}
                                                                                                >
                                                                                                    <Select
                                                                                                        style={{width: 120}}
                                                                                                        placeholder={'질문타입'}
                                                                                                        options={selectOptions}
                                                                                                        onChange={(value: QnType) => {
                                                                                                            handleSelectQnType(value, index)
                                                                                                        }}
                                                                                                    ></Select>
                                                                                                </Form.Item>
                                                                                            </div>
                                                                                        </div>
                                                                                        {/******* Form Item *******
                                                                                         stage, id, seq, content
                                                                                         **************************/}
                                                                                        <Form.Item
                                                                                            name={[index, 'stage']}
                                                                                            initialValue={stage}
                                                                                            style={{display: 'none'}}
                                                                                        ></Form.Item>
                                                                                        <Form.Item
                                                                                            name={[index, 'id']}
                                                                                            style={{display: 'none'}}
                                                                                            initialValue={uuidv4()}
                                                                                        ></Form.Item>
                                                                                        <Form.Item
                                                                                            name={[index, 'seq']}
                                                                                            initialValue={index + 1}
                                                                                            style={{display: 'none'}}
                                                                                        ></Form.Item>

                                                                                        {/****************말풍선 리스트*****************/}
                                                                                        <Form.List
                                                                                            name={[index, 'content']}
                                                                                            initialValue={form.getFieldValue([
                                                                                                'convoQns',
                                                                                                index,
                                                                                                'content',
                                                                                            ]) || [{}]}
                                                                                        >
                                                                                            {(fields, {
                                                                                                add,
                                                                                                remove
                                                                                            }) => (
                                                                                                <>
                                                                                                    {/* 질문 input 추가 버튼 */}
                                                                                                    {
                                                                                                        formValueQnType !== QnType.VIDEO && (
                                                                                                            <div
                                                                                                                className={'qn-chat-btn-outer'}>
                                                                                                                <Button
                                                                                                                    type={'text'}
                                                                                                                    className={'qn-chat-btn plus'}
                                                                                                                    onClick={() => {
                                                                                                                        add();
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <BiPlus
                                                                                                                        size={20}/>
                                                                                                                    <IoChatbubbleOutline
                                                                                                                        size={24}/>
                                                                                                                </Button>
                                                                                                            </div>
                                                                                                        )
                                                                                                    }

                                                                                                    <div
                                                                                                        className={'qn-chat-group'}>
                                                                                                        {fields.map((field, index) => (
                                                                                                            <div
                                                                                                                key={field.key}
                                                                                                                className={'qn-chat'}>
                                                                                                                {
                                                                                                                    formValueQnType === QnType.VIDEO ? (
                                                                                                                        <Form.Item
                                                                                                                            name={[index, 'content']}
                                                                                                                            rules={[
                                                                                                                                {
                                                                                                                                    required: true,
                                                                                                                                    message: '동영상 URL 입력해주세요.',
                                                                                                                                },
                                                                                                                                {
                                                                                                                                    validator: validateYouTubeUrl,
                                                                                                                                },
                                                                                                                            ]}
                                                                                                                        >
                                                                                                                            <Input
                                                                                                                                placeholder="동영상 URL 입력해주세요."
                                                                                                                            />
                                                                                                                        </Form.Item>
                                                                                                                    ) : (
                                                                                                                        <div
                                                                                                                            className={'qn-chat-text-input-group'}>
                                                                                                                            <div>
                                                                                                                                <Button
                                                                                                                                    type={'text'}
                                                                                                                                    className={'qn-chat-btn minus'}
                                                                                                                                    onClick={() => {
                                                                                                                                        remove(index);
                                                                                                                                        // todo: remove 할 때 말풍선이 1개일 때 삭제되지 않도록 수정
                                                                                                                                    }}
                                                                                                                                ><BiMinusCircle
                                                                                                                                    size={20}/>
                                                                                                                                </Button>
                                                                                                                            </div>
                                                                                                                            <Form.Item
                                                                                                                                key={field.key}
                                                                                                                                name={[index, 'text']}
                                                                                                                                rules={[
                                                                                                                                    {
                                                                                                                                        required: true,
                                                                                                                                        message:
                                                                                                                                            '질문을 입력해주세요.',
                                                                                                                                    },
                                                                                                                                ]}
                                                                                                                            >
                                                                                                                                <TextArea
                                                                                                                                    // placeholder="질문을 입력해주세요."
                                                                                                                                    style={{
                                                                                                                                        borderTopLeftRadius: 0,
                                                                                                                                        borderBottomLeftRadius: 0
                                                                                                                                    }}
                                                                                                                                    autoSize={{
                                                                                                                                        minRows: 3,
                                                                                                                                    }}
                                                                                                                                />
                                                                                                                            </Form.Item>
                                                                                                                        </div>
                                                                                                                    )
                                                                                                                }
                                                                                                                <Form.Item
                                                                                                                    name={[index, 'interval']}
                                                                                                                    initialValue={2}
                                                                                                                >
                                                                                                                    <Input
                                                                                                                        className={'qn-chat-interval-input'}
                                                                                                                        type={'number'}
                                                                                                                        min={0}
                                                                                                                        step={1}
                                                                                                                        addonAfter={
                                                                                                                            <span
                                                                                                                                style={{
                                                                                                                                    fontSize: '12px'
                                                                                                                                }}
                                                                                                                            >초</span>}
                                                                                                                    />
                                                                                                                </Form.Item>
                                                                                                            </div>
                                                                                                        ))}
                                                                                                    </div>
                                                                                                </>
                                                                                            )}
                                                                                        </Form.List>
                                                                                        {/*)}*/}
                                                                                    </div>
                                                                                    {
                                                                                        isAoBoxVsb(stage, formValueQnType) &&
                                                                                        <div
                                                                                            className={'ao-outer'}>
                                                                                            <AoBoxFormList
                                                                                                form={form}
                                                                                                stage={stage}
                                                                                                qnIndex={index}
                                                                                            />
                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        formValueQnType === QnType.MCQ &&
                                                                                        <div
                                                                                            className={'ao-outer'}>
                                                                                            <AoFormList
                                                                                                form={form}
                                                                                                qnIndex={index}
                                                                                            />
                                                                                        </div>
                                                                                    }
                                                                                    {/*{*/}
                                                                                    {/*    (formValueQnType === QnType.TOPIC ||*/}
                                                                                    {/*        formValueQnType === QnType.EMOTION ||*/}
                                                                                    {/*        formValueQnType === QnType.FIELD*/}
                                                                                    {/*    ) ?*/}

                                                                                    {/*        <div*/}
                                                                                    {/*            className={'ao-outer'}>구조화*/}
                                                                                    {/*            페이지에서 QnType 이*/}
                                                                                    {/*            'EMOTION', 'FIELD',*/}
                                                                                    {/*            'TOPIC' 인 경우*/}
                                                                                    {/*            <AoBoxFormList*/}
                                                                                    {/*                form={form}*/}
                                                                                    {/*                stage={stage}*/}
                                                                                    {/*                qnIndex={index}*/}
                                                                                    {/*                mode={mode}*/}
                                                                                    {/*            />*/}
                                                                                    {/*        </div>*/}
                                                                                    {/*        : stage === StageType.RANDOM_SPEECH_BUBBLE ?*/}
                                                                                    {/*            null*/}
                                                                                    {/*            :*/}
                                                                                    {/*            formValueQnType === QnType.MCQ &&*/}
                                                                                    {/*            <div*/}
                                                                                    {/*                className={'ao-outer'}>*/}
                                                                                    {/*                <AoFormList*/}
                                                                                    {/*                    form={form}*/}
                                                                                    {/*                    qnIndex={index}*/}
                                                                                    {/*                    mode={mode}*/}
                                                                                    {/*                />*/}
                                                                                    {/*            </div>*/}
                                                                                    {/*}*/}
                                                                                </div>
                                                                            </Card>
                                                                        </div>
                                                                    );
                                                                }}
                                                            </Draggable>
                                                        );
                                                    })}
                                                    {provided.placeholder}
                                                    <div className="qn-add-btn-group">
                                                        <Form.Item>
                                                            <Button type="dashed" onClick={() => add()} block>
                                                                + 질문 추가
                                                            </Button>
                                                        </Form.Item>
                                                    </div>
                                                </>
                                            )}
                                        </Form.List>
                                    </div>
                                    {
                                        stage === StageType.STRUCTURING &&
                                        <TagImporting
                                            onDropEnd={handleDropEnd}
                                            // selectedQnType={selectedQnType ? selectedQnType : QnType.EMOTION}
                                        />
                                    }
                                </DndProvider>
                            </div>
                        </div>
                    )
                    }
                </Droppable>
            </DragDropContext>
        </>
    )
        ;
};

export default QnFormList;
