import React, { useEffect, useState } from 'react';
import { Button, Form, Modal, message } from 'antd';
import AppReviewQnFormList from '../../components/formlist/appmain/AppReviewQnFormList';
import { DownOutlined } from '@ant-design/icons';
import { ConvoService } from '../../services/ConvoService';
import { StageType } from '../../types/enumtype/stageEnumtype';
import { PageMode } from '../../types/componentprops/pageTypes';
import { RiArrowGoBackLine } from 'react-icons/ri';
import { AiOutlineSave } from 'react-icons/ai';
import {
  ConvoData,
  ConvoQnData,
  ConvoQnStrFormData,
  ConvoReqData,
} from '../../types/convoTypes';
import ReviewResultChart, {
  ReviewResultChartData,
  ReviewResultContent,
} from 'components/ReviewResultChart';
import dayjs, { Dayjs } from 'dayjs';
import { MdOutlineDeleteForever } from 'react-icons/md';
import Colors from 'styles/colors';

const AppReview = () => {
  const [form] = Form.useForm();
  const [mode, setMode] = useState<PageMode>('read');
  const [cvId, setCvId] = useState<string>();
  const [data, setData] = useState<ConvoData>();
  const [chartDatas, setChartDatas] = useState<ReviewResultChartData[]>();
  // const [initialValues, setInitialValues] = useState<ConvoData[] | null>(null);
  const [initialValues, setInitialValues] = useState<any>(null);

  useEffect(() => {
    getData();
    getChartData();
  }, []);

  const getData = async () => {
    const res = await ConvoService.getByStage(StageType.APP_REVIEW);
    /*Todo:
           기타 주관식 설정기능 추가 :
           기타 주관식 일 경우, AnswerOption 에 기타 주관식 태그 연결 (tag table data 에 '기타 주관식' 추가한 후 연결)
        */
    try {
      if (res.data.result && res.data.result.length > 0) {
        if (res.data.result) {
          setData(res.data.result[0]);
          const _cvId = res.data.result[0].id;
          setCvId(_cvId);
          await getQns(_cvId);
        }
      } else {
        if (res.data.message) {
          throw new Error(res.data.message);
        }
      }
    } catch (e: any) {
      console.error(e.message);
    }
  };

  const getChartData = async () => {
    const res = await ConvoService.getAppReviewDatas();
    try {
      if (res.data.result && res.data.result.length > 0) {
        const newData: ReviewResultChartData[] = res.data.result;
        if (res.data.result.length > 0) {
          setChartDatas(newData);
        }
      } else {
        if (res.data.message) {
          throw new Error(res.data.message);
        }
      }
    } catch (e: any) {
      console.error(e.message);
    }
  };

  const toJsonQnContent = (reqBody: ConvoQnData[]) => {
    return reqBody.map((qn) => {
      return {
        ...qn,
        content: [{ text: qn.content }],
      };
    });
  };

  const fromJsonQnContent = (resBody: ConvoQnData[]) => {
    return resBody.map((qn) => {
      return {
        ...qn,
        content: qn.content[0].text,
      };
    });
  };

  const getQns = async (cvId: string) => {
    const res = await ConvoService.getQns(cvId);
    if (res.data.success) {
      const newResult = fromJsonQnContent(res.data.result);
      setInitialValues(newResult);
    } else {
      message.error('질문 데이터를 가져오는데 실패했습니다.');
    }
  };

  const handleSave = async () => {
    form.submit();
  };
  const handleFinish = async (values: ConvoQnStrFormData) => {
    if (mode === 'create') {
      // cvId 받아서 createQns 호출
      const responseCvId = await create();

      if (responseCvId) {
        await createQns(values['convoQns'], responseCvId);
        setCvId(responseCvId);
      }
    } else {
      if (cvId) {
        await createQns(values['convoQns'], cvId);
        await getQns(cvId);
      }
    }
  };

  const create = async () => {
    const reqBody: ConvoReqData = {
      stage: StageType.APP_REVIEW,
      title: '기본 앱 리뷰',
    };
    const res = await ConvoService.create(reqBody);
    console.log('create', res.data);
    if (res.data.success) {
      return res.data.result.id;
    } else {
      message.error('저장에 실패했습니다.');
    }
  };

  const createQns = async (reqBody: any, cvId: string) => {
    const newReqBody = toJsonQnContent(reqBody);

    const res = await ConvoService.createQns(newReqBody, cvId);
    if (res.data.success) {
      message.success('저장되었습니다.');
      form.resetFields();
      setMode('read');
    } else {
      const msg = res.data.message ? res.data.message : '저장에 실패했습니다.';
      message.error(msg);
    }
  };

  const handleDeleteAnswers = async (cvId: string) => {
    // 유저의 답변을 삭제하는 메서드
    try {
      const res = await ConvoService.deleteAnswers(cvId);
      if (res.data.success) {
        message.success('유저 답변이 삭제되었습니다.');
        setTimeout(() => {
          form.submit();
        }, 1000);
      } else {
        throw new Error(res.data.message);
      }
    } catch (e: any) {
      message.error('답변을 삭제하는데 실패했습니다.');
      console.error(e.message);
    }
  };

  const exportExcelFile = () => {
    if (!chartDatas) {
      return;
    }
    const csvData = [];

    // CSV 헤더 추가
    // 정렬된 헤더 추가
    csvData.push(
      Object.keys(chartDatas[0])
        .filter((key) => key !== 'answers')
        .concat('answers')
        .join(','),
    );

    // 각 행의 데이터를 CSV 형식으로 변환하여 추가
    chartDatas.forEach((item: any) => {
      const row = [];
      for (const key in item) {
        if (item.hasOwnProperty(key)) {
          if (key !== 'answers') {
            row.push(
              typeof item[key] === 'string' && item[key].includes(',')
                ? `"${item[key]}"`
                : item[key],
            );
          }
        }
      }
      // answers 필드 추가
      row.push(
        item.answers
          .map((answer: ReviewResultContent) =>
            typeof answer.answer === 'string' && answer.answer.includes(',')
              ? `"${answer.answer}", ${answer.count}`
              : `${answer.answer}, ${answer.count}`,
          )
          .join(','),
      );
      csvData.push(row.join(','));
    });

    // CSV 파일 다운로드
    const csvContent = csvData.join('\n');
    const blob = new Blob([csvContent], {
      type: 'text/csv;charset=utf-8;',
    });

    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);

    link.setAttribute(
      'download',
      `app-review-backup-${dayjs().tz('Asia/Seoul').toISOString()}.csv`,
    );
    document.body.appendChild(link);
    link.click();
  };

  return (
    <>
      <div className="content-header">
        <h2>앱 리뷰 질문</h2>

        {/* STEP 1 */}
        {/* STEP 3 */}
        {mode === 'read' && // 페이지 로딩 직후 (또는 저장 후) 읽기 모드
          (cvId ? ( // 최초 등록 이후
            <Button
              className="new-btn"
              icon={<DownOutlined />}
              onClick={() => setMode('modify')}
            >
              질문 보기/수정
            </Button>
          ) : (
            // 최초 등록 시
            <Button
              className="new-btn"
              type="primary"
              icon={<DownOutlined />}
              onClick={() => setMode('create')}
            >
              질문 등록
            </Button>
          ))}

        {/* STEP 2*/}
        {mode !== 'read' && ( // 질문 등록 또는 질문 보기/수정 버튼 클릭 시 [저장] [취소] 버튼 출력
          <div className={'new-btn'}>
            <Button
              icon={<AiOutlineSave />}
              className={'save-btn'}
              type={'primary'}
              onClick={handleSave}
            >
              질문 저장
            </Button>
            <Button
              icon={<RiArrowGoBackLine />}
              className={'cancel-btn'}
              type="primary"
              ghost
              onClick={() => setMode('read')}
            >
              질문 등록 취소
            </Button>
            <Button
              icon={<MdOutlineDeleteForever />}
              className={'delete-btn'}
              type="primary"
              ghost
              onClick={() => {
                cvId &&
                  Modal.confirm({
                    title: '유저 답변 삭제',
                    content: '유저 답변을 삭제 후 저장하시겠습니까?',
                    icon: null,
                    okButtonProps: { type: 'primary', ghost: true },
                    cancelButtonProps: {
                      type: 'primary',
                      style: { background: Colors.primary_250 },
                    },
                    okText: '취소',
                    cancelText: '유저 답변 삭제',
                    onOk() {
                      Modal.destroyAll();
                    },
                    onCancel() {
                      handleDeleteAnswers(cvId);
                    },
                  });
              }}
            >
              답변 삭제하기
            </Button>
          </div>
        )}
        <Button
          className="new-btn"
          type="primary"
          icon={<DownOutlined />}
          onClick={exportExcelFile}
        >
          엑셀 다운로드
        </Button>
      </div>

      {/* STEP 2*/}
      {mode !== 'read' && ( // 질문 등록 또는 질문 보기/수정 버튼 클릭 시 [폼] 출력
        <Form
          name="convo"
          form={form}
          autoComplete="off"
          colon={false}
          onFinish={handleFinish}
          requiredMark={false}
        >
          <AppReviewQnFormList
            mode={mode}
            form={form}
            initialValues={initialValues}
          />
        </Form>
      )}
      {chartDatas && chartDatas.length > 0 ? (
        chartDatas.map((nowQuestion) => {
          return (
            <ReviewResultChart
              key={nowQuestion.qnId}
              datas={nowQuestion.answers}
              total={nowQuestion.total}
              seq={nowQuestion.seq}
              title={nowQuestion.qnLabel}
            />
          );
        })
      ) : (
        <></>
      )}
    </>
  );
};

export default AppReview;
