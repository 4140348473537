import React, {useEffect} from 'react';
import {Button, Form, FormInstance, Popover, Select} from "antd";
import {StageType} from "../../../types/enumtype/stageEnumtype";
import {MinusCircleOutlined} from "@ant-design/icons";
import {MdInsertLink} from "react-icons/md";
import Colors from "../../../styles/colors";
import AoBoxInnerInput from "./AoBoxInnerInput";

interface AoBoxFormProps {
    form: FormInstance<any>;
    qnIndex: number;
    stage: StageType;
}

const AoBoxFormList = ({form, qnIndex}: AoBoxFormProps) => {

    useEffect(() => {
        // if (mode === "create") {
        const ao = form.getFieldValue(['convoQns', qnIndex, 'answerOptions']);
        if (!ao) {
            form.setFieldsValue({
                ['convoQns']: {
                    [qnIndex]: {
                        answerOptions: [{}]
                    }
                }
            });
        }
        //console.log('AoBoxFormList form render', form.getFieldValue(['convoQns', qnIndex, 'answerOptions']));
    }, [form]);

    const oql = form.getFieldValue('convoQns').length; // opened question length
    const selectOptions: any[] = [];
    for (let i = 1; i <= oql; i++) {
        // desc: 연관질문은 자기자신 질문 외, 큰 번호 질문으로만 설정 가능
        if (i !== qnIndex + 1 && i > qnIndex + 1) {
            selectOptions.push({value: i, label: i + '번째 질문으로 이동'});
        }
    }

    const handleSelectChange = (value: number, index: number) => {
        const ao = form.getFieldValue(['convoQns', qnIndex, 'answerOptions', index]);
        ao.nextQnSeq = value;
        form.setFieldsValue({
            ['convoQns']: {
                [qnIndex]: {
                    answerOptions: {
                        [index]: ao
                    }
                }
            }
        });
    }

    return (
        <Form.List
            name={[qnIndex, "answerOptions"]}
        >
            {(fields, {add, remove}) => {
                const qnFormValues = form.getFieldValue(['convoQns', qnIndex]);
                return (<>
                        {fields.map((field, index) => (
                            <div key={index} className={"ao-box-outer"}>
                                {/* FormItem 1 */}
                                <Form.Item
                                    name={[index, 'qnId']}
                                    style={{display: 'none'}}
                                    initialValue={qnFormValues?.id}
                                ></Form.Item>

                                {/* Form DELETE */}
                                <span className={"ao-box-side"}>
                                <MinusCircleOutlined onClick={() => remove(index)}/>
                            </span>

                                {/* FormItem 2 */}
                                {/*<AoBoxInnerFormList form={form} qnIndex={qnIndex} aogIndex={index}/>*/}
                                <AoBoxInnerInput form={form} qnIndex={qnIndex} aoIndex={index}/>

                                {/* FormItem 3 */}
                                <span className={"ao-box-side"}>
                                <Popover
                                    trigger="click"
                                    placement="right"
                                    title="다음질문 설정하기"
                                    content={
                                        <Select
                                            placeholder={"순서대로 이동"}
                                            options={selectOptions}
                                            disabled={selectOptions.length === 0}
                                            allowClear
                                            onChange={(value: number) => {
                                                handleSelectChange(value, index);
                                            }}
                                            virtual={false}
                                        >
                                        </Select>}
                                >
                                <Form.Item
                                    name={[index, 'nextQnSeq']}
                                    initialValue={null}
                                >
                                    {qnFormValues?.isKeyQn &&
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                            {/*다음질문 번호*/}
                                            {form.getFieldValue(['convoQns', qnIndex, 'answerOptions', index, 'nextQnSeq']) &&
                                                <span className="ao-next-qn">
                                                    {'Q.' + form.getFieldValue(['convoQns', qnIndex, 'answerOptions', index, 'nextQnSeq'])}
                                                </span>}
                                            {/*연결되는 다음질문 아이콘*/}
                                            <MdInsertLink size={24} style={{
                                                color: Colors.emphasis_300,
                                                cursor: 'pointer'
                                            }}/>
                                        </div>}
                                </Form.Item>
                            </Popover>
                            </span>
                            </div>
                        ))}
                        <Form.Item>
                            <Button
                                type="dashed"
                                onClick={() => add()}
                                block
                            >
                                + 답변 옵션 추가
                            </Button>
                        </Form.Item>
                    </>
                )
            }}
        </Form.List>
    );
};

export default AoBoxFormList;
