enum EmotionType {
    POSITIVE = "POSITIVE",
    NEGATIVE = "NEGATIVE",
}

const emotionTypeLabel = {
    [EmotionType.POSITIVE]: "긍정감정",
    [EmotionType.NEGATIVE]: "부정감정",
}

const emotionTypeList = [
    EmotionType.POSITIVE,
    EmotionType.NEGATIVE,
]

export {EmotionType, emotionTypeLabel, emotionTypeList};
