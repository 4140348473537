import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export interface Tokens {
    accessToken: string | null;
    refreshToken: string | null;
}

export interface LoginSuccessPayload {
    tokens: Tokens;
    id: string;
    roles: string[];
    username: string;
}

export interface State {
    isLoggedIn: boolean;
    tokens: Tokens | null;
    id: string | null;
    roles: string[] | null;
    username: string | null;
    // userId: number | null;
    // siteId: number | null;
    // authorities: string[];
}

const initialToken: Tokens = {
    accessToken: null,
    refreshToken: null,
};

const initialState: State = {
    isLoggedIn: false,
    tokens: initialToken,
    id: null,
    roles: null,
    username: null,
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        loginSuccess: (
            state: State,
            action: PayloadAction<LoginSuccessPayload>,
        ) => {
            const {tokens, id, roles, username} = action.payload;
            state.isLoggedIn = true;
            state.tokens = tokens;
            state.id = id;
            state.roles = roles;
            state.username = username;
        },
        logoutSuccess: () => initialState,
    },
});

export const {loginSuccess, logoutSuccess} = authSlice.actions;
export default authSlice.reducer;
