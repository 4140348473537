import React from 'react';

interface SectionProps {
    title?: string;
    children?: React.ReactNode;
    style?: React.CSSProperties;
    extra?: React.ReactNode;
}


export const Section = ({title, children, style, extra}: SectionProps) => (
    <div className="section-container" style={style}>
        {title && <div className="section-header">{/*우 상단에 버튼 그룹 추가*/}
            <div className="section-title">
                <h2>{title}</h2>
            </div>
            {extra && <div className="section-extra">{extra}</div>}
        </div>}
        <div className="section-content">
            {children}
        </div>
    </div>
);

// exapmle
const TriSectionLayout = () => {
    return (
        <>
            <div className="layout-container">
                <Section title="Avatar"/>
                <Section title="Avatar"/>
            </div>
            <Section title="Avatar"/>
        </>
    );
};


export default TriSectionLayout;
