import axios, {AxiosResponse} from 'axios';
import {ApiResponseArrayData} from '../types/apiResponseDataTypes';
import {NoticeFaqData, NoticeWindowResData, WindowNoticeReqData} from '../types/noticeTypes';
import {PushAlarmData} from 'types/pushAlarmTypes';

const apiUrl = process.env.REACT_APP_API_URL + '/admin';
export const AdminPostService = {

    async getServerNotice(): Promise<
        AxiosResponse<ApiResponseArrayData<NoticeFaqData>>
    > {
        return await axios.get(`${apiUrl}/notice?type=server`);
    },

    async getWindowNotices(): Promise<
        AxiosResponse<ApiResponseArrayData<NoticeWindowResData>>
    > {
        return await axios.get(`${apiUrl}/notice?type=window`);
    },

    async createWindowNotice(reqBody: WindowNoticeReqData): Promise<
        AxiosResponse<ApiResponseArrayData<NoticeWindowResData>>
    > {
        return await axios.post(`${apiUrl}/notice/simple`, reqBody);
    },

    async modifyWindowNotice(
        reqBody: WindowNoticeReqData,
        id: string,
    ): Promise<AxiosResponse<ApiResponseArrayData<NoticeWindowResData>>> {
        return await axios.put(`${apiUrl}/notice/simple/${id}`, reqBody);
    },

    /*Notice*/
    async getNotices(): Promise<
        AxiosResponse<ApiResponseArrayData<NoticeFaqData>>
    > {
        return await axios.get(`${apiUrl}/notice`);
    },
    async createNotice(
        formData: FormData,
    ): Promise<AxiosResponse<ApiResponseArrayData<NoticeFaqData>>> {
        return await axios.post(`${apiUrl}/notice`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    },
    async modifyNotice(
        formData: FormData,
        id: string,
    ): Promise<AxiosResponse<ApiResponseArrayData<NoticeFaqData>>> {
        return await axios.put(`${apiUrl}/notice/${id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    },
    async deleteNotice(id: string): Promise<AxiosResponse<any>> {
        return await axios.delete(`${apiUrl}/notice/${id}`);
    },

    /*FAQ*/
    async getFaqs(): Promise<AxiosResponse<ApiResponseArrayData<NoticeFaqData>>> {
        return await axios.get(`${apiUrl}/faq`);
    },
    async createFaq(
        formData: FormData,
    ): Promise<AxiosResponse<ApiResponseArrayData<NoticeFaqData>>> {
        return await axios.post(`${apiUrl}/faq`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    },
    async modifyFaq(
        formData: FormData,
        id: string,
    ): Promise<AxiosResponse<ApiResponseArrayData<NoticeFaqData>>> {
        return await axios.put(`${apiUrl}/faq/${id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    },
    async deleteFaq(id: string): Promise<AxiosResponse<any>> {
        return await axios.delete(`${apiUrl}/faq/${id}`);
    },

    /*PUSH Alarm*/
    async getPushAlarms(): Promise<
        AxiosResponse<ApiResponseArrayData<PushAlarmData>>
    > {
        return await axios.get(`${apiUrl}/push`);
    },
    async createPushAlarm(
        formData: FormData,
    ): Promise<AxiosResponse<ApiResponseArrayData<PushAlarmData>>> {
        return await axios.post(`${apiUrl}/push`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    },
    async modifyPushAlarm(
        formData: FormData,
        id: string,
    ): Promise<AxiosResponse<ApiResponseArrayData<PushAlarmData>>> {
        return await axios.put(`${apiUrl}/push/${id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    },
    async deletePushAlarm(id: string): Promise<AxiosResponse<any>> {
        return await axios.delete(`${apiUrl}/push/${id}`);
    },
};
