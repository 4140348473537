import React, {CSSProperties} from 'react';
import {Card} from 'antd';
import {useDrag} from "react-dnd";
import {EmotionType} from "../types/enumtype/emotionEnumType";
import {TagMapValue} from "../types/tagTypes";
import Colors from "../styles/colors";

const _gridStyle: React.CSSProperties = {
    width: '25%',
    textAlign: 'center',
    padding: '0.5rem 1rem',
};

interface GridCardProps {
    optionType: EmotionType | 'FIELD' | 'TOPIC';
    contentList: TagMapValue[];
    onDropEnd: (item: { contentList: TagMapValue[] }, dropResult: DropResult) => void;

    /*optional*/
    title?: string;
    hoverable?: boolean;
    gridHoverable?: boolean;
    style?: React.CSSProperties;
    gridStyle?: React.CSSProperties;

}

const boxStyle: CSSProperties = {
    border: '1px dashed gray',
    backgroundColor: 'white',
    padding: '0.5rem 1rem',
    marginRight: '1.5rem',
    marginBottom: '1.5rem',
    cursor: 'move',
    float: 'left',
}

export interface DropResult {
    aoIndex: number;
}

const GridCard: React.FC<GridCardProps> = ({
                                               optionType,
                                               title,
                                               contentList,
                                               onDropEnd,
                                               hoverable = true,
                                               gridHoverable = false,
                                               style, gridStyle
                                           }) => {
    const [{isDragging}, drag] = useDrag(() => ({
        type: 'BOX',
        item: {contentList},
        /*item: {contentList: optionType !== 'TOPIC' ? contentList :
                contentList.length > 0 ?
                [{ seq:1,
                    id: contentList[0].id,
                    type: contentList[0].type,
                    name: contentList[0].type,
                 }]
                : []
        },*/
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult<DropResult>()
            if (item && dropResult) {
                // alert(`${dropResult.aogIndex}번째 답변옵션 박스에 드롭되었습니다.`)
                onDropEnd(item, dropResult);
            }
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
            handlerId: monitor.getHandlerId(),
        }),
    }))
    const opacity = isDragging ? 0.4 : 1


    return (
        <div ref={drag} style={{...style, opacity}} data-testid={`BOX`}>
            <Card title={title}
                  hoverable={hoverable}
                  size={"small"}
                  style={style}>
                {contentList.map((content, index) => (
                    <Card.Grid
                        key={index}
                        hoverable={gridHoverable}
                        style={{..._gridStyle, ...gridStyle}}>
                        <div style={{color: Colors.secondary_300}}>
                            {content.name}
                        </div>
                        {/*{content.name}*/}
                    </Card.Grid>
                ))}
            </Card>
        </div>
    );
}

export default GridCard;
