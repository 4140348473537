import React, {useEffect} from 'react';
import {Button, Form, FormInstance, Input, Popover, Select} from "antd";
import {MinusCircleTwoTone} from "@ant-design/icons";
import {MdInsertLink} from "react-icons/md";
import Colors from "../../styles/colors";

interface AoFormListProps {
    form: FormInstance<any>;
    qnIndex: number;
}

const AoFormList = ({form, qnIndex}: AoFormListProps) => {

    useEffect(() => {
        // if (mode === "create") {
        const ao = form.getFieldValue(['convoQns', qnIndex, 'answerOptions']);
        if (!ao) {
            form.setFieldsValue({
                ['convoQns']: {
                    [qnIndex]: {
                        answerOptions: [{}]
                    }
                }
            });
        }
    }, [form]);

    const oql = form.getFieldValue('convoQns').length; // opened question length
    const selectOptions: any[] = [];
    for (let i = 1; i <= oql; i++) {
        // desc: 연관질문은 자기자신 질문 외, 큰 번호 질문으로만 설정 가능
        if (i !== qnIndex + 1 && i > qnIndex + 1) {
            selectOptions.push({value: i, label: i + '번째 질문으로 이동'});
        }
    }

    const handleSelectChange = (value: number, index: number) => {
        const ao = form.getFieldValue(['convoQns', qnIndex, 'answerOptions', index]);
        ao.nextQnSeq = value;
        form.setFieldsValue({
            ['convoQns']: {
                [qnIndex]: {
                    answerOptions: {
                        [index]: ao
                    }
                }
            }
        });
    }

    const handleRemoveAo = (index: number, remove: any) => {
        remove(index); // index 번째 답변옵션 삭제 후, 나머지 답변옵션들의 seq 재설정
        const ao = form.getFieldValue(['convoQns', qnIndex, 'answerOptions']);
        ao.forEach((item: any, index: number) => {
            item.seq = index + 1;
        });
        form.setFieldsValue({
                ['convoQns']: {
                    [qnIndex]: {
                        answerOptions: ao
                    }
                }
            }
        );
    }

    return (
        <>
            <Form.List
                name={[qnIndex, "answerOptions"]}
                // initialValue={qnFormValues?.answerOptions || [{}]}
            >
                {(fields, {add, remove}) => {
                    const qnFormValues = form.getFieldValue(['convoQns', qnIndex]);
                    return (
                        <>
                            {fields.map((field, index) => (
                                <div key={index}>
                                    <Form.Item
                                        name={[index, 'qnId']}
                                        style={{display: 'none'}}
                                        initialValue={qnFormValues?.id}
                                    ></Form.Item>
                                    <Form.Item
                                        name={[index, 'seq']}
                                        style={{display: 'none'}}
                                        initialValue={index + 1}
                                    ></Form.Item>
                                    <Form.Item
                                        name={[index, "content"]}
                                        rules={[
                                            {
                                                required: true,
                                                message: '답변옵션을 입력해 주세요.',
                                            },
                                        ]}
                                    >
                                        <Input
                                            className={"ao-input"}
                                            placeholder="답변옵션을 입력해 주세요."
                                            /*삭제하기 버튼*/
                                            //prefix={<MinusCircleTwoTone onClick={() => remove(index)}/>}
                                            prefix={<MinusCircleTwoTone onClick={() => handleRemoveAo(index, remove)}/>}
                                            suffix={
                                                (
                                                    <Popover
                                                        trigger="click"
                                                        placement="right"
                                                        title="다음질문 설정하기"
                                                        content={
                                                            <Select
                                                                placeholder={"순서대로 이동"}
                                                                options={selectOptions}
                                                                disabled={selectOptions.length === 0}
                                                                allowClear
                                                                virtual={false}
                                                                onChange={(value: number) => {
                                                                    handleSelectChange(value, index);
                                                                }}
                                                            >
                                                            </Select>}
                                                    >
                                                        <Form.Item name={[index, 'nextQnSeq']}>
                                                            {qnFormValues?.isKeyQn &&
                                                                <div style={{display: 'flex', alignItems: 'center'}}>
                                                                    {/*다음질문 번호*/}
                                                                    {form.getFieldValue(['convoQns', qnIndex, 'answerOptions', index, 'nextQnSeq']) &&
                                                                        <span className="ao-next-qn">
                                                                            {'Q.' + form.getFieldValue(['convoQns', qnIndex, 'answerOptions', index, 'nextQnSeq'])}
                                                                        </span>}
                                                                    {/*연결되는 다음질문 아이콘*/}
                                                                    <MdInsertLink size={24} style={{
                                                                        color: Colors.emphasis_300,
                                                                        cursor: 'pointer'
                                                                    }}/>
                                                                </div>}
                                                        </Form.Item>
                                                    </Popover>
                                                )
                                            }
                                        />
                                    </Form.Item>
                                </div>
                            ))}
                            <Form.Item>
                                <Button
                                    type="dashed"
                                    onClick={() => add()}
                                    block
                                >
                                    + 답변 옵션 추가
                                </Button>
                            </Form.Item>
                        </>
                    )
                }}
            </Form.List>
        </>
    );
};

export default AoFormList;
