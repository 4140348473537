import {Button, Modal} from 'antd';
import React, {useState} from 'react';
import {FaCircleExclamation} from "react-icons/fa6";
import Colors from "../../styles/colors";

interface UseConfirmDeleteProps {
    handleClickConfirm: (param: any) => Promise<void>;
    title: string;
    content?: string;
}

const UseConfirmDelete = ({handleClickConfirm, title, content}: UseConfirmDeleteProps) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [record, setRecord] = useState<any>();

    const openConfirmModal = (record: any) => {
        setIsModalVisible(true);
        setRecord(record);
    }

    const handleClickOk = async () => {
        if (record) {
            try {
                await handleClickConfirm(record);
            } catch (error: any) {
                throw new Error(error);
            } finally {
                setIsModalVisible(false);
                setRecord(null);
            }
        }
    }

    const confirmModal = (
        <Modal
            title={
                <div className='confirm-modal-title btn-with-text'>
                    <FaCircleExclamation color={Colors.emphasis_200}/>
                    <span>{title}</span>
                </div>
            }
            open={isModalVisible}
            width={400}
            closeIcon={null}
            footer={
                <div className='confirm-modal-footer'>
                    <Button type="primary"
                            onClick={handleClickOk}
                    >확인</Button>
                    <Button type="primary" ghost
                            onClick={() => setIsModalVisible(false)}
                    >취소</Button>
                </div>
            }>
            { content && <div className='confirm-modal-content'>
                {content}
            </div>}
        </Modal>
    );

    return {openConfirmModal, confirmModal};
};

export default UseConfirmDelete;
