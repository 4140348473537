import React from 'react';
import {Form, FormInstance, Input} from "antd";
import {useDrop} from "react-dnd";
import Colors from "../../../styles/colors";
import {MinusCircleTwoTone} from "@ant-design/icons";
import {
    TopicGroup,
    topicGroupLabel,
    topicGroupList,
} from "../../../types/enumtype/topicGroupEnumtype";

interface AoBoxInnerProps {
    form: FormInstance<any>;
    qnIndex: number;
    aoIndex: number;
}

const AoBoxInnerInput = ({form, qnIndex, aoIndex}: AoBoxInnerProps) => {
    const [{canDrop, isOver}, drop] = useDrop(() => ({
        accept: 'BOX',
        drop: () => ({aoIndex}),
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    }))

    const isActive = canDrop && isOver
    //let backgroundColor = Colors.primary_50;
    let backgroundColor = Colors.primary_50;
    if (isActive) {
        backgroundColor = Colors.primary_100;
    }

    const value = form.getFieldValue(['convoQns', qnIndex,'answerOptions', aoIndex]);

    return (
        <div ref={drop}
             style={{backgroundColor}}
             className={"ao-box-inner"}
             // style={{display: !topicGroupList.includes(value.content) ? 'none' : 'block'}}
        >
        <Form.Item
            name={[aoIndex, 'content']}
            //style={{display: topicGroupList.includes(value.content) ? 'none' : 'block'}}
        >
                <Input
                    className={'ao-topic-group'}
                    style={{display: value?.content ? 'block' : 'none'}}
                />
        </Form.Item>
        </div>
    );
};

export default AoBoxInnerInput;
