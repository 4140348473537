import React, { useEffect, useState } from 'react';
import {
  Button,
  DatePicker,
  Dropdown,
  Form,
  Input,
  message,
  Popover,
  Table,
} from 'antd';
import { Section } from '../../components/TriSectionLayout';
import { AnyObject } from 'antd/es/_util/type';
import { EditOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { AiOutlineDelete } from 'react-icons/ai';
import UseConfirmDelete from '../../utils/useHook/UseConfirmDelete';
import { RiSettings3Fill } from 'react-icons/ri';
import { AdminPostService } from '../../services/AdminPostService';
import {
  NoticeWindowResData,
  WindowNoticeReqData,
} from '../../types/noticeTypes';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import NoticeWindowModal from './NoticeWindowModal';

dayjs.extend(utc);
dayjs.extend(timezone);

const NoticeWindow = () => {
  const [form] = Form.useForm();
  const [data, setData] = useState<NoticeWindowResData[]>();
  const [selectedRecord, setSelectedRecord] = useState<NoticeWindowResData>();
  const [modalVsb, setModifyModalVsb] = useState(false);

  useEffect(() => {
    getNotices();
  }, []);

  const getNotices = async () => {
    const res = await AdminPostService.getWindowNotices();
    if (res.data.success) {
      setData(res.data.result);
    } else {
      throw new Error(res.data.message);
    }
  };

  const handleFinishCreate = async (values: AnyObject) => {
    const { title, content, dateRange } = values;
    const reqBody: WindowNoticeReqData = {
      title,
      content,
      type: 'window',
      startDate: dateRange[0].format('YYYY-MM-DD'),
      endDate: dateRange[1].format('YYYY-MM-DD'),
    };
    await createNotices(reqBody);
  };

  const createNotices = async (reqBody: WindowNoticeReqData) => {
    const res = await AdminPostService.createWindowNotice(reqBody);
    if (res.data.success) {
      message.success('생성되었습니다.');
      await getNotices();
    } else {
      throw new Error(res.data.message);
    }
  };

  const handleFinishModify = async (values: AnyObject) => {
    const { dateRange, title, content } = values;
    const reqBody: WindowNoticeReqData = {
      title,
      content,
      type: 'window',
      startDate: dateRange[0].format('YYYY-MM-DD'),
      endDate: dateRange[1].format('YYYY-MM-DD'),
    };
    modifyNotices(reqBody);
  };

  const modifyNotices = async (reqBody: WindowNoticeReqData) => {
    if (!selectedRecord) return;
    const res = await AdminPostService.modifyWindowNotice(
      reqBody,
      selectedRecord!.id,
    );
    if (res.data.success) {
      await getNotices();
      setModifyModalVsb(false);
      setSelectedRecord(undefined);
      message.success('수정되었습니다.');
    } else {
      throw new Error(res.data.message);
    }
  };

  const handleDelete = async (id: string) => {
    await deleteNotice(id);
  };

  const deleteNotice = async (id: string) => {
    const res = await AdminPostService.deleteNotice(id);
    if (res.data.success) {
      await getNotices();
      message.success('삭제되었습니다.');
    } else {
      throw new Error(res.data.message);
    }
  };

  const { handleClickDelete, confirmModal } = UseConfirmDelete({
    handleDelete,
  });

  const items = [
    {
      key: '1',
      label: (
        <>
          <EditOutlined />
          <span className={'menu-item-text'}>수정하기</span>
        </>
      ),
      onClick: () => selectedRecord && setModifyModalVsb(true),
    },
    {
      key: '2',
      label: (
        <>
          <AiOutlineDelete />
          <span className={'menu-item-text'}>삭제하기</span>
        </>
      ),
      onClick: () => selectedRecord && handleClickDelete(selectedRecord?.id),
    },
  ];

  const columns: ColumnsType<NoticeWindowResData> = [
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      width: 100,
      align: 'center',
      render: (value: string, record: NoticeWindowResData, index: number) => (
        <Dropdown
          trigger={['click']}
          menu={{ items }}
          placement="bottomLeft"
          arrow
        >
          <div className="setting-icon-container">
            <RiSettings3Fill
              size={24}
              onClick={() => {
                setSelectedRecord(record);
              }}
            />
          </div>
        </Dropdown>
      ),
    },
    {
      title: '공지기간',
      dataIndex: 'dateRange',
      key: 'dateRange',
      width: 200,
      align: 'center',
      render(value: string[]) {
        // "dateRange": [
        //     "2024-03-12",
        //     "2024-03-13"
        // ]
        // return <div>{`${dayjs(value[0]).format('YY. MM. DD')} ~ ${dayjs(value[1]).format('YY. MM. DD')}`}</div>
        return (
          <div className={'date-text'}>{`${dayjs(value[0])
            .tz('Asia/Seoul')
            .format('YYYY.MM.DD')} ~ ${dayjs(value[1])
            .tz('Asia/Seoul')
            .format('YYYY.MM.DD')}`}</div>
        );
      },
    },
    {
      title: '제목',
      dataIndex: 'title',
      key: 'title',
      width: 100,
      align: 'center',
    },
    {
      title: '내용',
      dataIndex: 'content',
      key: 'content',
      width: 300,
      align: 'center',
      render: (value: string) => {
        const longText = value.length > 100;
        return longText ? (
          <Popover
            content={
              <div style={{ maxWidth: 300, maxHeight: 300, overflow: 'auto' }}>
                {value}
              </div>
            }
            trigger="hover"
            placement="bottom"
          >
            <span>{value.slice(0, 100)}...</span>
          </Popover>
        ) : (
          <span>{value}</span>
        );
      },
    },
  ];

  return (
    <>
      <div className="layout-container two-to-three">
        <Section title={`새 공지창 등록`}>
          <div>
            <Form
              className="create-form"
              name="create-form"
              form={form}
              autoComplete="off"
              colon={false}
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 18 }}
              requiredMark={false}
              onFinish={handleFinishCreate}
            >
              <Form.Item
                name="dateRange"
                label="공지기간"
                rules={[
                  { required: true, message: '공지기간을 입력해 주세요' },
                ]}
              >
                <DatePicker.RangePicker
                  disabledDate={(current) => {
                    return (
                      current &&
                      current < dayjs().tz('Asia/Seoul').startOf('day')
                    );
                  }}
                  placeholder={['시작날짜', '종료날짜']}
                />
              </Form.Item>

              <Form.Item
                name="title"
                label={'제목'}
                rules={[{ required: true, message: `제목을 입력해 주세요` }]}
              >
                <Input placeholder={`제목 입력`} />
              </Form.Item>

              <Form.Item name="content" label="내용" initialValue={''}>
                <Input.TextArea
                  placeholder="내용 입력"
                  style={{ minHeight: 200, overflow: 'auto' }}
                />
              </Form.Item>

              <div className="centered-outer">
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ minWidth: 100 }}
                >
                  생성
                </Button>
              </div>
            </Form>
          </div>
        </Section>

        {/* Form Item : 제목, 이미지, 내용 */}
        <Section title={'공지창 목록'}>
          <Table
            size={'small'}
            dataSource={data}
            rowKey={'id'}
            columns={columns}
            pagination={{ position: ['bottomCenter'] }}
          />
        </Section>
      </div>
      {confirmModal}
      {modalVsb && (
        <NoticeWindowModal
          open={modalVsb}
          close={() => setModifyModalVsb(false)}
          handleFinish={handleFinishModify}
          record={selectedRecord}
        />
      )}
    </>
  );
};

export default NoticeWindow;
