import React, { useEffect, useState } from 'react';
import { Button, Form, Input, message, Modal, Select, Tabs } from 'antd';
import { PsyTestService } from '../../services/PsyTestService';
import PsyTestQnFormList from '../../components/formlist/psytest/PsyTestQnFormList';
import { AiOutlineSave } from 'react-icons/ai';
import TypeTestResultFormList from '../../components/formlist/psytest/TypeTestResultFormList';
import {
  PsyTestQnReq,
  PsyTestResultType,
  ScorePsyTestResultType,
} from '../../types/psyTestTypes';
import { TestType, testTypeLabel } from '../../types/enumtype/psytestEnumtype';
import ScoreTestResultFormList from '../../components/formlist/psytest/ScoreTestResultFormList';
import { PageMode } from '../../types/componentprops/pageTypes';
import { useNavigate, useParams } from 'react-router-dom';
import { RiArrowGoBackLine } from 'react-icons/ri';
import { MdOutlineDeleteForever, MdPreview } from 'react-icons/md';
import Colors from '../../styles/colors';
import TabPane from 'antd/es/tabs/TabPane';
import { useDispatch } from 'react-redux';

const PsyTestQns = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { mode, ptId, testType: testTypeParam } = params;
  const testType = (testTypeParam as string).toUpperCase() as TestType;
  const [initialValues, setInitialValues] = useState<any[] | null>(null);
  const [rtInitialValues, setRtInitialValues] = useState<
    PsyTestResultType[] | ScorePsyTestResultType[]
  >();
  const [maxScore, setMaxScore] = useState<number>();
  const [minScore, setMinScore] = useState<number>();
  const previousPath = '/psytest';

  // 점수테스트 답변옵션 설정
  const [aosetCount, setAosetCount] = useState<number>(1);
  const [aosetFirstScore, setAosetFirstScore] = useState<number>(0);
  const [aosetLastScore, setAosetLastScore] = useState<number>(0);

  const [form] = Form.useForm();
  const [resultForm] = Form.useForm();

  const dispatch = useDispatch();

  useEffect(() => {
    if (mode === 'modify') {
      getData();
    }
  }, [mode]);

  const getData = async () => {
    if (!ptId) return;
    const res = await PsyTestService.getQns(ptId);
    if (res.data.success && res.data.result.length > 0) {
      setInitialValues(res.data.result);
    }
    const res2 = await PsyTestService.getRts(ptId);
    if (res2.data.success && res2.data.result.length > 0) {
      setRtInitialValues(res2.data.result);
      setMaxScore(calculateMaxScore());
    }
  };

  const handleSave = async () => {
    form.submit();
  };
  const validateResultTypes = (resultTypes: any[]) => {
    const invalidMessages: string[] = [];
    const endScores = resultTypes.map((resultType: any) => resultType.endScore);
    const rtMaxScore = Math.max(...endScores);
    // const mmaxScoreinScore = Math.min(...endScores);
    const _maxScore = calculateMaxScore();

    if (rtMaxScore > _maxScore || !endScores.includes(_maxScore)) {
      invalidMessages.push(
        `결과유형의 점수범위가 올바르지 않습니다. \n 다시 설정하여 주세요.`,
      );
    }
    return invalidMessages;
  };
  const handleFinish = async (values: any) => {
    if (testType !== TestType.TYPE_TEST) {
      const resultTypeValue = resultForm.getFieldValue('psyTestResultTypes');
      console.log('resultTypeValue >>> ', resultTypeValue);

      if (!resultTypeValue) {
        message.warning('결과유형을 확인하여 주세요.');
        return;
      }
      // 결과 유형 유효성 검사
      const invalidMessages = validateResultTypes(resultTypeValue);

      // 유효하지 않은 결과 유형이 있으면 유효성 검사 오류 메시지 표시
      if (invalidMessages.length > 0) {
        const errorMessage = invalidMessages.join('\n');
        message.warning(errorMessage);
        return;
      }
    }

    // 모든 결과 유형이 유효하면, 폼 제출
    resultForm
      .validateFields()
      .then((rtValues) => {
        createQns(rtValues['psyTestResultTypes'], values['psyTestQns']);
      })
      .catch((e) => {
        console.error('Error in resultForm validation:', e);
        message.warning(e.errorFields[0].errors[0]);
      });
  };

  const createQns = async (
    rtsReq: PsyTestResultType[],
    qnsReq: PsyTestQnReq[],
  ) => {
    if (!ptId) return;
    PsyTestService.createRts(ptId, rtsReq)
      .then((res) => {
        if (res.data.success) {
          return PsyTestService.createQns(ptId, qnsReq);
        } else {
          throw new Error(res.data.message);
        }
      })
      .then((res2) => {
        if (res2.data.success) {
          message.success('저장되었습니다.');
          form.resetFields();
          navigate(previousPath);
        } else {
          throw new Error(res2.data.message);
        }
      })
      .catch((e) => {
        if (e.response?.status === 409) {
          const msg = e.response.data.message;
          Modal.confirm({
            title: msg,
            content: '유저 답변을 삭제 후 저장하시겠습니까?',
            icon: null,
            okButtonProps: { type: 'primary', ghost: true },
            cancelButtonProps: {
              type: 'primary',
              style: { background: Colors.primary_250 },
            },
            okText: '취소',
            cancelText: '유저 답변 삭제',
            onOk() {
              Modal.destroyAll();
            },
            onCancel() {
              handleDeleteAnswers(ptId);
            },
          });
        }
      });
  };

  /*점수테스트 최대값, 최소값 계산*/
  const handleClickResultTab = () => {
    calculateMaxScore();
  };

  const handleDeleteAnswers = async (ptId: string) => {
    // 유저의 답변을 삭제하는 메서드
    try {
      const res = await PsyTestService.deleteAnswers(ptId);
      if (res.data.success) {
        message.success('유저 답변이 삭제되었습니다.');
        setTimeout(() => {
          form.submit();
        }, 1000);
      } else {
        throw new Error(res.data.message);
      }
    } catch (e: any) {
      message.error('답변을 삭제하는데 실패했습니다.');
      console.error(e.message);
    }
  };

  const calculateEachMaxScores = () => {
    const questions = form.getFieldValue('psyTestQns');
    return questions?.map((question: any) => {
      return question.answerOptions?.reduce(
        (max: number, option: any) => Math.max(max, option.score || 0),
        0,
      );
    });
  };

  const calculateMaxScore = () => {
    const eachMaxScores = calculateEachMaxScores();
    const totalMaxScore = eachMaxScores?.reduce(
      (a: number, b: number) => a + b,
      0,
    );
    console.log('totalMaxScore >>> ', totalMaxScore);
    setMaxScore(totalMaxScore);
    return totalMaxScore;
  };

  const calculateEachMinScores = () => {
    const questions = form.getFieldValue('psyTestQns');
    return questions?.map((question: any) => {
      return question.answerOptions?.reduce(
        (min: number, option: any) => Math.min(min, option.score || Infinity),
        0,
      );
      // Todo: 기본값 0 대신 Infinity 로 하면, 최소값 0 다음으로 큰 값이 최소값으로 계산 되는 문제 수정
      // return question.answerOptions?.reduce((min: number, option: any) => Math.min(min, option.score || Infinity), Infinity);
    });
  };
  /*점수테스트 최대값, 최소값 계산 end */
  const handleOpenChatroom: () => void = () => {
    window.open(
      `/chat-preview/psytest/${ptId}`,
      'Child',
      'width=900, height=900',
    );
  };
  const tabBarExtraContentLeft = (
    <div className={'tab-bar-extra'}>
      <Button
        icon={<AiOutlineSave />}
        className={'save-btn'}
        type={'primary'}
        onClick={handleSave}
      >
        질문 저장
      </Button>
      <Button
        icon={<RiArrowGoBackLine />}
        className={'cancel-btn'}
        type="primary"
        ghost
        onClick={() => navigate(previousPath)}
      >
        질문 등록 취소
      </Button>
      {mode === 'modify' && (
        <>
          <Button
            icon={<MdPreview />}
            className={'preview-btn'}
            type="primary"
            ghost
            onClick={handleOpenChatroom}
          >
            채팅 미리보기
          </Button>
          <Button
            icon={<MdOutlineDeleteForever />}
            className={'delete-btn'}
            type="primary"
            ghost
            onClick={() => {
              ptId &&
                Modal.confirm({
                  title: '유저 답변 삭제',
                  content: '유저 답변을 삭제 후 저장하시겠습니까?',
                  icon: null,
                  okButtonProps: { type: 'primary', ghost: true },
                  cancelButtonProps: {
                    type: 'primary',
                    style: { background: Colors.primary_250 },
                  },
                  okText: '취소',
                  cancelText: '유저 답변 삭제',
                  onOk() {
                    Modal.destroyAll();
                  },
                  onCancel() {
                    handleDeleteAnswers(ptId);
                  },
                });
            }}
          >
            답변 삭제하기
          </Button>
        </>
      )}
    </div>
  );

  const tabBarExtraContentMap = {
    left: tabBarExtraContentLeft,
  };

  const handleSaveAoset = () => {
    console.log('aosetCount >>> ', aosetCount);
    console.log('aosetFirstScore >>> ', aosetFirstScore);
    console.log('aosetLastScore >>> ', aosetLastScore);
  };

  return (
    <Tabs
      defaultActiveKey="1"
      type="card"
      onTabClick={(activeKey) => {
        if (activeKey === '2') {
          handleClickResultTab();
        }
      }}
      tabBarExtraContent={tabBarExtraContentMap}
    >
      <TabPane
        key="1"
        tab={<h3 className="tab-title">질문</h3>}
        className="tab-content"
      >
        <div className="psytest-qns">
          <Form
            name="qnListForm"
            form={form}
            autoComplete="off"
            colon={false}
            onFinish={handleFinish}
            requiredMark={false}
          >
            <PsyTestQnFormList
              form={form}
              resultForm={resultForm}
              mode={mode as PageMode}
              initialValues={initialValues}
              rtInitialValues={rtInitialValues}
              testType={testType as TestType}
              setMaxScore={setMaxScore}
              setMinScore={setMinScore}
            />
          </Form>
        </div>
      </TabPane>
      <TabPane
        key="2"
        tab={
          testTypeLabel[testType] && (
            <h3 className="tab-title">{testTypeLabel[testType] + ' 결과'}</h3>
          )
        }
        className="tab-content"
        forceRender // 탭 클릭하기 전 미리 렌더링
      >
        <div className="psytest-results">
          <Form
            name="resultListForm"
            form={resultForm}
            autoComplete="off"
            colon={false}
            requiredMark={false}
          >
            {testType === TestType.TYPE_TEST ? (
              <TypeTestResultFormList
                form={resultForm}
                initialValues={rtInitialValues}
              />
            ) : (
              <ScoreTestResultFormList
                form={resultForm}
                initialValues={rtInitialValues as ScorePsyTestResultType[]}
                maxScore={maxScore}
                minScore={minScore}
              />
            )}
          </Form>
        </div>
      </TabPane>
    </Tabs>
  );
};

export default PsyTestQns;
