import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {saveBreadcrumb} from 'store/reducer/breadcrumb';
import {Link} from 'react-router-dom';
import type {MenuProps} from 'antd';
import {Layout, Menu} from 'antd';
import {TagFilled, UserAddOutlined} from '@ant-design/icons';
import './Sidebar.scss';
import {RootState} from "../../store";
import logo_lg from "../../assets/images/bg/soulme_logo.png";
import {IoChatbubbleEllipses} from "react-icons/io5";
import {FaHouseDamage, FaMobileAlt} from "react-icons/fa";
import {MdOutlinePostAdd, MdRateReview} from "react-icons/md";
import {SiGoogleforms} from "react-icons/si";
import {AiFillNotification} from "react-icons/ai";

const {Sider} = Layout;
const mainPagePath = "/conversation/smalltalk";

type SideMenu = {
    key: React.Key;
    title: string;
    link?: string;
    icon?: any;
    children?: SideMenu[];
};

interface SideMenuProps extends MenuProps {
    onClick(): void;
}

type SidebarProps = {
    collapsed: boolean;
};

const createLable = (menu: SideMenu) => {
    let lable: any = menu.title;
    const link = menu.link;
    if (link) {
        lable = <Link to={link}>{lable}</Link>;
    }
    return lable;
};

const menus: SideMenu[] = [
    {
        key: 'main-0',
        title: '앱 메인화면',
        icon: FaMobileAlt,
        children: [
            {
                key: 'sub-01',
                icon: AiFillNotification,
                title: '공지창',
                link: '/appmain/notice-window',
            },
            {
                key: 'sub-02',
                icon: IoChatbubbleEllipses,
                title: '랜덤 말풍선',
                link: '/appmain/random-speech-bubble',
            },
            {
                key: 'sub-03',
                icon: MdRateReview,
                title: '앱 리뷰',
                link: '/appmain/app-review',
            },
        ],
    },
    {
        key: 'main-1',
        title: '대화하기',
        icon: IoChatbubbleEllipses,
        children: [
            {
                key: 'sub-11',
                icon: IoChatbubbleEllipses,
                title: '스몰톡',
                link: '/conversation/smalltalk',
            },
            {
                key: 'sub-12',
                icon: IoChatbubbleEllipses,
                title: '구조화',
                link: '/conversation/structuring',
            },
            {
                key: 'sub-15',
                icon: IoChatbubbleEllipses,
                title: '리뷰',
                link: '/conversation/review',
            },
            {
                key: 'sub-16',
                icon: TagFilled,
                title: '태그관리',
                link: '/conversation/tag',
            }
        ],
    },
    {
        key: 'main-2',
        title: '고민상담',
        icon: SiGoogleforms,
        link: '/counseling',
    },
    {
        key: 'main-3',
        title: '심리테스트',
        icon: SiGoogleforms,
        link: '/psytest',
    },
    {
        key: 'main-4',
        title: '나의 집 꾸미기',
        icon: FaHouseDamage,
        link: '/decoration/avatar',
        children: [
            {
                key: 'sub-41',
                title: '아바타 관리',
                link: '/decoration/avatar',
            },
            {
                key: 'sub-42',
                title: '배경 관리',
                link: '/decoration/background',
            },
        ],
    },
    {
        key: 'main-5',
        title: '관리자 게시글',
        icon: MdOutlinePostAdd,

        link: '/adminpost/notice',
        children: [
            {
                key: 'sub-51',
                title: '공지사항',
                link: '/adminpost/notice',
            },
            {
                key: 'sub-52',
                title: 'FAQ',
                link: '/adminpost/faq',
            },
            {
                key: 'sub-53',
                title: '푸시알림',
                link: '/adminpost/pushalarm',
            },
        ],
    },
    {
        key: 'main-6',
        title: '상담사 관리',
        icon: UserAddOutlined,
        link: '/counselor',
    },
    // {
    //     key: 'main-7',
    //     title: '채팅 미리보기',
    //     icon: IoChatbubbleEllipses,
    //     link: '/chat-preview/counseling',
    //     children: [
    //         {
    //             key: 'sub-71',
    //             title: '대화하기',
    //             icon: IoChatbubbleEllipses,
    //             link: '/chat-preview/conversation',
    //         },
    //         {
    //             key: 'sub-72',
    //             title: '고민상담',
    //             icon: IoChatbubbleEllipses,
    //             link: '/chat-preview/counseling',
    //         },
    //     ],
    // }

    /*    {
            key: 'main-6',
            title: '리워드 관리',
            icon: FormOutlined,
            link: '/reward',
            children: [
                {
                    key: 'sub-61',
                    icon: FormOutlined,
                    title: '결제',
                    link: '#611',
                },
                {
                    key: 'sub-62',
                    icon: FormOutlined,
                    title: '레벨/하트/마음나무 정책',
                    link: '#622',
                },
            ],
        },
        {
            key: 'main-7',
            title: '사용자 관리',
            icon: FormOutlined,
            link: '/user',
        },*/
];

const Sidebar: React.FC<SidebarProps> = ({collapsed}) => {
    const dispatch = useDispatch();
    const role = useSelector((state: RootState) => state.auth.roles?.[0]);
    const [logo, setLogo] = useState<any>(
        <img src={logo_lg} alt="logo" style={{width: '100px'}}/>,
    );

    useEffect(() => {
        if (!collapsed) {
            setLogo(<img src={logo_lg} alt="logo" style={{width: '100px'}}/>);
        } else {
            setLogo(<div className="sidebar__text">S</div>);
        }
    }, [collapsed]);

    useEffect(() => {
        onSaveBreadcrumb('main-2');
    }, []);

    const onSaveBreadcrumb = (key: React.Key) => {
        const breadcrumb: string[] = [];
        menus.forEach((menu) => {
            if (menu.key === key) {
                breadcrumb.push(menu.title);
            } else {
                menu.children?.forEach((subMenu) => {
                    if (subMenu.key === key) {
                        breadcrumb.push(menu.title);
                        breadcrumb.push(subMenu.title);
                    }
                });
            }
        });

        dispatch(saveBreadcrumb(breadcrumb));
    };

    const items: SideMenuProps['items'] | null = menus.map((menu: SideMenu) => {
        const lable = createLable(menu);
        return {
            key: menu.key,
            icon: menu.icon && React.createElement(menu.icon),
            label: lable,
            onClick: () => !menu.children && onSaveBreadcrumb(menu.key),

            children: menu.children?.map((subMenu) => {
                const subKey = subMenu.link;
                const subLable = createLable(subMenu);
                if (subMenu.icon) {
                    return {
                        key: subKey,
                        icon: React.createElement(subMenu.icon),
                        label: subLable,
                        onClick: () => onSaveBreadcrumb(subMenu.key),
                    };
                } else {
                    return {
                        key: subKey,
                        label: subLable,
                        onClick: () => onSaveBreadcrumb(subMenu.key),
                    };
                }
            }),
        };
    });

    //const nonAdminItem = items?.filter(item => item?.key !== 'main-8' && item?.key !== 'main-5' && item?.key !== 'main-4');
    const adminOnlyItemKeys = ['main-0', 'main-4', 'main-5', 'main-6'];
    const nonAdminItems = items?.filter(item => !adminOnlyItemKeys.includes(item?.key as string));

    return (
        <Sider id="sidebar" trigger={null} collapsible collapsed={collapsed}>
            <div className="sidebar__title">
                <div className="sidebar__img">
                    <a href={mainPagePath}>
                        {logo}
                    </a>
                </div>
            </div>
            <div className="sidebar__menu">
                <Menu
                    id="side-menu"
                    mode="inline"
                    //defaultSelectedKeys={['main-2']}
                    //defaultOpenKeys={['main-1', 'main-4', 'main-5']}
                    forceSubMenuRender={true}
                    items={role === 'ADMIN' ? items : nonAdminItems}
                />
            </div>
        </Sider>
    );
};

export default Sidebar;
