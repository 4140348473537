import React, {useEffect} from 'react';
import {Button, Card, Form, FormInstance, Input, message} from "antd";
import {DeleteOutlined} from "@ant-design/icons";
import {v4 as uuidv4} from "uuid";
import {PageMode} from "../../../types/componentprops/pageTypes";
import {PsyTestResultType} from "../../../types/psyTestTypes";

interface QnFormListProps {
    form: FormInstance<any>;
    // mode: PageMode
    initialValues: PsyTestResultType[] | undefined
}

const TypeTestResultFormList = ({form, initialValues}: QnFormListProps) => {

    useEffect(() => {
        if (initialValues) {
            form.setFieldsValue({
                psyTestResultTypes: initialValues
            });
        } else {
            form.setFieldsValue({
                psyTestResultTypes: [
                    {id: uuidv4(), title: '결과유형 1', seq: 1},
                    {id: uuidv4(), title: '결과유형 2', seq: 2}
                ]
            });
        }
    }, [initialValues, form]);

    const handleClickRemove = (remove: (index: number) => void, index: number) => {
        const resultTypes = form.getFieldValue('psyTestResultTypes');
        if (resultTypes.length === 1) {
            message.warning('최소 1개의 결과유형이 필요합니다.');
            return;
        }
        if (initialValues) {
            const id = resultTypes[index].id;
            const findIndex = initialValues.findIndex(rt => rt.id === id);
            if (findIndex > -1) {
                message.warning('초기 데이터는 삭제할 수 없습니다.');
                return;
            }
        }
        remove(index);
    }

    return (
        <Form.List
            name='psyTestResultTypes'
        >
            {(fields, {add, remove}) => (
                <div className="result-form-list">
                    {fields.map((field, index) => {
                        return (<div key={field.key}>
                            <Card
                                className="common-card"
                                key={field.key}
                                extra={<>
                                    <Button
                                        className="icon-btn"
                                        icon={<DeleteOutlined/>}
                                        onClick={() => handleClickRemove(remove, index)}
                                        //onClick={() => remove(index)}
                                    >
                                    </Button>
                                </>}
                            >
                                <div>
                                    <div className="result-card-header">
                                        <Form.Item
                                            name={[index, 'id']}
                                            style={{display: 'none'}}
                                            initialValue={uuidv4()}
                                        >
                                        </Form.Item>
                                        <Form.Item
                                            name={[index, 'seq']}
                                            initialValue={index + 1}
                                            style={{display: 'none'}}
                                        ></Form.Item>
                                        <Form.Item
                                            name={[index, "title"]}
                                            initialValue={`결과유형 ${index + 1}`}
                                            rules={[{required: true, message: '제목을 입력해주세요.'}]}
                                        >
                                            <Input
                                                className="result-title-input"
                                                placeholder="제목을 입력해주세요."
                                            />
                                        </Form.Item>
                                    </div>
                                    <div className="common-card-content">
                                        <Form.Item
                                            name={[index, "content"]}
                                            rules={[{required: true, message: `${index + 1}번째 결과유형의 내용을 입력해주세요.`}]}
                                        >
                                            <Input.TextArea
                                                placeholder="내용을 입력해주세요."
                                                autoSize={{minRows: 7}}
                                            />
                                        </Form.Item>
                                    </div>
                                </div>
                            </Card>
                        </div>)
                    })}
                    <Form.Item>
                        <Button
                            type="dashed"
                            onClick={() => {
                                add();
                            }}
                            block
                        >
                            + 유형 추가
                        </Button>
                    </Form.Item>
                </div>
            )}
        </Form.List>
    );
};

export default TypeTestResultFormList;
