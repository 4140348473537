import axios, {AxiosResponse} from 'axios';
import {ApiResponseData} from "../types/apiResponseDataTypes";

const apiUrl = process.env.REACT_APP_API_URL;

export const DecorationService = {
    async getDecoAssets(assetType: string): Promise<AxiosResponse<any>> {
        return await axios.get(`${apiUrl}/admin/asset?assetType=${assetType}`);
    },
    async getAvatars(): Promise<AxiosResponse<any>> {
        return await axios.get(`${apiUrl}/admin/asset?assetType=AVATAR`);
    },
    async createDecoAsset(formData: FormData): Promise<AxiosResponse<any>> {
        return await axios.post(`${apiUrl}/admin/asset`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });
    },
    async modifyDecoAsset(formData: FormData, id:string): Promise<AxiosResponse<any>> {
        return await axios.put(`${apiUrl}/admin/asset/${id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });
    },
    async deleteDecoAsset(assetId: string): Promise<AxiosResponse<ApiResponseData<any>>> {
        return await axios.delete(`${apiUrl}/admin/asset/${assetId}`);
    }
};
