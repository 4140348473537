import React, {useEffect} from 'react';
import {Button, Form, Input, Modal, Select, SelectProps} from "antd";

interface ModalProps {
    open: boolean;
    close: () => void;
    handleFinish: (values: any) => void;
    record: any;
    selectOptions: SelectProps['options'];
}

const PsytestModal = ({open, close, handleFinish, record, selectOptions}: ModalProps) => {
    const [form] = Form.useForm();

    useEffect(() => {
        if (record) {
            const {title, description} = record;
            form.setFieldsValue({
                title, description
            });
        }
    }, [record]);

    return (
        <Modal
            className="common-modal"
            title={`${record ? '심리테스트 수정' : '새 심리테스트 등록'}`}
            open={open}
            footer={null}
            onCancel={close}
        >
            <div className='common-modal-content'>
                <Form
                    className="create-form"
                    name="create-form"
                    form={form}
                    autoComplete="off"
                    colon={false}
                    labelCol={{span: 4}}
                    wrapperCol={{span: 30, offset: 1}}
                    requiredMark={false}
                    onFinish={handleFinish}
                >

                    <Form.Item
                        name="title"
                        label='제목'
                        rules={[{required: true, message: '심리테스트 제목을 입력해 주세요'}]}
                    >
                        <Input placeholder={'제목 입력'}/>
                    </Form.Item>

                    {
                        !record &&
                        <Form.Item
                            name="testType"
                            label="테스트 타입"
                            rules={record ? [] : [{required: true, message: '테스트 타입을 선택해 주세요'}]}
                            //rules={[{required: true, message: '테스트 타입을 선택해 주세요'}]}
                        >
                            <Select
                                placeholder="테스트 타입 선택"
                                options={selectOptions}
                            />
                        </Form.Item>
                    }

                    <Form.Item
                        name="description"
                        label="설명"
                    >
                        <Input.TextArea placeholder="설명 입력"/>
                    </Form.Item>

                    <div className="centered-outer register-btn-outer">
                        <Button
                            type="primary"
                            ghost
                            htmlType="submit"
                        >
                            {record ? '수정 완료' : '등록'}
                        </Button>
                    </div>
                </Form>
            </div>
        </Modal>
    );
};

export default PsytestModal;
