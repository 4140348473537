import React, {useEffect, useState} from 'react';
import {Button, Card, Form, FormInstance, Input} from 'antd';
import PsyTestAoFormList from './PsyTestAoFormList';
import {PsyTestQnRes, PsyTestResultType, ScorePsyTestResultType} from '../../../types/psyTestTypes';
import {TestType} from '../../../types/enumtype/psytestEnumtype';
import {v4 as uuidv4} from 'uuid';
import {DragDropContext, Draggable, Droppable, DropResult,} from 'react-beautiful-dnd';
import {IoCopyOutline} from "react-icons/io5";
import {HiPencilSquare} from "react-icons/hi2";
import Colors from "../../../styles/colors";
import {AiOutlineDelete} from "react-icons/ai";
import {PageMode} from "../../../types/componentprops/pageTypes";
import Loader from "../../../components-default/loader/Loader";

interface QnFormListProps {
    form: FormInstance<any>;
    resultForm: FormInstance<any>;
    mode: PageMode;
    initialValues: PsyTestQnRes[] | null;
    testType: TestType;
    setMaxScore: React.Dispatch<React.SetStateAction<number | undefined>>;
    setMinScore: React.Dispatch<React.SetStateAction<number | undefined>>;
    rtInitialValues?: PsyTestResultType[] | ScorePsyTestResultType[];
}

const PsyTestQnFormList = ({
                               form,
                               resultForm,
                               mode,
                               initialValues,
                               testType,
                               rtInitialValues
                           }: QnFormListProps) => {
    const [editingQnIndex, setEditingQnIndex] = useState<number>(0);
    const [loading, setLoading] = React.useState<boolean>(true);


    useEffect(() => {
        form.setFieldsValue({
            psyTestQns: initialValues || [{}],
        });
        setLoading(false);
    }, [initialValues, form, mode]);

    const handleOnDragEnd = (result: DropResult) => {
        if (!result.destination) {
            return;
        }

        const currentList: PsyTestQnRes[] = form.getFieldsValue().psyTestQns;
        const newList: PsyTestQnRes[] = JSON.parse(JSON.stringify(currentList));
        const draggingItemIndex = result.source.index;
        const dropItemIndex = result.destination.index;
        // 드래그한 요소를 배열에서 삭제
        const removeForm = newList.splice(draggingItemIndex, 1);
        // 드롭한 위치에 드래그한 요소를 추가
        newList.splice(dropItemIndex, 0, removeForm[0]);
        newList.forEach((value, index) => {
            value.seq = index + 1;
        });
        form.setFieldsValue({['psyTestQns']: newList});
    };

    const handleRemoveQn = (index: number, remove: (index: number) => void) => {
        remove(index); // index 번째 질문 삭제 후, 나머지 질문의 seq 재설정
        const qn = form.getFieldValue(['psyTestQns']);
        qn.forEach((item: any, index: number) => {
            item.seq = index + 1;
        });
        form.setFieldsValue({
            ['psyTestQns']: qn
        });
    }
    const handleCopyQn = (index: number, add: (insertIndex: number) => void) => {
        const originalQn = form.getFieldValue(['psyTestQns', index]);
        const newQn = {...originalQn};
        newQn.id = uuidv4();
        newQn.seq = form.getFieldValue(['psyTestQns']).length + 1;
        add(newQn);
    }

    if (loading) return <Loader style={{left: '40%', top: '30%'}}/>;

    return (
        <>
            <DragDropContext
                onDragEnd={handleOnDragEnd}
            >
                <Droppable droppableId="psyTestQns">
                    {(provided) => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                            <Form.List
                                name={'psyTestQns'}
                            >
                                {(fields, {add, remove}) => (
                                    <>
                                        {fields.map((field, index) => {
                                            return (
                                                <Draggable
                                                    draggableId={String(field.key)}
                                                    index={index}
                                                    key={field.key}
                                                >
                                                    {(provided) => {
                                                        return (
                                                            <div
                                                                key={field.key}
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                            >
                                                                <Card
                                                                    className="common-card"
                                                                    key={field.key}
                                                                    onClick={() => {
                                                                        setEditingQnIndex(index);
                                                                    }}
                                                                    extra={
                                                                        <>
                                                                            {/*<span>질문 ID : </span>*/}
                                                                            {/*<span style={{*/}
                                                                            {/*    marginRight: '10px',*/}
                                                                            {/*    fontWeight: 'bold'*/}
                                                                            {/*}}>*/}
                                                                            {/*    {form.getFieldValue(['psyTestQns', index, 'id'])}*/}
                                                                            {/*</span>*/}

                                                                            {/*직상증 표시*/}
                                                                            {editingQnIndex === index && (
                                                                                <Button ghost>
                                                                                    <HiPencilSquare
                                                                                        size={20}
                                                                                        color={Colors.secondary_300}
                                                                                    />
                                                                                </Button>
                                                                            )}
                                                                            {/*복사하기 버튼*/}
                                                                            <Button ghost
                                                                                    onClick={() => handleCopyQn(index, add)}
                                                                            >
                                                                                <IoCopyOutline
                                                                                    size={20}
                                                                                    color={"#555555"}/>
                                                                            </Button>
                                                                            {/*삭제하기 버튼*/}
                                                                            <Button
                                                                                ghost
                                                                                onClick={() => handleRemoveQn(index, remove)}
                                                                                //onClick={()=>remove(index)}
                                                                            >
                                                                                <AiOutlineDelete
                                                                                    size={20}
                                                                                    color={'#555555'}
                                                                                />
                                                                            </Button>
                                                                        </>
                                                                    }
                                                                >
                                                                    <div className={'common-card-content'}>
                                                                        <div className={'qn-outer'}>
                                                                            <div className={'qn-number'}>
                                                                                Q. {index + 1}
                                                                            </div>
                                                                            <Form.Item
                                                                                name={[index, 'id']}
                                                                                style={{display: 'none'}}
                                                                                initialValue={uuidv4()}
                                                                            ></Form.Item>
                                                                            <Form.Item
                                                                                name={[index, 'seq']}
                                                                                initialValue={index + 1}
                                                                                style={{display: 'none'}}
                                                                            ></Form.Item>
                                                                            <Form.Item
                                                                                name={[index, 'content']}
                                                                                rules={[
                                                                                    {
                                                                                        required: true,
                                                                                        message: '질문을 입력해주세요.',
                                                                                    },
                                                                                ]}
                                                                            >
                                                                                <Input.TextArea
                                                                                    className={'qn-input'}
                                                                                    placeholder={'질문을 입력해주세요.'}
                                                                                    autoSize={{minRows: 4}}
                                                                                />
                                                                            </Form.Item>
                                                                        </div>
                                                                        {(mode === "create" || (mode === "modify" && rtInitialValues)) &&
                                                                            <div className={'ao-outer'}>
                                                                                <PsyTestAoFormList
                                                                                    form={form}
                                                                                    resultForm={resultForm}
                                                                                    qnIndex={index}
                                                                                    testType={testType}
                                                                                    rtInitialValues={rtInitialValues}
                                                                                />
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </Card>
                                                            </div>
                                                        );
                                                    }}
                                                </Draggable>
                                            );
                                        })}
                                        {provided.placeholder}
                                        <Form.Item>
                                            <Button type="dashed" onClick={() => add()} block>
                                                + 질문 추가
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </>
    )
        ;
};

export default PsyTestQnFormList;
