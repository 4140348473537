enum TestType {
    SCORE_TEST = "SCORE_TEST",
    TYPE_TEST = "TYPE_TEST",
    BASIC_TEST = "BASIC_TEST"
}

const testTypeList = [
    TestType.SCORE_TEST,
    TestType.TYPE_TEST,
    TestType.BASIC_TEST
];

const testTypeLabel = {
    [TestType.SCORE_TEST]: "점수테스트",
    [TestType.TYPE_TEST]: "유형테스트",
    [TestType.BASIC_TEST]: "기본 심리테스트"
}

const testTypeOptions = testTypeList.map((testType) => {
    return {
        label: testTypeLabel[testType],
        value: testType,
    }
})

export {TestType, testTypeList, testTypeLabel, testTypeOptions};
