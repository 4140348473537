import React, {useEffect} from 'react';
import {Button, Checkbox, Divider, Form, FormInstance, Input, InputNumber, Popover, Select, Tag} from "antd";
import {MinusCircleTwoTone} from "@ant-design/icons";
import {TestType} from "../../../types/enumtype/psytestEnumtype";
import {PsyTestResultType, ScorePsyTestAnswerOption, ScorePsyTestResultType} from "../../../types/psyTestTypes";

interface AoFormListProps {
    form: FormInstance<any>;
    resultForm: FormInstance<any>;
    qnIndex: number;
    testType: TestType;
    rtInitialValues?: PsyTestResultType[] | ScorePsyTestResultType[];

}

const PsyTestAoFormList = ({
                               form,
                               resultForm,
                               qnIndex,
                               testType,
                               rtInitialValues
                           }: AoFormListProps) => {
        // const [addedOrRemoved, setAddedOrRemoved] = React.useState<number>(0);
        const [resultTypes, setResultTypes] = React.useState<PsyTestResultType[]>();
        const [selectedValue, setSelectedValue] = React.useState<string>('none');
        const [isFromOne, setIsFromOne] = React.useState<boolean>(false);

        useEffect(() => {
            const ao = form.getFieldValue(['psyTestQns', qnIndex, 'answerOptions']);
            if (!ao) {
                form.setFieldsValue({
                    ['psyTestQns']: {
                        [qnIndex]: {
                            answerOptions: [{}]
                        }
                    }
                });
            }
            if (rtInitialValues) {
                setResultTypes(rtInitialValues);
            }
        }, [form]);

        const selectWidth = 150;
        const dropdownStyle = {
            width: selectWidth,
            maxHeight: 400, overflow: 'auto'
        }

        const updateFieldValue = (value: number, aoIndex: number, fieldName: string) => {
            const ao = form.getFieldValue(['psyTestQns', qnIndex, "answerOptions", aoIndex]);
            ao[fieldName] = value;
            form.setFieldsValue({
                'psyTestQns': {
                    [qnIndex]: {
                        answerOptions: {
                            [aoIndex]: ao
                        }
                    }
                }
            });
        }

        const handleSelectRtWeight = (value: number, aoIndex: number) => {
            updateFieldValue(value, aoIndex, 'rtWeight');
        }

        const handleSelectScore = (value: number, aoIndex: number) => {
            updateFieldValue(value, aoIndex, 'score');
            // setAddedOrRemoved(addedOrRemoved + 1);
        }

        const handleSelectResultType = (value: number, aoIndex: number) => {
            updateFieldValue(value, aoIndex, 'rtId');
        }

        const handleRemoveAo = (index: number, remove: any) => {
            remove(index); // index 번째 답변옵션 삭제 후, 나머지 답변옵션들의 seq 재설정
            const ao = form.getFieldValue(['psyTestQns', qnIndex, 'answerOptions']);
            ao.forEach((item: any, index: number) => {
                item.seq = index + 1;
            });
            form.setFieldsValue({
                    ['psyTestQns']: {
                        [qnIndex]: {
                            answerOptions: ao
                        }
                    }
                }
            );
        }

        const handleClickRtSpan = () => {
            const _resultTypes: PsyTestResultType[] = resultForm.getFieldValue('psyTestResultTypes');
            setResultTypes(_resultTypes);
        }

        const handleTagClick = (value: string) => {
            setSelectedValue(value);
            let sortedAnswerOptions: ScorePsyTestAnswerOption[] = form.getFieldValue(['psyTestQns', qnIndex, 'answerOptions']);
            switch (value) {
                case 'ascending':
                    sortedAnswerOptions = sortedAnswerOptions.map((option, index) => {
                            return {...option, score: index + (isFromOne ? 1 : 0)};
                        }
                    );
                    break;
                case 'descending':
                    sortedAnswerOptions = sortedAnswerOptions.map((option, index) => {
                            return {...option, score: sortedAnswerOptions.length - (index + (isFromOne ? 0 : 1))};
                        }
                    );
                    break;
                case 'none':
                    sortedAnswerOptions = sortedAnswerOptions.map((option: any) => {
                        return {...option, score: 0};
                    });
                    break;
            }

            form.setFieldsValue({
                psyTestQns: {
                    [qnIndex]: {
                        answerOptions: sortedAnswerOptions
                    }
                }
            });
        }

        return (
            <>
                {
                    testType !== TestType.TYPE_TEST && (
                        <>
                            <div className="aoset">
                                <span>점수 일괄설정</span>
                                <div className="checkable-tag-group">
                                    <Tag.CheckableTag
                                        checked={isFromOne}
                                        onChange={() => {
                                            setIsFromOne(!isFromOne);
                                        }}
                                    >1 ~
                                    </Tag.CheckableTag>
                                    <Tag.CheckableTag
                                        checked={selectedValue === 'ascending'}
                                        onChange={() => handleTagClick('ascending')}
                                    >
                                        +1 오름차순
                                    </Tag.CheckableTag>
                                    <Tag.CheckableTag
                                        checked={selectedValue === 'descending'}
                                        onChange={() => handleTagClick('descending')}
                                    >
                                        -1 내림차순
                                    </Tag.CheckableTag>
                                    <Tag.CheckableTag
                                        checked={selectedValue === 'none'}
                                        onChange={() => handleTagClick('none')}
                                    >
                                        모든점수 0
                                    </Tag.CheckableTag>
                                </div>
                            </div>
                            <Divider style={{margin: '10px 0'}}/>
                        </>
                    )
                }
                <Form.List
                    name={[qnIndex, "answerOptions"]}
                >
                    {(fields, {add, remove}) => (
                        <>
                            {fields.map((field, index) => {
                                const rtWeight = form.getFieldValue(['psyTestQns', qnIndex, "answerOptions", index, "rtWeight"]) || 1;
                                const score = form.getFieldValue(['psyTestQns', qnIndex, "answerOptions", index, "score"]);
                                return (
                                    <div key={index}>
                                        <Form.Item
                                            name={[index, 'seq']}
                                            style={{display: 'none'}}
                                            initialValue={index + 1}
                                        ></Form.Item>
                                        <Form.Item
                                            name={[index, "content"]}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: '답변옵션을 입력해 주세요.',
                                                },
                                            ]}
                                        >
                                            <Input
                                                className={"ao-input"}
                                                placeholder="답변옵션을 입력해 주세요."
                                                prefix={<MinusCircleTwoTone twoToneColor="#ff4d4f"
                                                                            onClick={() => {
                                                                                handleRemoveAo(index, remove);
                                                                                // setAddedOrRemoved((prevState: boolean) => !prevState)
                                                                                // setAddedOrRemoved(addedOrRemoved - 1);
                                                                            }}
                                                />}
                                                suffix={
                                                    testType === TestType.TYPE_TEST ?
                                                        <>
                                                            <div
                                                                style={{display: form.getFieldValue(['psyTestQns', qnIndex, "answerOptions", index, "rtId"]) ? 'block' : 'none'}}>
                                                                <Popover
                                                                    trigger="click"
                                                                    placement="right"
                                                                    content={
                                                                        <div>
                                                                            <InputNumber
                                                                                defaultValue={1}
                                                                                placeholder="결과유형 가중치 설정"
                                                                                min={1}
                                                                                disabled={!form.getFieldValue(['psyTestQns', qnIndex, "answerOptions", index, "rtId"])}
                                                                                onChange={(value) => {
                                                                                    if (typeof value === "number") {
                                                                                        handleSelectRtWeight(value, index);
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    }
                                                                >
                                                                    <Form.Item name={[index, "rtWeight"]}
                                                                               initialValue={1}>
                                                                        <span
                                                                            className={"type-selector-btn"}>{rtWeight ? '가중치 ' + rtWeight : '가중치 1'}</span>
                                                                    </Form.Item>
                                                                </Popover>
                                                            </div>
                                                            <Popover
                                                                trigger="click"
                                                                placement="right"
                                                                content={
                                                                    <Select
                                                                        placeholder="결과유형 선택"
                                                                        options={resultTypes?.map((rt, index) => {
                                                                            return {
                                                                                value: rt.id,
                                                                                label: rt.title
                                                                            }
                                                                        })}
                                                                        onChange={(value: number) => {
                                                                            handleSelectResultType(value, index);
                                                                        }}
                                                                        style={{width: selectWidth}}
                                                                        dropdownStyle={dropdownStyle}
                                                                        maxTagTextLength={10}
                                                                        maxTagCount={3}>
                                                                    </Select>
                                                                }>
                                                                <Form.Item name={[index, "rtId"]}
                                                                           rules={[{
                                                                               required: true,
                                                                               message: '결과유형을 입력해 주세요'
                                                                           }]}
                                                                >
                                                                        <span className={"type-selector-btn"}
                                                                              onClick={handleClickRtSpan}
                                                                        >{
                                                                            form.getFieldValue(['psyTestQns', qnIndex, "answerOptions", index, "rtId"]) ?
                                                                                resultTypes?.find(rt => rt.id === form.getFieldValue(['psyTestQns', qnIndex, "answerOptions", index, "rtId"]))?.title
                                                                                :
                                                                                <span
                                                                                    style={{color: '#7c7c7c'}}>결과유형</span>
                                                                        }</span>
                                                                </Form.Item>
                                                            </Popover>
                                                        </>
                                                        :
                                                        <Popover
                                                            trigger="click"
                                                            placement="right"
                                                            content={
                                                                <div>
                                                                    <InputNumber
                                                                        defaultValue={score}
                                                                        placeholder="점수 설정"
                                                                        min={0}
                                                                        onChange={(value) => {
                                                                            if (typeof value === "number") {
                                                                                handleSelectScore(value, index);
                                                                            }
                                                                        }}
                                                                    />
                                                                </div>
                                                            }
                                                        >
                                                            <Form.Item name={[index, "score"]}
                                                                       initialValue={0}
                                                            >
                                                            <span
                                                                className={"type-selector-btn"}>
                                                                {score !== undefined ? score + ' 점' : 0 + ' 점'}
                                                            </span>
                                                            </Form.Item>
                                                        </Popover>
                                                }
                                            />
                                        </Form.Item>
                                    </div>
                                )
                            })}
                            <Form.Item>
                                <Button
                                    type="dashed"
                                    onClick={() => {
                                        add();
                                    }}
                                    // onClick={() => {
                                    //     testType === TestType.TYPE_TEST ? add() : handleAddAo();
                                    //     // setAddedOrRemoved(addedOrRemoved + 1);
                                    // }}
                                    block
                                >
                                    + 답변 옵션 추가
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>
            </>
        )
            ;
    }
;

export default PsyTestAoFormList;
