import React, {useEffect, useState} from 'react';
import {Button, Dropdown, message, Table, Tag} from "antd";
import {Section} from "../../components/TriSectionLayout";
import {CounselingService} from "../../services/CounselingService";
import {TagMapValue} from "../../types/tagTypes";
import {ColumnsType} from "antd/es/table";
import UseConfirmDelete from "../../utils/useHook/UseConfirmDelete";
import CreateTagModal from "./CreateOrModifyTagModal";
import {RiSettings3Fill} from "react-icons/ri";
import {EditOutlined} from "@ant-design/icons";
import {AiOutlineDelete} from "react-icons/ai";

const TagMng = () => {
    const [emotionData, setEmotionData] = useState<TagMapValue[]>();
    const [fieldData, setFieldData] = useState<TagMapValue[]>();
    const [topicData, setTopicData] = useState<TagMapValue[]>();
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [createModalVsb, setCreateModalVsb] = useState<boolean>(false);
    const [selectedDiv, setSelectedDiv] = useState<'EMOTION' | 'FIELD'>();
    const [modifyModalVsb, setModifyModalVsb] = useState<boolean>(false);
    const [selectedRecord, setSelectedRecord] = useState<TagMapValue>();

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        try {
            const res = await CounselingService.getTags();
            setEmotionData(res.data.result.EMOTION);
            setFieldData(res.data.result.FIELD);
            setTopicData(res.data.result.PROGRAM);
        } catch (e: any) {
            message.error('데이터를 불러오는데 실패했습니다.');
            console.error(e.message);
        }
    }

    const handleDelete = async (id: string) => {
        try {
            const res = await CounselingService.deleteTag(id);
            if (res.data.success) {
                message.success('삭제되었습니다.');
                getData();
            } else {
                throw new Error(res.data.message);
            }
        } catch (e: any) {
            if (e.response.status === 409) {
                // const msg = e.response.data.message;
                // message.warning(msg);
                const name = selectedRecord?.name;
                message.warning(`'${name}' 태그를 사용하고 있는 상담 컨텐츠가 있습니다. 상담 컨텐츠를 먼저 삭제해 주세요.`);
            }
        } finally {
            setSelectedRecord(undefined);
        }
    }

    const items = [
        {
            key: '1',
            label: (
                <>
                    <EditOutlined/>
                    <span className={'menu-item-text'}>수정하기</span>
                </>
            ),
            onClick: () => selectedRecord && setModifyModalVsb(true),
        },
        {
            key: '2',
            label: (
                <>
                    <AiOutlineDelete/>
                    <span className={'menu-item-text'}>삭제하기</span>
                </>
            ),
            onClick: () => selectedRecord && handleClickDelete(selectedRecord?.id),
        },
    ]

    const columns: ColumnsType<TagMapValue> = [
        {
            title: '',
            dataIndex: 'id',
            key: 'id',
            width: 50,
            align: 'center',
            render: (value: string, record: TagMapValue, index: number) => (
                <Dropdown
                    trigger={['click']}

                    menu={{items}}
                    // overlay={menu}
                    placement="bottomLeft" arrow
                >
                    <div className="setting-icon-container">
                        <RiSettings3Fill
                            size={24}
                            onClick={() => {
                                setSelectedRecord(record);
                            }}
                        />
                    </div>
                </Dropdown>
            ),
        },
        {
            title: '감정 분류',
            dataIndex: 'type',
            key: 'type',
            align: 'center',
            render: (type) => type ?
                type === 'POSITIVE'
                    ? <Tag color={'blue'}>긍정감정</Tag>
                    : <Tag color={'red'}>부정감정</Tag>
                : null
        },
        {
            title: '이름',
            dataIndex: 'name',
            key: 'name',
            align: 'center',
        }
    ];

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: TagMapValue[]) => {
            //console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            setSelectedRowKeys(selectedRowKeys);
        }
    }
    const buttonGroup = (
        <div className='btn-group'>
            <Button
                type="primary"
                onClick={() => setCreateModalVsb(true)}
                style={{minWidth: 100}}
            >+ 새 태그 등록</Button>
            <Button
                disabled={selectedRowKeys.length === 0}
                onClick={() => {
                    handleClickDelete(selectedRowKeys[0].toString());
                }}
            >선택 삭제
            </Button>
        </div>
    )
    const {handleClickDelete, confirmModal} = UseConfirmDelete({handleDelete});

    const handleFinishModal = async (value: any, mode: string) => {
        if (mode === "create") {
            const res = await CounselingService.createTag(value);
            if (res.data.success) {
                message.success('등록되었습니다.');
                getData();
                handleCloseModal();
            } else {
                message.error('등록에 실패했습니다.');
                throw new Error(res.data.message);
            }
        } else {
            if (!selectedRecord?.id) return;
            const res = await CounselingService.modifyTag(selectedRecord?.id, value);
            if (res.data.success) {
                message.success('수정되었습니다.');
                getData();
                setModifyModalVsb(false);
                setSelectedRecord(undefined);
            } else {
                message.error('수정에 실패했습니다.');
                throw new Error(res.data.message);
            }
        }
    }

    const handleCloseModal = () => {
        setCreateModalVsb(false);
        setSelectedDiv(undefined);
    }

    return (
        <>
            <div className="layout-container three-to-two">
                <Section title='감정 태그'
                         extra={
                             <Button
                                 type="primary"
                                 onClick={() => {
                                     setCreateModalVsb(true)
                                     setSelectedDiv('EMOTION')
                                 }}
                                 style={{minWidth: 100}}
                             >+ 새 태그 등록</Button>
                         }
                    //style={{overflowY: 'auto', height: 'calc(100vh - 200px)'}}
                >
                    <Table
                        size={'small'}
                        columns={columns}
                        dataSource={emotionData}
                        rowKey={'id'}
                        //rowSelection={rowSelection}
                        //pagination={false}
                        pagination={{pageSize: 15}}
                    />
                </Section>
                <Section
                    title='분야 태그'
                    //extra={buttonGroup}
                    extra={
                        <Button
                            type="primary"
                            onClick={() => {
                                setCreateModalVsb(true)
                                setSelectedDiv('FIELD')
                            }}
                            style={{minWidth: 100}}
                        >+ 새 태그 등록</Button>
                    }
                    //style={{overflowY: 'auto', height: 'calc(100vh - 200px)'}}
                >
                    <Table
                        size={'small'}
                        columns={columns.filter((item: any) => item.key !== 'type')}
                        dataSource={fieldData}
                        rowKey={'id'}
                        pagination={{pageSize: 15}}
                    />
                </Section>
                {/*</div>*/}
                {/*<Section
                    title='주제그룹(상담타입)'
                    extra={buttonGroup}
                    style={{overflowY: 'auto', height: 'calc(100vh - 200px)'}}

                >
                    <Table
                        size={'small'}
                        columns={columns.filter((item: any) => item.key !== 'type')}
                        dataSource={topicData}
                        rowKey={'id'}
                        rowSelection={rowSelection}
                        pagination={false}
                    />
                </Section>*/}
            </div>
            {confirmModal}
            {createModalVsb && selectedDiv &&
                <CreateTagModal open={createModalVsb} close={handleCloseModal} div={selectedDiv}
                                handleFinish={handleFinishModal}
                />
            }
            {modifyModalVsb && selectedRecord &&
                <CreateTagModal open={modifyModalVsb} close={() => setModifyModalVsb(false)} div={selectedRecord.div!}
                                handleFinish={handleFinishModal}
                                record={selectedRecord}
                />
            }
        </>
    );
};

export default TagMng;
