import React, {useEffect} from 'react';
import {Button, Form, Input, Modal, Select} from "antd";
import {StageType, stageTypeLabel} from "../../types/enumtype/stageEnumtype";
import {ConvoModalProps} from "../../types/componentprops/modifyModalProps";

const ConvoModal = ({open, close, stage, avatarOptions, handleFinish, record}: ConvoModalProps) => {
    const [form] = Form.useForm();
    useEffect(() => {
        if (record) {
            const {title, avatar, description} = record;
            const avatarId = avatar ? avatar.id : undefined;
            form.setFieldsValue({
                title, avatarId, description
            });
        }
    }, [record]);

    const stageAvatarNotRequired = [StageType.REVIEW, StageType.APP_REVIEW];

    return (
        <Modal
            className="common-modal"
            title={record ? `${stageTypeLabel[stage]} 수정` : `새 ${stageTypeLabel[stage]} 등록`}
            open={open}
            footer={null}
            onCancel={close}
        >
            <div className='common-modal-content'>
                <Form
                    className="create-form"
                    name="create-form"
                    form={form}
                    autoComplete="off"
                    colon={false}
                    labelCol={{span: 4}}
                    wrapperCol={{span: 30, offset: 1}}
                    requiredMark={false}
                    onFinish={handleFinish}
                >
                    <Form.Item
                        name="stage"
                        initialValue={stage}
                        hidden={true}
                    />

                    <Form.Item
                        name="title"
                        label='제목'
                        rules={[{required: true, message: `${stageTypeLabel[stage]} 제목을 입력해 주세요`}]}
                    >
                        <Input placeholder={'제목 입력'}/>
                    </Form.Item>

                    {!stageAvatarNotRequired.includes(stage) &&
                        <Form.Item
                            name="avatarId"
                            label="아바타"
                        >
                            <Select
                                options={avatarOptions}
                                placeholder="아바타 선택"
                            />
                        </Form.Item>}
                    <Form.Item
                        name="description"
                        label="설명"
                    >
                        <Input.TextArea placeholder="설명 입력"/>
                    </Form.Item>


                    <div className="centered-outer register-btn-outer">
                        <Button
                            type="primary"
                            ghost
                            htmlType="submit"
                        >
                            {record ? '수정 완료' : '등록'}
                        </Button>
                    </div>
                </Form>
            </div>
        </Modal>
    );
};

export default ConvoModal;
