import {Button, Modal} from 'antd';
import React, {useState} from 'react';
import {FaCircleExclamation} from "react-icons/fa6";

interface UseConfirmDeleteProps {
    handleDelete: (id: string) => Promise<void>;
    content?: string;
}

const UseConfirmDelete = ({handleDelete, content}: UseConfirmDeleteProps) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [deleteId, setDeleteId] = useState<string | null>();

    const handleClickDelete = (id: string) => {
        setDeleteId(id);
        setIsModalVisible(true);
    };

    const handleClickOk = async () => {
        if (deleteId) {
            try {
                await handleDelete(deleteId);
            } catch (error: any) {
                throw new Error(error);
            } finally {
                setIsModalVisible(false);
                setDeleteId(null);
            }
        }
    };
    const handleCancelDelete = () => {
        setIsModalVisible(false);
        setDeleteId(null);
    };

    const confirmModal = (
        <Modal
            title={
                <div className='confirm-modal-title btn-with-text'>
                    <FaCircleExclamation color={'#ff4d4f'}/>
                    <span>정말 삭제하시겠습니까?</span>
                </div>
            }
            open={isModalVisible}
            width={400}
            closeIcon={null}
            footer={
                <div className='confirm-modal-footer'>
                    <Button type="primary"
                            onClick={handleClickOk}
                    >삭제</Button>
                    <Button type="primary" ghost
                            onClick={handleCancelDelete}
                    >취소</Button>
                </div>
            }>
            <div className='confirm-modal-content'>
                {content && content}
            </div>
        </Modal>
    );

    return {handleClickDelete, confirmModal};
};

export default UseConfirmDelete;
