import React, {useState} from 'react';
import 'react-chat-elements/dist/main.css';
import PreviewMessageList from "./PreviewMessageList";
import PreviewChatList from "./PreviewChatList";

const ChatPreview = () => {
        const [chatId, setChatId] = useState<string>()

        return (
            <div className={'chat-preview-container'}>
                <PreviewMessageList chatId={chatId}/>
                <PreviewChatList setChatId={setChatId}/>
            </div>
        );
    }
;

export default ChatPreview;
