import {StageType} from "./stageEnumtype";

enum QnType {
    TEXT = "TEXT",
    MCQ = "MCQ",
    SAQ = "SAQ",
    VIDEO = "VIDEO",
    DRAWING = "DRAWING",
    EMOTION = "EMOTION",
    FIELD = "FIELD",
    TOPIC = "TOPIC",
}

const qnTypeListMap = {
    [StageType.SMALLTALK]: [
        QnType.TEXT,
        QnType.MCQ,
    ],
    [StageType.STRUCTURING]: [
        QnType.TEXT,
        QnType.EMOTION,
        QnType.FIELD,
        QnType.TOPIC,
        QnType.MCQ,
    ],
    [StageType.MAIN]: [
        QnType.TEXT,
        QnType.MCQ,
        QnType.SAQ,
        QnType.VIDEO,
        QnType.DRAWING,
    ],
    [StageType.REVIEW]: [
        QnType.TEXT,
        QnType.SAQ,
        QnType.MCQ,
    ],
    [StageType.APP_REVIEW]: [
        QnType.MCQ,
        QnType.SAQ,
        QnType.TEXT,
    ],
    [StageType.RANDOM_SPEECH_BUBBLE]: [
        QnType.TEXT,
    ],
}

const qnTypeLabel = {
    [QnType.MCQ]: "객관식 질문",
    [QnType.SAQ]: "주관식 질문",
    [QnType.VIDEO]: "동영상 질문",
    [QnType.DRAWING]: "그림 질문",
    [QnType.TEXT]: "텍스트",
    [QnType.EMOTION]: "감정 질문",
    [QnType.FIELD]: "분야 질문",
    [QnType.TOPIC]: "주제 질문",
}

export {QnType, qnTypeListMap, qnTypeLabel};
