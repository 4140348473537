import { useSelector } from 'react-redux';
import './Breadcrumb.scss';

const Breadcrumb = () => {
  const { breadcrumb } = useSelector((state: any) => state.breadcrumb);

  return (
    <div className="breadcrumb">
      <div className="inner">
        {/*<a href="src/components/components_default/breadcrumb#!" className="bcmb_elm bcmb_home">*/}
        {/*  메인으로*/}
        {/*</a>*/}
        {/*{breadcrumb.map((item: string, index: number) => {*/}
        {/*  if (index === breadcrumb.length - 1) {*/}
        {/*    return (*/}
        {/*      <a href="src/components/components_default/breadcrumb#!" className="bcmb_elm bcmb_on" key={index}>*/}
        {/*        {item}*/}
        {/*      </a>*/}
        {/*    );*/}
        {/*  } else {*/}
        {/*    return (*/}
        {/*      <a href="src/components/components_default/breadcrumb#!" className="bcmb_elm" key={index}>*/}
        {/*        {item}*/}
        {/*      </a>*/}
        {/*    );*/}
        {/*  }*/}
        {/*})}*/}
      </div>
    </div>
  );
};

export default Breadcrumb;
