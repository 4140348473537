import axios, {AxiosResponse} from 'axios';
import {ApiResponseData} from "../types/apiResponseDataTypes";
import {ConvoQnData} from "../types/userChatTypes";

const apiUrl = process.env.REACT_APP_API_URL + '/user/counseling';
export const UserChatService = {
    async getCsls(): Promise<AxiosResponse<any>> {
        return await axios.get(`${apiUrl}`);
    },
    async getCslQn(csId: string, seq: number): Promise<AxiosResponse<ApiResponseData<ConvoQnData>>> {
        return await axios.get(`${apiUrl}/${csId}/qns?seq=${seq}`);
    },
};
