import React, {useEffect, useState} from 'react';
import {LoadingOutlined, PlusOutlined} from '@ant-design/icons';
import {message, Upload} from 'antd';
import type {UploadChangeParam} from 'antd/es/upload';
import type {RcFile, UploadProps} from 'antd/es/upload/interface';

const getBase64 = (img: RcFile, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result as string));
    reader.readAsDataURL(img);
};

const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
};

interface ImageUploadProps {
    onFileChange: (file: RcFile | null) => void;
    defaultImageUrl?: string | undefined;
    title?: string;
    rule?: React.ReactNode;
    width?: string;
}

const ImageUpload: React.FC<ImageUploadProps> = ({
                                                     onFileChange,
                                                     defaultImageUrl,
                                                     title,
                                                     rule,
                                                     width
                                                 }) => {
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState<string>();
    useEffect(() => {
        if (defaultImageUrl) {
            setImageUrl(defaultImageUrl);
        }
        return () => {
            setImageUrl(undefined);
        }
    }, [defaultImageUrl])

    const handleChange: UploadProps['onChange'] = (info: UploadChangeParam) => {
        if (info.file.status === 'uploading') {
            setLoading(true);
            return;
        }
        if (info.file.status === 'done') {
            getBase64(info.file.originFileObj as RcFile, (url) => {
                setLoading(false);
                setImageUrl(url)
                onFileChange(info.file.originFileObj as RcFile);
            });
        }
    };

    const uploadButton = (
        <div className="image-uploader-btn">
            {loading ? <LoadingOutlined/> : <PlusOutlined/>}
            <div style={{marginTop: 8}}>{title}</div>
            <div>Upload</div>
            {rule && <div className={'image-uploader-guide'}>
                {rule}
            </div>}
        </div>
    );

    const dummyRequest = ({file, onSuccess}: any) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };
    return (
        <>
            <Upload
                name="image-uploader"
                listType="picture-card"
                className="image-uploader"
                showUploadList={false}
                customRequest={dummyRequest} // desc: action(post url)을 지정하지 않으면 dummyRequest를 지정해야 함
                beforeUpload={beforeUpload}
                onChange={handleChange}
            >
                {imageUrl ? <img src={imageUrl} alt="asset" style={{width: width ?? '100%'}}/> : uploadButton}
            </Upload>
        </>


    );
};

export default ImageUpload;
