import React, {useEffect} from 'react';
import {Button, Card, Divider, Form, FormInstance, Input, message, Modal, Slider, Tag} from "antd";
import {DeleteOutlined} from "@ant-design/icons";
import {v4 as uuidv4} from "uuid";
import {PageMode} from "../../../types/componentprops/pageTypes";
import Loader from "../../../components-default/loader/Loader";
import {ScorePsyTestResultType} from "../../../types/psyTestTypes";
import {use} from "i18next";
import {SliderMarks} from "antd/es/slider";
import {FaSliders} from "react-icons/fa6";
import Colors from "../../../styles/colors";
import {RiArrowGoBackLine} from "react-icons/ri";

interface QnFormListProps {
    form: FormInstance<any>;
    // mode: PageMode
    initialValues: ScorePsyTestResultType[] | undefined;
    maxScore: number | undefined;
    minScore: number | undefined;
}

const ScoreTestResultFormList = ({form, initialValues, maxScore, minScore}: QnFormListProps) => {
    const [scoreRange, setScoreRange] = React.useState<any>([]);
    const [scoreRangeChangeable, setScoreRangeChangeable] = React.useState<any>([]);
    const [sliderModalVsb, setSliderModalVsb] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(true);

    useEffect(() => {
        if (initialValues) {
            form.setFieldsValue({
                psyTestResultTypes: initialValues
            });
        } else {
            form.setFieldsValue({
                psyTestResultTypes: [{seq: 1, id: uuidv4(), title: '결과유형 1', startScore: 0, endScore: maxScore}]
            });
        }
        setLoading(false);
    }, [initialValues, form]);

    useEffect(() => {
        if (maxScore !== undefined) {
            if (initialValues) {
                setScoreRange(calclScoreRangeWithData());
                setScoreRangeChangeable(calclScoreRangeWithData());
            } else {
                setScoreRange([0, maxScore]);
                setScoreRangeChangeable([0, maxScore]);
            }
        }
    }, [maxScore]);

    const handleSliderChange = (values: number[]) => {
        // 첫번째와 마지막은 고정
        const newValues = values.map((value, index) => {
            if (index === 0) {
                // return minScore || 0;
                return 0;
            }
            if (index === values.length - 1) {
                return maxScore;
            }
            return value;
        })
        setScoreRangeChangeable(newValues);
    }

    const handleClickAdd = (add: () => void) => {
        if (maxScore === undefined) return;
        const resultTypes = form.getFieldValue('psyTestResultTypes');
        if (maxScore < resultTypes.length) {
            message.warning('결과유형 개수는 최대 점수보다 작아야 합니다.');
            return;
        }
        add();
    }

    const handleClickRemove = (remove: (index: number) => void, index: number) => {
        const resultTypes = form.getFieldValue('psyTestResultTypes');
        if (resultTypes.length === 1) {
            message.warning('최소 1개의 결과유형이 필요합니다.');
            return;
        }
        remove(index);
    }

    // 점수범위 계산
    const calcScoreRange = () => {
        // maxScore 에서 resultTypes 의 개수만큼 점수범위을 나누어준다.
        if (maxScore === undefined) return;
        const resultCount = form.getFieldValue('psyTestResultTypes').length;
        return Array.from({length: resultCount + 1}, (_, i) => Math.ceil((maxScore / resultCount) * i));
        // minScore 사용할 경우
        // ,,, return Math.ceil((minScore + (maxScore - minScore) / resultCount) * i);
    }

    const calclScoreRangeWithData = () => {
        // const resultTypes = form.getFieldValue('psyTestResultTypes');
        if (!initialValues) return;
        const resultTypes = initialValues;
        const resultCount = resultTypes.length;
        const scoreRange = Array.from({length: resultCount + 1}, (_, i) => {
            if (i === 0) return 0;
            if (i === resultCount) return maxScore;
            // return resultTypes[i - 1].endScore;
            return resultTypes[i].startScore;
        });
        return scoreRange;
    }

    const handleClickSlider = () => {
        setScoreRangeChangeable(calcScoreRange());
        setSliderModalVsb(true);
    }

    const updateScoreValues = (scoreRangeChangeable: any) => {
        const values = form.getFieldsValue();
        const {psyTestResultTypes} = values;
        const newValues = psyTestResultTypes.map((rt: any, index: number) => {
            const startScore = scoreRangeChangeable[index];
            const endScore = index === scoreRangeChangeable.length - 2 ? maxScore : scoreRangeChangeable[index + 1] - 1;
            return {
                ...rt,
                startScore,
                endScore
            }
        })
        form.setFieldsValue({psyTestResultTypes: newValues})
    }
    const handleOkSliderModal = () => {
        setScoreRange(scoreRangeChangeable);
        setSliderModalVsb(false);
        updateScoreValues(scoreRangeChangeable);
    }

    const handleClickAutoSet = () => {
        // 1. 점수범위 자동조정
        setScoreRangeChangeable(calcScoreRange());
        setScoreRange(calcScoreRange());
        // 2. 결과유형의 점수범위 자동조정
        updateScoreValues(calcScoreRange());
    }

    const handleClickReset = () => {
        // 1. 점수범위 원래대로
        setScoreRange(calclScoreRangeWithData());
        setScoreRangeChangeable(calclScoreRangeWithData());
        // 2. 결과유형 카드의 점수범위 원래대로
        updateScoreValues(calclScoreRangeWithData());
    }

    const marks = (scoreRange: any): SliderMarks => {
        return scoreRange?.reduce((acc: any, cur: any, i: number, arr: any[]) => {
                if (i === arr.length - 1) {
                    acc[cur] = cur;
                } else {
                    acc[cur] = `${cur} ~`;
                }
                return acc;
            }
            , {});
    }

    if (loading) return <Loader style={{left: 'auto', right: '20%', top: '30%'}}/>;

    return (
        <>
            {scoreRange.length > 0 &&
                <div className="score-slider-outer">
                    <div className={"score-slider-inner"}
                         onClick={handleClickSlider}
                    >
                        <div className={"score-slider-header"}>
                            <div className={"score-range-text"}>
                                <span>전체 점수범위 : </span>
                                <span>
                                { 0 + ' ~ ' + maxScore + '점'}
                                </span>
                            </div>
                        </div>
                        <Slider
                            range
                            defaultValue={scoreRange}
                            value={scoreRange}
                            disabled
                            min={0}
                            max={maxScore}
                            marks={marks(scoreRange)}
                        />
                    </div>
                    <div className="autoset-btns">
                        <Button
                            className="autoset-btn"
                            type="primary"
                            ghost
                            icon={<FaSliders/>}
                            onClick={handleClickAutoSet}
                        >자동조정</Button>
                        {initialValues &&
                            <Button
                                className="autoset-btn"
                                type="primary"
                                ghost
                                icon={<RiArrowGoBackLine/>}
                                onClick={handleClickReset}
                            >원래 점수로</Button>
                        }
                    </div>
                </div>
            }

            <Form.List name='psyTestResultTypes'>
                {(fields, {add, remove}) => (
                    <div className="result-form-list">
                        {fields.map((field, index) => {
                            const startScore = form.getFieldValue(['psyTestResultTypes', index, 'startScore']);
                            const endScore = form.getFieldValue(['psyTestResultTypes', index, 'endScore']);
                            return (<div key={index}>
                                <Card
                                    className="common-card"
                                    key={index}
                                    extra={<div className={'justify'}>
                                        <div className={"score-range-text"}>
                                            {/*{startScore !== undefined && endScore !== undefined ?*/}
                                            {Number.isInteger(startScore) && Number.isInteger(endScore) ?
                                                (startScore === endScore ? startScore + '점' : startScore + ' ~ ' + endScore + '점') :
                                                '점수범위을 설정하여 주세요'
                                            }
                                        </div>
                                        <Button
                                            className="icon-btn"
                                            icon={<DeleteOutlined/>}
                                            onClick={() => handleClickRemove(remove, index)}
                                        >
                                        </Button>
                                    </div>}
                                >
                                    <div>
                                        <div className="result-card-header">
                                            <Form.Item
                                                name={[field.name, 'startScore']}
                                                style={{display: 'none'}}
                                                rules={[{
                                                    required: true,
                                                    message: `${index + 1}번째 결과유형의 점수범위을 설정하여 주세요`
                                                }]}
                                            ></Form.Item>

                                            <Form.Item
                                                name={[field.name, 'endScore']}
                                                style={{display: 'none'}}
                                                rules={[{
                                                    required: true,
                                                    message: `${index + 1}번째 결과유형과의 점수범위을 설정하여 주세요`
                                                }]}
                                            ></Form.Item>

                                            <Form.Item
                                                name={[field.name, 'id']}
                                                style={{display: 'none'}}
                                                initialValue={uuidv4()}
                                            >
                                            </Form.Item>
                                            <Form.Item
                                                name={[field.name, 'seq']}
                                                initialValue={index + 1}
                                                style={{display: 'none'}}
                                            ></Form.Item>

                                            <Form.Item
                                                name={[field.name, "title"]}
                                                initialValue={`결과유형 ${index + 1}`}
                                                rules={[{required: true, message: '제목을 입력해주세요.'}]}
                                            >
                                                <Input
                                                    className="result-title-input"
                                                    placeholder="제목을 입력해주세요."
                                                />
                                            </Form.Item>
                                        </div>
                                        <div className="common-card-content">
                                            <Form.Item
                                                name={[field.name, "content"]}
                                                rules={[{required: true, message: `${index + 1}번째 결과유형의 내용을 입력해주세요.`}]}
                                            >
                                                <Input.TextArea
                                                    placeholder="내용을 입력해주세요."
                                                    autoSize={{minRows: 7}}
                                                />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </Card>
                            </div>)
                        })}
                        <Form.Item>
                            <Button
                                type="dashed"
                                onClick={() => handleClickAdd(add)}
                                block
                            >
                                + 유형 추가
                            </Button>
                        </Form.Item>
                    </div>
                )}
            </Form.List>

            {sliderModalVsb &&
                <Modal
                    title=" 점수범위 설정"
                    open={sliderModalVsb}
                    width={'70vw'}
                    onCancel={() => setSliderModalVsb(false)}
                    footer={
                        <div className='confirm-modal-footer'>
                            <Button type="primary"
                                    onClick={handleOkSliderModal}
                            >확인</Button>
                            <Button type="primary" ghost
                                    onClick={() => setSliderModalVsb(false)}
                            >취소</Button>
                        </div>
                    }
                >
                    <div className="slider-modal-outer">
                        <div className="slider-modal-inner">
                            <Slider
                                range
                                defaultValue={scoreRangeChangeable}
                                value={scoreRangeChangeable}
                                onChange={handleSliderChange}
                                min={0}
                                max={maxScore}
                                marks={marks(scoreRangeChangeable)}
                            />
                        </div>
                    </div>
                </Modal>
            }
        </>
    );
};

export default ScoreTestResultFormList;
