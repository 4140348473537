import DefaultRouter from './routers/DefaultRouter';
import Login from "./pages/login/Login";
import setupAxiosInterceptor from "./utils/setupAxiosInterceptor";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AuthService} from "./services/AuthService";
import {loginSuccess} from "./store/reducer/auth";
import {RootState} from "./store";
import Loader from "./components-default/loader/Loader";

const App = () => {
    const dispatch = useDispatch();
    const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        setupAxiosInterceptor();
    }, []);

    useEffect(() => {
        if (!isLoggedIn) {
            refreshAuth();
        }
    }, [isLoggedIn]);
    const refreshAuth = async () => {
        try {
            setIsLoading(true)
            const refreshToken = localStorage.getItem('refreshToken');
            if (!refreshToken) return;
            const res = await AuthService.refresh(refreshToken); // 여기서 401 에러가 발생하면 무한로딩 발생
            if (res.data.success) {
                const {id, jwt, roles, username} = res.data.result;
                await localStorage.setItem('refreshToken', jwt.refreshToken);
                const payload = {
                    tokens: jwt,
                    id,
                    roles: [roles],
                    username
                }
                dispatch(loginSuccess(payload));
            } else {
                console.error(res.data.message);
            }
        } catch (e: any) {
            throw new Error(e.message);
        } finally {
            setIsLoading(false);
        }
    }

    if (isLoading) return <Loader/>

    return isLoggedIn ? <DefaultRouter/> : <Login/>
};

export default App;
