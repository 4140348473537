import React, {useEffect, useState} from 'react';
import {Button, Form, Input, Modal} from "antd";
import {AssetType, assetTypeLabel as typeLabel} from "../../types/enumtype/assetEnumtype";
import {RcFile} from "antd/es/upload/interface";
import ImageUpload from "../../components/ImageUpload";
import {AssetModifyModalProps} from "../../types/componentprops/modifyModalProps";
import {AssetData} from "../../types/assetTypes";

const ModifyAssetModal = ({open, close, record, type, handleFinish}: AssetModifyModalProps<AssetData>) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const [form] = Form.useForm();
    const [selectedFiles, setSelectedFiles] = useState<(RcFile | null)[]>([null, null, null]);
    const assetType = type as AssetType;

    useEffect(() => {
        const {name, description, mindtreeAmount} = record;
        form.setFieldsValue({
            name, description, mindtreeAmount
        });
    }, [record]);

    const handleFileChange = (index: number, file: RcFile | null) => {
        setSelectedFiles(prevFiles => {
            const newFiles = [...prevFiles];
            newFiles[index] = file;
            return newFiles;
        });
    };

    return (
        <Modal
            className="common-modal"
            title={`${typeLabel[assetType]} 수정`}
            open={open}
            footer={null}
            onCancel={close}
            width={800}
        >
            <div className='common-modal-content'>
                <div className="image-uploader-container">
                    {type === 'BACKGROUND' ? (
                        <>
                            <ImageUpload
                                onFileChange={(file) => handleFileChange(0, file)}
                                defaultImageUrl={record.imageId ? `${apiUrl}/images/${record.imageId}` : undefined}
                            />
                            <ImageUpload
                                onFileChange={(file) => handleFileChange(1, file)}
                                defaultImageUrl={record.image2Id ? `${apiUrl}/images/${record.image2Id}` : undefined}
                            />
                            <ImageUpload
                                onFileChange={(file) => handleFileChange(2, file)}
                                defaultImageUrl={record.image3Id ? `${apiUrl}/images/${record.image3Id}` : undefined}
                                width="40%"
                            />
                        </>
                    ) : (
                        <ImageUpload
                            onFileChange={(file) => handleFileChange(0, file)}
                            defaultImageUrl={record.imageId ? `${apiUrl}/images/${record.imageId}` : undefined}
                        />
                    )}
                </div>
                <Form
                    className="create-form"
                    name="create-form"
                    form={form}
                    autoComplete="off"
                    colon={false}
                    labelCol={{span: 4}}
                    wrapperCol={{span: 30, offset: 1}}
                    requiredMark={false}
                    onFinish={(values) => handleFinish(values, selectedFiles)}
                >
                    <Form.Item
                        name="name"
                        label={`${typeLabel[assetType]} 이름`}
                        rules={[{required: true, message: `${typeLabel[assetType]} 이름을 입력해 주세요`}]}
                    >
                        <Input placeholder={`${typeLabel[assetType]} 이름 입력`}/>
                    </Form.Item>

                    <Form.Item
                        name="description"
                        label="소개글"
                    >
                        <Input.TextArea placeholder="소개글 입력"
                                        style={{minHeight: 200, overflow: 'auto'}}
                        />
                    </Form.Item>

                    <Form.Item
                        name="mindtreeAmount"
                        label="마음나무"
                        rules={[{required: true, message: '마음나무 개수를 설정해 주세요'}]}
                    >
                        <Input type="number" placeholder="마음나무 개수 입력"
                               defaultValue={0}
                               style={{width: '100%'}}
                        />
                    </Form.Item>

                    <div className="centered-outer">
                        <Button
                            type="primary"
                            ghost
                            htmlType="submit"
                        >
                            등록
                        </Button>
                    </div>
                </Form>
            </div>
        </Modal>
    );
};

export default ModifyAssetModal;
