enum NotiType {
    NOTICE = "NOTICE",
    FAQ = "FAQ",
}

const notiTypeLabel = {
    [NotiType.NOTICE]: "공지사항",
    [NotiType.FAQ]: "FAQ",
}

const notiTypeList = [
    {label: "공지사항", value: NotiType.NOTICE},
    {label: "FAQ", value: NotiType.FAQ},
];

export {NotiType, notiTypeLabel, notiTypeList};
