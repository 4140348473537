enum AssetType {
    AVATAR = "AVATAR",
    BACKGROUND = "BACKGROUND",
    BGM = "BGM",
}

const assetTypeLabel = {
    [AssetType.AVATAR]: "아바타",
    [AssetType.BACKGROUND]: "배경",
    [AssetType.BGM]: "BGM",
}

const assetTypeList = [
    AssetType.AVATAR,
    AssetType.BACKGROUND,
    AssetType.BGM,
]

export {AssetType, assetTypeLabel, assetTypeList};
